import React, { Dispatch, SetStateAction } from "react";

import { ScoreSectionTile } from "../../../../../../components/CategorySectionTile";
import { ICompanyFactor } from "../../../../../../lib/";
import { ContentfulScoreConfiguration } from "../../../../types";
import { getFactorDataType } from "../../SecurityScoreCard";

export const factorMapper = (
  factors: ICompanyFactor[],
  onClick: Dispatch<SetStateAction<ICompanyFactor | null>>,
  getFactorData: getFactorDataType,
  securityScoreCardConfigurationData: ContentfulScoreConfiguration
) =>
  factors?.map((factor: any) => {
    return (
      <ScoreSectionTile
        securityScoreCardConfigurationData={securityScoreCardConfigurationData}
        key={factor.name}
        factor={{
          ...factor,
          name: getFactorData(factor.name)?.heading
        }}
        onClick={() =>
          onClick({
            ...factor,
            description: getFactorData(factor.name)?.description
          })
        }
      />
    );
  });
