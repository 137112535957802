import { Formik } from "formik";
import React, { ChangeEvent, useRef } from "react";
import { useTheme } from "styled-components";

import { WrapperMultiEmailField } from "../../../../../components/DynamicJourney/WrapperComponents/Inputs";
import { PrimaryButton } from "../../../../../components/PrimaryButton";
import {
  errorMessages,
  multipleEmailRegex
} from "../../../../../lib/utilities/";
import {
  FileUploader,
  LookoutDialogFormMethod,
  LookoutDialogFormWrapper,
  LookoutDialogSubmitButton
} from "../../../styles/ServicesPage.styles";
import { InvitationModalProps } from "./InvitationModal";
import { invitationModalSchema } from "./InvitationModalSchema";

export function splitEmails(emails: string): string[] {
  return emails
    ?.split(";")
    .slice()
    .filter((mail) => mail !== "");
}

export function validateSingleEmail(email: string): boolean {
  return multipleEmailRegex.test(email);
}

export function validateManyEmails(emailArray: string) {
  return splitEmails(emailArray).map(validateSingleEmail);
}

export type InvitationModalFormProps = Omit<
  InvitationModalProps,
  "onClose" | "isOpen" | "setIsInvitationDialogOpen"
> & {
  onSubmit: (data: { emails: string; emailBody: string }) => any;
  isLoading: boolean;
  changeHandler: (
    { target }: ChangeEvent<HTMLInputElement>,
    callBack: any
  ) => void;
};

export function InvitationModalForm({
  onSubmit,
  enrollmentCode = "",
  changeHandler,
  fields,
  isLoading
}: InvitationModalFormProps) {
  const {
    uploadCsvButton,
    submitButton,
    multipleEmailsField,
    emailsAddedMessage
  } = fields;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  return (
    <Formik
      validate={({ emails }) => {
        const validate = validateManyEmails(emails);
        if (validate.includes(false))
          return { emails: errorMessages.invalidEmail };
      }}
      onSubmit={(args) => {
        onSubmit(args);
      }}
      validationSchema={invitationModalSchema}
      validateOnChange
      validateOnMount
      initialValues={{
        emails: "",
        emailBody: `${fields.defaultEmailBodyMessage} ${enrollmentCode}`
      }}
    >
      {({ handleChange, values, handleBlur, submitForm, errors, isValid }) => (
        <LookoutDialogFormWrapper>
          <LookoutDialogFormMethod>
            <WrapperMultiEmailField
              showEmails={false}
              dataSelectorPrefix=""
              spacing={{
                spacingLevel: {
                  top: 2,
                  right: 2,
                  bottom: 2,
                  left: 2
                },
                children: undefined
              }}
              state={errors?.emails ? "error" : undefined}
              fieldWrapper={{
                width: "full",
                required: true,
                subText: `${
                  splitEmails(values?.emails)?.length
                } ${emailsAddedMessage}`,
                helpText: errors?.emails ? errors?.emails : undefined,
                label: multipleEmailsField.label
              }}
              textInput={{
                value: values?.emails,
                required: true,
                id: multipleEmailsField.fieldId,
                name: multipleEmailsField.fieldId,
                placeholder: multipleEmailsField.placeholder,
                onChange: handleChange,
                onBlur: handleBlur
              }}
            />

            <div>
              <PrimaryButton
                appearance={uploadCsvButton.appearance}
                inverse={theme.name !== "WS10"}
                onClick={() => fileInputRef.current?.click()}
                text={uploadCsvButton.buttonText}
              />
            </div>

            <FileUploader
              data-testid="fileUploader"
              onChange={(evt) =>
                changeHandler(evt, (args: any) => {
                  handleChange({
                    target: {
                      name: "emails",
                      value: args
                    }
                  });
                })
              }
              type="file"
              ref={fileInputRef}
              accept=".csv"
              name="file"
            />
          </LookoutDialogFormMethod>
          <LookoutDialogSubmitButton>
            <PrimaryButton
              appearance={submitButton.appearance}
              state={!isValid ? "disabled" : undefined}
              text={submitButton.buttonText}
              onClick={submitForm}
              loading={isLoading}
            />
          </LookoutDialogSubmitButton>
        </LookoutDialogFormWrapper>
      )}
    </Formik>
  );
}
