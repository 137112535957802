import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";

import { Heading } from "@source-web/heading";
import { LoadingSpinner } from "@source-web/loading-spinner";

import { Grid, GridColumn, GridRow } from "@vfuk/core-grid";
import { Stepper } from "@vfuk/core-stepper";

import { formatToDate } from "../../../../../components/DynamicJourney/Functions";
import RequestStatusCard from "../../../../../components/RequestStatusCard/RequestStatusCard";
import { IRequestStatus } from "../../../../../context/auth/auth.types";
import { getRequestStatuses } from "../../../../../lib";
import * as Styled from "../../../styles/ServicesPage.styles";

interface IMFWRequestStatusProps {
  showOnlyClosedRequests?: boolean;
}

export const MFWRequestStatus = ({
  showOnlyClosedRequests = false
}: IMFWRequestStatusProps) => {
  const [selected, setSelected] = useState(0);
  const [currentStep, setCurrentStep] = useState(2);

  const { isLoading, data } = useQuery(
    "managedFirewallRequeststatuses",
    getRequestStatuses
  );

  const requestStatusData: IRequestStatus[] = useMemo(() => {
    if (data) {
      if (showOnlyClosedRequests) {
        const closed = data?.filter(
          (item: IRequestStatus) => item.pillText === "Request Completed"
        );
        return closed;
      }

      const inProgress = data?.filter(
        (item: IRequestStatus) => item.pillText !== "Request Completed"
      );

      return inProgress;
    }
    return [];
  }, [data, showOnlyClosedRequests]);

  const stepperProps = useMemo(() => {
    const props = {
      hideControls: true,
      currentStep
    };
    if (showOnlyClosedRequests) {
      return {
        ...props,
        steps: [
          {
            title: "Kick-off"
          },
          {
            title: "Request Completed"
          }
        ]
      };
    }
    return {
      ...props,
      steps: [
        {
          title: "Kick-off"
        },
        {
          title: "In progress"
        }
      ]
    };
  }, [currentStep, showOnlyClosedRequests]);

  useEffect(() => {
    switch (requestStatusData[selected]?.pillText) {
      case "Request Completed":
        setCurrentStep(2);
        break;
      case "Request in Progress":
        setCurrentStep(2);
        break;
      default:
        setCurrentStep(1);
        break;
    }
  }, [selected, requestStatusData]);

  return (
    <>
      {!isLoading ? (
        <Grid maxWidth="full">
          <GridRow marginTop={6}>
            {requestStatusData.map((item, index) => (
              <GridColumn col={12} colMd={6} colLg={4} key={index}>
                <RequestStatusCard
                  id={item.id}
                  date={formatToDate(item.date)}
                  status={item.status}
                  pillText={item.pillText}
                  onChange={() => setSelected(index)}
                  isSelected={selected === index}
                />
              </GridColumn>
            ))}
          </GridRow>
          <GridRow justify="center" marginBottom={6}>
            <Heading level={4} text={requestStatusData[selected]?.status} />
          </GridRow>
          <Styled.UnsetColorOnStepper>
            <Stepper {...stepperProps} />
          </Styled.UnsetColorOnStepper>
        </Grid>
      ) : (
        <Styled.LoadingSpinnerContainer>
          <LoadingSpinner size={5} />
        </Styled.LoadingSpinnerContainer>
      )}
    </>
  );
};
