import React from "react";
import { useQuery } from "react-query";

import { Loader, ThemedSection } from "../../../../../../components";
import { getScoreFactory } from "../../../../../../context/auth/helpers";
import {
  ICompanyFactor,
  ISecurityScorecardRootObject,
  capitalizeFactor,
  getSecurityScoreAssessment
} from "../../../../../../lib";
import {
  ContentfulIndustryBenchmark,
  GetContentfulScoreRatings,
  GetContentfulSecurityRating
} from "../../../../types";
import { IndustryBenchmark } from "./IndustryBenchmark";

export interface ConnectedIndustryBenchmarkProps {
  companyName: string;
  companyFactors: ICompanyFactor[];
  industry: string | null;
  industryBenchmarkData: ContentfulIndustryBenchmark["contentfulIndustryBenchmark"];
  securityScoreCardRatings: GetContentfulScoreRatings["allContentfulSecurityScorecardRatings"];
  securityRatings: GetContentfulSecurityRating["allContentfulSecurityRating"];
  setIndustry: React.Dispatch<React.SetStateAction<string | null>>;
}

export type IndustryFactorType = {
  section: string;
  company?: number;
  industry: number;
};

export enum Roles {
  company = "company",
  industry = "industry"
}

export function formatBothScoreFactors(
  companyFactorsScores: ICompanyFactor[],
  scoreFromIndustry: ICompanyFactor[],
  customLabels: Record<string, string> = {
    company: "company",
    industry: "industry"
  }
) {
  const keys = companyFactorsScores?.map((factor: any) => factor.name) || [];
  if (!keys.length) return;

  const factors: any = keys?.map((key: any) => {
    const formattedKey = capitalizeFactor(key);
    return {
      section: formattedKey,
      [customLabels.company]: companyFactorsScores?.find(
        ({ name }: ICompanyFactor) => name === key
      )?.score,
      [customLabels.industry]: scoreFromIndustry?.find(
        ({ name }: ICompanyFactor) => name === key
      )?.score
    };
  });

  return factors;
}

const scoreFactory = (
  data: any,
  companyFactors: ConnectedIndustryBenchmarkProps["companyFactors"],
  customLabels: Record<string, string>
) => {
  const parsedIndustryFactors = getScoreFactory(data);

  return formatBothScoreFactors(
    companyFactors,
    parsedIndustryFactors.companyFactors,
    customLabels
  );
};

export function ConnectedIndustryBenchmark({
  companyFactors,
  industry,
  securityScoreCardRatings,
  industryBenchmarkData,
  securityRatings,
  setIndustry
}: ConnectedIndustryBenchmarkProps) {
  const customFactoryLabels = {
    company: industryBenchmarkData.companyLabel,
    industry: industryBenchmarkData.industryLabel
  };
  const { isLoading, data, isFetched, isError } = useQuery<
    ISecurityScorecardRootObject[]
  >({
    queryKey: ["industryBenchmark", industry],
    enabled: !!industry,
    queryFn: () =>
      getSecurityScoreAssessment(Roles.industry, industry, (data) =>
        scoreFactory(data, companyFactors, customFactoryLabels)
      )
  });

  return isLoading ? (
    <ThemedSection appearance="secondary">
      <Loader text={{ text: "" }} />
    </ThemedSection>
  ) : (
    <IndustryBenchmark
      industryBenchmarkData={industryBenchmarkData}
      securityScoreCardRatings={securityScoreCardRatings}
      isFetched={isFetched}
      isError={isError}
      industry={industry}
      setIndustry={setIndustry}
      data={data}
      securityRatings={securityRatings}
    />
  );
}
