// @ts-nocheck
import React from "react";
import { useTheme } from "styled-components";

import { Container } from "@source-web/container";
import { Heading } from "@source-web/heading";
import { Span } from "@source-web/span";

import { DotsItemSymbolProps } from "@nivo/core";
import {
  GridLabelProps,
  RadarCommonProps,
  RadarDataProps,
  ResponsiveRadar
} from "@nivo/radar";

import { capitalizeFirstLetter, isServer, trimLowerCase } from "../../lib/";
import { IvalueRating } from "../../pageTemplates/servicesPage/components/SecurityScoreCard/components/IndustryBenchmark/IndustryBenchmark";
import {
  ResponsiveRadarWrapper,
  StyledLabel,
  StyledTooltipValuesContainer
} from "./ResponsiveRadarChart.styles";

const LabelComponent = (
  props: GridLabelProps & {
    smallStroke: boolean;
    labelActive: boolean;
    isCapital: boolean;
  }
) => {
  const { id, x, y } = props;
  const words = id.split(" ");
  const firstLine = words.slice(0, words.length - 1).join(" ");
  const secondLine = words[words.length - 1];
  return (
    <g transform={`translate(${x},${y})`}>
      <StyledLabel smallStroke={props.smallStroke} active={props.labelActive}>
        <tspan x="0" dy="0">
          {props.isCapital ? firstLine.toUpperCase() : firstLine}
        </tspan>
        <tspan x="0" dy="1.2em">
          {secondLine}
        </tspan>
      </StyledLabel>
    </g>
  );
};

interface ResponsiveRadarChartProps {
  data: RadarDataProps["data"];
  chartArgs?: RadarCommonProps;
  smallStroke?: boolean;
  highlight?: string;
  rating?: IvalueRating;
}

const defaultProps: RadarCommonProps = {
  animate: true,
  enableDots: true,
  isInteractive: false,
  gridLabelOffset: 20,
  gridLevels: 3,
  dotSize: 10,
  dotBorderWidth: 3,
  indexBy: "section",
  keys: ["company", "industry"],
  valueFormat: ">-.2f",
  borderColor: { from: "color" },
  colors: { scheme: "set1" },
  blendMode: "multiply",
  margin: { top: 0, right: 0, bottom: 0, left: 0 },
  dotColor: { from: "color", modifiers: [] },
  legends: [
    {
      direction: "row",
      translateX: 0,
      translateY: 65,
      itemWidth: 70,
      itemHeight: 10,
      itemsSpacing: 20,
      symbolSize: 15,
      symbolShape: "square"
    }
  ]
};

export function ResponsiveRadarChart({
  data,
  chartArgs = defaultProps,
  smallStroke = false,
  highlight,
  rating
}: ResponsiveRadarChartProps) {
  const theme = useTheme();

  const updatedChartArgs = {
    ...defaultProps,
    ...chartArgs,
    legends: chartArgs.legends
      ? chartArgs.legends.map((legend) => ({
          ...legend,
          itemTextColor: theme.color.monochrome6.default
        }))
      : []
  };

  return (
    <>
      {!isServer && (
        <ResponsiveRadarWrapper>
          <ResponsiveRadar
            {...updatedChartArgs}
            data={data}
            sliceTooltip={({ index, data: tooltipData }) => (
              <Container>
                <Heading level={5} weight={3}>
                  {index}
                </Heading>
                {tooltipData.map((value) => (
                  <StyledTooltipValuesContainer
                    color={value.color}
                    key={value.id}
                  >
                    <Span>{capitalizeFirstLetter(value.id)}</Span>
                    &nbsp;&#45;&nbsp;
                    <Span>
                      {capitalizeFirstLetter(
                        value.formattedValue.split(".")[0]
                      )}
                    </Span>
                  </StyledTooltipValuesContainer>
                ))}
              </Container>
            )}
            dotSymbol={(props: DotsItemSymbolProps) => {
              const colorByTheme =
                theme.name !== "WS10" ? "#000000" : "#FFFFFF";
              const dotActive =
                highlight &&
                trimLowerCase(highlight) === trimLowerCase(props.datum.index);
              return (
                <circle
                  r={dotActive ? 8 : 6}
                  fill={dotActive ? "#FFFFFF" : colorByTheme}
                  stroke={props.color}
                  strokeWidth={dotActive ? 5 : 3}
                ></circle>
              );
            }}
            gridLabel={(props: GridLabelProps) => {
              const labelActive =
                highlight &&
                trimLowerCase(highlight) === trimLowerCase(props.id);

              const capitalActive =
                rating?.isCapital &&
                trimLowerCase(rating.value) === trimLowerCase(props.id);

              return (
                <LabelComponent
                  smallStroke={smallStroke}
                  labelActive={labelActive}
                  isCapital={capitalActive}
                  {...props}
                />
              );
            }}
          />
        </ResponsiveRadarWrapper>
      )}
    </>
  );
}
