import React, { Fragment, useEffect, useState } from "react";
import { useTheme } from "styled-components";

import { Paragraph } from "@source-web/paragraph";
import { Tab, Tabs } from "@source-web/tabs";

import { useLocation } from "@reach/router";

import ConfirmationModal from "../../../../components/ConfirmationModal";
import { ExpandableTable } from "../../../../components/ExpandableTable/ExpandableTable";
import { formFlowEnums } from "../../../../lib";
import * as Styled from "./../../styles/ServicesPage.styles";
import { MFWRequestStatus } from "./ManagedFirewall/MFWRequestStatus";

export interface ServiceTabProps {
  isWalkthroughService: boolean;
  walkthroughButtonText: string;
  formattedLocale: string;
  heading: string;
  serviceId: number;
  openFormCallback: (currentFlow: string) => void;
  reports: any;
}

export interface ServiceTabsLocationState {
  initialTab: "completeSection" | undefined;
}

export const ServiceTabs = ({
  isWalkthroughService,
  walkthroughButtonText,
  serviceId,
  openFormCallback,
  reports
}: ServiceTabProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showWalkthroughCall, setShowWalkthroughCall] = useState(true);

  const locationState = (useLocation().state as ServiceTabsLocationState) ?? {};
  const { initialTab = undefined } = locationState;

  useEffect(() => {
    const walkthroughSession = sessionStorage.getItem("did-walkthrough");
    const didWalkthrough = walkthroughSession === "true";
    if (didWalkthrough) setShowWalkthroughCall(didWalkthrough);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceId]);

  const handleSkipModal = () => {
    setShowWalkthroughCall(!showWalkthroughCall);
    setIsOpen(!isOpen);
    document.getElementById("inProgress")?.click();
  };

  function navigateToForm() {
    openFormCallback(formFlowEnums.walkthrough);
  }

  const theme = useTheme();

  const handleTabContent = () => {
    switch (serviceId) {
      case 2:
        return (
          <Tabs
            externallySetTabId={initialTab}
            {...(theme.name === "WS10Dark" && {
              appearance: "secondary",
              inverse: true
            })}
          >
            <Tab text="In progress" key="inProgress" id="inProgressSection">
              <MFWRequestStatus />
            </Tab>
            <Tab text="Closed" key="closed" id="closedSection">
              <MFWRequestStatus showOnlyClosedRequests />
            </Tab>
          </Tabs>
        );

      default:
        return (
          <Tabs
            externallySetTabId={initialTab}
            {...(theme.name === "WS10Dark" && {
              appearance: "secondary",
              inverse: true
            })}
          >
            <Tab text="In progress" key="inProgress" id="inProgressSection">
              <ExpandableTable
                horizontalHeadings={["Id", "Title", "Start date"]}
                items={reports?.filter(({ report }: any) => !report?.file)}
              />
            </Tab>
            <Tab text="Complete" key="complete" id="completeSection">
              <ExpandableTable
                horizontalHeadings={["Id", "Title", "End date"]}
                items={reports?.filter(({ report }: any) => report?.file)}
                haveButton
                setIsSkipConfirmationOpen={() => setIsOpen(true)}
                navigateToWalkthrough={navigateToForm}
                walkthroughButtonText={walkthroughButtonText}
                isWalkthroughService={isWalkthroughService}
                showWalkthroughCall={showWalkthroughCall}
              />
            </Tab>
          </Tabs>
        );
    }
  };

  return (
    <Styled.NavTabContainer role="tablist">
      <Fragment>
        {handleTabContent()}
        <ConfirmationModal
          isOpen={isOpen}
          isCloseable={true}
          onCloseCb={() => setIsOpen(false)}
          heading={{
            justify: "center",
            text: "Are you sure?",
            size: 3
          }}
          cancelBtnProps={{
            text: "No",
            appearance: "secondary",
            onClick: () => {
              setIsOpen(false);
            }
          }}
          confirmBtnProps={{
            text: "Yes",
            appearance: "primary",
            onClick: () => handleSkipModal()
          }}
        >
          <Paragraph>
            Are you sure you want to skip the walkthrough call?
          </Paragraph>
        </ConfirmationModal>
      </Fragment>
    </Styled.NavTabContainer>
  );
};
