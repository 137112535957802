import React, { useState } from "react";

import { Paragraph } from "@source-web/paragraph";
import { OnChangeEvent } from "@source-web/select-input";
import { SelectInputWithLabel } from "@source-web/select-input-with-label";
import { SimpleGrid } from "@source-web/simple-grid";

import { ContentfulTrendMicroDashboard } from "../../../types";
import { FilterWrapper } from "../styles/TrendMicroDashboard.styles";

// import { getTrendInformation } from "../analyticsDashboardMapper";
// import { AnalyticsDashboardGraph, AnalyticsDashboardList } from "../components";
//
// const iconsForKeys: Record<string, string> = {
//   web: "international",
//   cloudSynchronization: "iot",
//   email: "mail",
//   removableStorage: "device-overview",
//   localOrNetworkDrive: "networker",
//   desktop: "laptop",
//   mobileDevices: "mobile"
// };

export function TrendMicroDashboard({
  fields
}: {
  data: any;
  fields: ContentfulTrendMicroDashboard["contentfulTrendMicroDashboard"];
}) {
  const [filter, setFilter] = useState("30");

  const handleChangeFilter = (e: OnChangeEvent) => {
    setFilter(e.target.value);
  };

  const { filterName, filterOptions } = fields;

  // const { data: destructuredData } = data;
  // const [filter, setFilter] = useState("7");

  // const parsedVulnerabilityTypes = JSON.parse(
  //   fields.allVulnerabilityTypes.internal.content
  // );

  // const { chart, list, table, infectionChannels, agentDetails } =
  //   getTrendInformation(data, parsedVulnerabilityTypes, filter);

  return (
    <SimpleGrid
      layout={{
        sm: [12, 12, 12],
        lg: [12, 6, 6, 6, 6]
      }}
      spacing={5}
    >
      <FilterWrapper>
        <Paragraph weight={3} size={2}>
          {filterName}
        </Paragraph>
        <SelectInputWithLabel
          fieldWrapper={{
            label: "",
            showLabel: false,
            width: "full"
          }}
          selectInput={{
            onChange: handleChangeFilter,
            name: "dateFilter",
            id: "dateFilter",
            options: filterOptions,
            value: filter
          }}
        />
      </FilterWrapper>
      {/*{chart && (*/}
      {/*  <AnalyticsDashboardGraph*/}
      {/*    selectedFilter={filter}*/}
      {/*    onChangeFilter={(newFilter: string) => {*/}
      {/*      setFilter(newFilter);*/}
      {/*    }}*/}
      {/*    name={fields.chartTitle}*/}
      {/*    data={chart}*/}
      {/*    tableData={table}*/}
      {/*    listData={list}*/}
      {/*  />*/}
      {/*)}*/}

      {/*<AnalyticsDashboardList*/}
      {/*  iconsForKeys={iconsForKeys}*/}
      {/*  name={fields.firstCardTitle}*/}
      {/*  values={infectionChannels}*/}
      {/*  title={fields.firstListTitle}*/}
      {/*  titleValues={*/}
      {/*    infectionChannels*/}
      {/*      ? Object.values(infectionChannels).reduce(*/}
      {/*          (acc: number, curr: string | number) => {*/}
      {/*            const current =*/}
      {/*              typeof curr === "string" ? parseInt(curr) : curr;*/}

      {/*            return acc + current;*/}
      {/*          },*/}
      {/*          0*/}
      {/*        )*/}
      {/*      : 0*/}
      {/*  }*/}
      {/*/>*/}
      {/*<AnalyticsDashboardList*/}
      {/*  showLabels*/}
      {/*  isObjectValue*/}
      {/*  iconsForKeys={iconsForKeys}*/}
      {/*  name={fields.secondCardTitle}*/}
      {/*  values={agentDetails}*/}
      {/*  title={fields.secondListTitle}*/}
      {/*  desktopLabel={fields.desktopLabel}*/}
      {/*  mobileLabel={fields.mobileLabel}*/}
      {/*  titleValues={agentDetails?.wholeSum}*/}
      {/*/>*/}
    </SimpleGrid>
  );
}
