import styled, { css } from "styled-components";

import { ScoreSectionTileProps } from "../ScoreSectionTile";

interface TileColorArgs {
  color: string;
  onClick?: (score: ScoreSectionTileProps["factor"]) => void;
}

export const ComponentContainer = styled.div<TileColorArgs>(
  (props) => css`
    height: 56px;
    background-color: ${props.theme.color.monochrome2.default};
    display: flex;
    justify-content: space-between;
    border-radius: 5px 4px;
    padding: 0 10px;
    @media (max-width: ${props.theme.breakpoints.md}px) {
      padding: 0 4px 0 8px;
    }

    transition: 600ms cubic-bezier(0.075, 0.82, 0.165, 1);
    will-change: auto;
    &:hover {
      cursor: pointer;
      scale: 1.02;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.61);
    }

    border-left: 5px solid ${props.color};

    margin-bottom: ${props.theme.spacing.fixed[3]}px;
  `
);

export const Application = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `
);

export const FactorSeverity = styled.div(
  () => css`
    p {
      font-weight: 400;
    }
  `
);

export const FactorName = styled.div(
  ({ theme }) => css`
    p {
      font-size: 16px;
    }
    @media (max-width: ${theme.breakpoints.md}px) {
      p {
        font-weight: 600;
        font-size: 14px;
      }
    }
  `
);

export const FactorScore = styled.div(
  ({ theme }) => css`
    display: grid;
    place-items: center;
    padding: 0 10px 0 0;
    font-size: 20px;
    @media (max-width: ${theme.breakpoints.md - 1}px) {
      padding: 0;
      font-size: 14px;
    }
  `
);
