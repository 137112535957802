import { curveLinear, line } from "d3-shape";
import React, { useState } from "react";
import { useTheme } from "styled-components";

import { Button } from "@source-web/button";
import { Heading } from "@source-web/heading";
import { useBreakpoints } from "@source-web/match-media";
import { SelectInputWithLabel } from "@source-web/select-input-with-label";

import { DotsItem } from "@nivo/core";
import { CustomLayerProps, ResponsiveLine } from "@nivo/line";

import { Loader, ThemedSection } from "../../../../../../components";
import { AdviseNotification } from "../../../../../../components/AdviseNotification";
import {
  getFormattedDateForHistoryGraph,
  mapYValueToHeight,
  useEventSource
} from "../../../../../../lib";
import { GetContentfulSecurityScoreHistory } from "../../../../types";
import {
  ChartWrapper,
  HistoryDivider,
  HistoryTitleWrapper,
  HistoryWrapper,
  ViewDetailsWrapper,
  ViewLastWrapper
} from "./styles/History.styles";

type scoreInfoChart = {
  value: number;
  textAnchor: string;
  textX: number;
  textY: number;
  x?: number;
  y?: number;
};
export const generateScoreInfo = ({
  value,
  textAnchor,
  textX,
  textY
}: scoreInfoChart) => {
  const map = [
    {
      range: [0, 59],
      letter: "F",
      fill: "#BD0000",
      translateY: 5,
      lineY1: -80,
      lineY2: 0
    },
    {
      range: [60, 69],
      letter: "D",
      fill: "#EB9700",
      translateY: 165,
      lineY1: 80,
      lineY2: 160
    },
    {
      range: [70, 79],
      letter: "C",
      fill: "#FECB00",
      translateY: 125,
      lineY1: 40,
      lineY2: 120
    },
    {
      range: [80, 89],
      letter: "B",
      fill: "#B7BF10",
      translateY: 85,
      lineY1: 0,
      lineY2: 80
    },
    {
      range: [90, 99],
      letter: "A",
      fill: "#008A00",
      translateY: 45,
      lineY1: -41,
      lineY2: 40
    },
    {
      range: [100, 100],
      letter: "",
      fill: "",
      translateY: 5,
      lineY1: 0,
      lineY2: 0
    } // Adding the range to 100.
  ];

  const { letter, fill, translateY, lineY1, lineY2 } = map.find(
    ({ range }) => value >= range[0] && value <= range[1]
  ) || { translateY: 0 };

  return (
    <>
      <text
        textAnchor={textAnchor}
        transform={`translate(${textX},${textY + translateY})`}
        className="axisYValue"
      >
        {value}
      </text>
      {letter && (
        <text x="-35" y={translateY - 45} fill={fill}>
          {letter}
        </text>
      )}
      <line
        x1="-4"
        y1={lineY1 && lineY1}
        x2="-4"
        y2={lineY2 && lineY2 + 1}
        stroke={"#7E7E7E"}
        strokeWidth={8}
      />
      <line
        x1="-4"
        y1={lineY1}
        x2="-4"
        y2={lineY2 && lineY2 - 1}
        stroke={fill}
        strokeWidth={8}
      />
      {lineY1 === 0 && lineY2 === 0 && (
        <line
          x1="-4"
          y1={1}
          x2="-4"
          y2={-1}
          stroke={"#7E7E7E"}
          strokeWidth={8}
        />
      )}
      {fill === "#BD0000" && (
        <line
          x1="-4"
          y1={1}
          x2="-4"
          y2={-1}
          stroke={"#7E7E7E"}
          strokeWidth={8}
        />
      )}
    </>
  );
};

export const checkPositionLine = (line: number) => {
  switch (true) {
    case line === 0:
      return 400;
    case line === 60:
      return 320;
    case line === 70:
      return 240;
    case line === 80:
      return 160;
    case line === 90:
      return 80;
    case line === 100:
      return 0;
    default:
      break;
  }
};

export const CustomGrid = (lines: number[]) => {
  const Grid = ({ innerWidth }: { innerWidth: number }) => {
    return (
      <>
        <g key={"grid"}>
          {lines.map((line: number) => (
            <>
              <line
                key={line}
                x1={0}
                x2={innerWidth}
                y1={checkPositionLine(line)}
                y2={checkPositionLine(line)}
                stroke="#7E7E7E"
                strokeWidth={2}
              />
            </>
          ))}
        </g>
      </>
    );
  };
  Grid.displayName = "Grid";

  return Grid;
};

// @ts-ignore
export const CustomLineLayer = ({ data, xScale, innerHeight }) => {
  // Line generator configuration
  // @ts-ignore
  const lineGenerator = line()
    // @ts-ignore
    .x((d) => xScale(d.x)) // Mapping x values
    // @ts-ignore
    .y((d) => mapYValueToHeight(d.y, innerHeight)) // Mapping y values to heights on the y-axis
    .curve(curveLinear); // Specifying the type of curve (optional)

  return data.map((dataSeries: any, lineIndex: any) => {
    const path = lineGenerator(dataSeries.data);

    return (
      <g key={`line-${lineIndex}`}>
        {/* @ts-ignore */}
        <path d={path} fill="none" stroke="#dddddd" strokeWidth={2} />
      </g>
    );
  });
};

type IPoints = Array<{
  x: unknown;
  y: number;
  label: string;
}>;
// @ts-ignore
export const CustomPoints = ({ data, xScale, innerHeight }: any) => {
  // @ts-ignore
  const generatePoints = (data) => {
    const points: IPoints = [];
    // @ts-ignore
    data.forEach((dataSeries) => {
      // @ts-ignore
      dataSeries.data.forEach((point) => {
        const x = xScale(point.x);
        const y = mapYValueToHeight(point.y, innerHeight);
        points.push({ x, y, label: point.y });
      });
    });

    return points;
  };

  const points = generatePoints(data);

  return (
    <g>
      {points.map((point, index) => (
        <g key={index}>
          <>
            <DotsItem
              // @ts-ignore
              x={point.x}
              y={point.y}
              datum={point}
              size={4}
              color="white"
              borderWidth={2}
              borderColor="white"
              // If you decide to choose to make the labels visible above the points.
              // label={point.label}
            />
          </>
        </g>
      ))}
    </g>
  );
};

export interface CustomSliceLayerProps extends CustomLayerProps {
  slices: Array<{
    height: number;
    id: number;
    points: any[];
    width: number;
    x: number;
    x0: number;
    y: number;
    y0: number;
  }>;
  height: number;
}

export type ITooltipProps = {
  x: number;
  y: number;
  value: {
    date: string;
    score: number;
  };
};

export const CustomSlicesLayer = ({
  slices,
  height,
  innerHeight
}: CustomSliceLayerProps) => {
  const [tooltipData, setTooltipData] = useState<ITooltipProps | null>();
  // @ts-ignore
  const handleMouseEnter = (event, slice) => {
    setTooltipData({
      x: slice.x,
      // Calculate a y position closest to the drawn line.
      y: mapYValueToHeight(slice.points[0].data.y, innerHeight) - 25, // Adjust the position as needed.
      value: {
        date: getFormattedDateForHistoryGraph(slice.points[0].data.xFormatted),
        score: slice.points[0].data.y
      }
    });
  };

  const handleMouseLeave = () => {
    setTooltipData(null);
  };

  const renderTooltip = () => {
    if (tooltipData) {
      const rectWidth = 140; // Width of the rectangle.
      const rectHeight = 30; // Height of the rectangle.

      return (
        <>
          {/* Gray rectangle. */}
          <rect
            x={tooltipData.x - rectWidth / 2} // Adjusted x position to center the rectangle.
            y={tooltipData.y - rectHeight / 2} // Adjusted y position to center the rectangle.
            width={rectWidth}
            height={rectHeight}
            fill="#333"
          />
          {/* Text centered within the rectangle. */}
          <text
            x={tooltipData.x}
            y={tooltipData.y}
            textAnchor="middle"
            alignmentBaseline="middle"
            fill="#fff"
            fontSize={14}
          >
            <tspan alignmentBaseline="middle">
              date:{" "}
              <tspan
                fill="#fff"
                fontSize={14}
                textAnchor="middle"
                alignmentBaseline="middle"
              >
                {tooltipData.value.date}
              </tspan>
            </tspan>
            <tspan alignmentBaseline="middle" dx="10">
              score:{" "}
              <tspan
                fill="#fff"
                fontSize={14}
                textAnchor="middle"
                alignmentBaseline="middle"
              >
                {tooltipData.value.score}
              </tspan>
            </tspan>
          </text>
        </>
      );
    }
    return null;
  };

  return (
    <>
      {slices.map((slice) => (
        <g
          key={slice.id}
          onMouseEnter={(event) => handleMouseEnter(event, slice)}
          onMouseLeave={handleMouseLeave}
        >
          <rect
            x={slice.x0}
            y={0}
            width={slice.width}
            height={height}
            fillOpacity={0}
          />
        </g>
      ))}
      {renderTooltip()}
    </>
  );
};

export const getDateRange = (value: string) => {
  const currentDate = new Date();
  let fromDate, toDate;

  if (value === "6") {
    fromDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 5,
      1
    );
    toDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  } else if (value === "30") {
    fromDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - 29
    );
    toDate = currentDate;
  } else {
    fromDate = new Date(
      currentDate.getFullYear() - 1,
      currentDate.getMonth(),
      1
    );
    toDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
  }

  // Format dates
  const from =
    fromDate &&
    `${fromDate.getFullYear()}-${(fromDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${fromDate.getDate().toString().padStart(2, "0")}`;
  const to =
    toDate &&
    `${toDate.getFullYear()}-${(toDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${toDate.getDate().toString().padStart(2, "0")}`;

  return `from=${from}&to=${to}`;
};

type IHistory = {
  date?: string;
  industry?: string;
  partyRole?: {
    name: string;
    role: string;
  };
  riskAssessmentResult: {
    overallScore: number;
    score?: number;
  };
};

type IHistoryProps = {
  securityScoreHistory: GetContentfulSecurityScoreHistory["contentfulSecurityScoreHistory"];
};

export const History = ({ securityScoreHistory }: IHistoryProps) => {
  const theme = useTheme();
  const { sm } = useBreakpoints();
  const [historySelected, setHistorySelected] = useState("12");
  const [dateHistorySelected, setDateHistorySelected] = useState(
    getDateRange("12")
  );

  const { isLoading, data, isError, refetch } = useEventSource<IHistory>(
    `scores/assessment/history?${dateHistorySelected}&group=MONTH`,
    {
      isSingleEntity: false
    }
  );

  const allHistory = data as IHistory[];

  const arrayFormatted = allHistory.map((item) => ({
    x: item.date,
    y: item.riskAssessmentResult.overallScore
  }));

  const dataFormatted = [
    {
      id: "history",
      data: arrayFormatted
    }
  ];

  const margins = sm
    ? { top: 50, right: 20, bottom: 50, left: 65 }
    : { top: 50, right: 85, bottom: 50, left: 80 };

  return (
    <>
      <HistoryWrapper>
        <HistoryTitleWrapper>
          <Heading
            level={3}
            text={securityScoreHistory.heading}
            appearance="primary"
          />
          <ViewLastWrapper>
            <Heading
              level={4}
              text={securityScoreHistory.selectorText}
              appearance="primary"
              size={1}
              weight={3}
              noMargin
            />
            <SelectInputWithLabel
              dataAttributes={{ "data-testid": "ViewLastSelect" }}
              fieldWrapper={{
                label: ""
              }}
              selectInput={{
                name: "ViewLastSelect",
                id: "select",
                value: historySelected,
                // @ts-ignore
                onChange: (event) => {
                  setHistorySelected(event.target.value);
                  setDateHistorySelected(getDateRange(event.target.value));
                  refetch();
                },
                options: securityScoreHistory.selectorOptions,
                placeholder: {
                  text: ""
                }
              }}
            />
          </ViewLastWrapper>
        </HistoryTitleWrapper>
        <HistoryDivider />
        {isLoading && (
          <ThemedSection appearance="secondary">
            <Loader text={{ text: "" }} data-testid="LoadingSpinnerWrapper" />
          </ThemedSection>
        )}

        {!isLoading && data && !isError && (
          <>
            <ChartWrapper data-testid="ChartWrapper">
              <ResponsiveLine
                theme={{
                  textColor: "white"
                }}
                axisLeft={{
                  renderTick: ({
                    x,
                    y,
                    textAnchor,
                    textX,
                    textY,
                    value
                  }: scoreInfoChart) => (
                    <g
                      transform={`translate(${x},${y})`}
                      className="axisYHistoryChart"
                    >
                      {generateScoreInfo({ value, textAnchor, textX, textY })}
                    </g>
                  ),
                  tickPadding: 20,
                  tickValues: [0, 60, 70, 80, 90, 100]
                }}
                layers={[
                  "axes",
                  "crosshair",
                  "mesh",
                  CustomLineLayer,
                  CustomGrid([0, 60, 70, 80, 90, 100]),
                  CustomPoints,
                  // @ts-ignore
                  CustomSlicesLayer
                ]}
                data={dataFormatted}
                margin={margins}
                xScale={{ type: "point" }}
                yScale={{
                  type: "linear",
                  min: 0,
                  max: 100,
                  stacked: true,
                  reverse: false
                }}
                enablePointLabel={true}
                yFormat=" >-.2f"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 10,
                  tickRotation: 0,
                  legendOffset: 36,
                  legendPosition: "middle",
                  // @ts-ignore
                  format: (dateValue) => {
                    const value = getFormattedDateForHistoryGraph(dateValue);
                    const dateArray = value.split(" ");
                    return (
                      <tspan className="axisXHistoryChart">
                        <tspan x={sm ? "0" : "-0.5em"} dy="0">
                          {dateArray[0]}
                        </tspan>
                        <tspan x={sm ? "0" : "1em"} dy={sm ? "1.2em" : "0"}>
                          {dateArray[1]}
                        </tspan>
                      </tspan>
                    );
                  }
                }}
                enableSlices={"x"}
                enableGridX={false}
                useMesh={true}
                isInteractive
              />
            </ChartWrapper>
          </>
        )}
        {isError && <AdviseNotification text="Error" />}
      </HistoryWrapper>
      {!isLoading && data && !isError && (
        <>
          <ViewDetailsWrapper>
            <Button
              inverse={theme.name === "WS10" ? false : true}
              appearance="secondary"
              text={securityScoreHistory.buttonText}
              icon={{
                name: "pop-out",
                justify: "right"
              }}
              htmlAttributes={{ target: "_blank" }}
              href={securityScoreHistory.buttonLink}
            />
          </ViewDetailsWrapper>
        </>
      )}
    </>
  );
};
