import React, { useState } from "react";

import { Heading } from "@source-web/heading";
import { Modal } from "@source-web/modal";
import { Paragraph } from "@source-web/paragraph";

import { PrimaryButton } from "../../../../components/PrimaryButton";
import {
  StyledServiceDialog,
  StyledServiceDialogBodyWrapper,
  StyledServiceDialogImage,
  StyledStepGridWrapper
} from "../../styles/ServicesPage.styles";
import { ContentfulLookoutDialog } from "../../types";
import { InvitationModal } from "./InvitationModal";
import { StepOne } from "./steps/StepOne";
import { StepThree } from "./steps/StepThree";
import { StepTwo } from "./steps/StepTwo";

export interface LookoutDialogProps {
  data: any;
  onClose: () => void;
  isDialogOpen: boolean;
  icon: string;
  emailData: {
    subject: string;
    template: {
      template: string;
    };
  };
  messagePopup: {
    messageBodyModalOne: string;
    messageIconModalOne: string;
    messageTypeModalOne: boolean;
    successfullyButton: [
      {
        buttonText: string;
        appearance: string;
        href: string;
        openInNewTab: boolean;
      }
    ];
    messageBodyModalTwo: string;
    messageIconModalTwo: string;
    messageTypeModalTwo: boolean;
    errorButton: [
      {
        buttonText: string;
        appearance: string;
        href: string;
        openInNewTab: boolean;
      }
    ];
  };
  fields: ContentfulLookoutDialog["contentfulLookoutDialog"];
}

export type StepProps = {
  heading: string;
  stepHeading: string;
};

export type LookoutDialogContentType = Omit<LookoutDialogProps, "data"> & {
  setIsInvitationDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  enrollmentCode: string;
};

export function LookoutDialogContent({
  icon,
  fields,
  enrollmentCode,
  onClose,
  setIsInvitationDialogOpen
}: LookoutDialogContentType) {
  const {
    bodyText,
    heading,
    step3Icon,
    stepOneHeading,
    stepThreeHeading,
    stepTwoHeading,
    doneButton,
    stepHeading: {
      text: { text }
    }
  } = fields;

  const onTriggerInvitationModal = () => {
    onClose();
    setIsInvitationDialogOpen(true);
  };

  return (
    <StyledServiceDialog>
      <StyledServiceDialogImage src={icon} alt={heading + " logo"} />
      <StyledServiceDialogBodyWrapper>
        <Heading text={heading} size={3} />
        <Paragraph>{bodyText}</Paragraph>
      </StyledServiceDialogBodyWrapper>
      <StyledStepGridWrapper>
        <StepOne
          onMobileParagraph={fields.onMobileParagraph.text}
          stepHeading={`${text} 1`}
          downloadLink={fields?.downloadLink}
          shareLink={fields?.shareToMultipleUsersLink}
          openInvitationModal={onTriggerInvitationModal}
          heading={stepOneHeading}
        />
        <StepTwo
          stepHeading={`${text} 2`}
          enrollmentCode={enrollmentCode}
          heading={stepTwoHeading}
        />
        <StepThree
          stepHeading={`${text} 3`}
          heading={stepThreeHeading}
          icon={step3Icon}
        />
      </StyledStepGridWrapper>
      <PrimaryButton
        text={doneButton.buttonText}
        appearance={doneButton.appearance}
        onClick={onClose}
      />
    </StyledServiceDialog>
  );
}

export function LookoutDialog({
  fields,
  onClose,
  isDialogOpen,
  emailData,
  messagePopup,
  icon,
  data
}: LookoutDialogProps) {
  const [isInvitationDialogOpen, setIsInvitationDialogOpen] = useState(false);

  const { heading, invitationModalFields } = fields;

  const enrollmentCode = data?.enrollmentCode;

  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);

  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);

  return (
    <>
      <Modal
        appearance="primary"
        overlayBlur
        srName={heading}
        isOpen={isDialogOpen}
        isClosable
        size={5}
        onCloseCb={onClose}
      >
        <LookoutDialogContent
          {...{
            enrollmentCode,
            heading,
            isDialogOpen,
            onClose,
            emailData,
            messagePopup,
            setIsInvitationDialogOpen,
            fields,
            icon
          }}
        />
      </Modal>
      <InvitationModal
        fields={invitationModalFields}
        enrollmentCode={enrollmentCode}
        isOpen={isInvitationDialogOpen}
        emailData={emailData}
        messagePopup={messagePopup}
        isSuccessPopupOpen={isSuccessPopupOpen}
        setIsSuccessPopupOpen={setIsSuccessPopupOpen}
        isErrorPopupOpen={isErrorPopupOpen}
        setIsErrorPopupOpen={setIsErrorPopupOpen}
        setIsInvitationDialogOpen={setIsInvitationDialogOpen}
        onClose={() => setIsInvitationDialogOpen(false)}
      />
    </>
  );
}
