import React from "react";

import { Divider } from "@source-web/divider";
import { Icon } from "@source-web/icon";
import { SimpleGrid } from "@source-web/simple-grid";

// @ts-ignore
import HookIcon from "../../../../../assets/lookoutIcons/hook_icon.inline.svg";
import { ContentfulLookoutDashboard } from "../../../types";
import { AnalyticsDashboardTable, AnalyticsDashboardTile } from "../components";
import {
  IssuesContainer,
  LoginsContainer,
  LookOutDashboardTitle,
  SitesBlockedContainer
} from "../styles/AnalyticsDashboard.styles";

export function LookoutDashboard({
  data,
  fields,
  isError,
  formattedLocale
}: {
  fields: ContentfulLookoutDashboard["contentfulLookoutDashboard"];
  data: any;
  isError: boolean;
  formattedLocale: string;
}) {
  const {
    secondTileTitle,
    secondTableTitle,
    fourthTileTitle,
    firstTileTitle,
    firstTableTitle,
    thirdTileTitle,
    riskTiles,
    lookoutTitle
  } = fields;

  if (isError) return null;

  return (
    <>
      <SitesBlockedContainer>
        <LookOutDashboardTitle>{lookoutTitle[1].name}</LookOutDashboardTitle>
        <Divider appearance="secondary" inverse />
        <SimpleGrid
          spacing={4}
          layout={{
            sm: [12],
            md: [12],
            lg: [6]
          }}
        >
          <AnalyticsDashboardTile
            titleOptions={{
              level: 5
            }}
            valueOptions={{
              level: 5,
              weight: 3
            }}
            isDark={false}
            label={thirdTileTitle}
            value={data?.maliciousSitesBlocked || "0"}
            icon={() => <Icon name="virus-protection" group="system" />}
          />
          <AnalyticsDashboardTile
            titleOptions={{
              level: 5
            }}
            valueOptions={{
              level: 5,
              weight: 3
            }}
            isDark={false}
            label={fourthTileTitle}
            value={data?.phishingSitesBlocked || "0"}
            icon={() => <HookIcon />}
          />
        </SimpleGrid>
      </SitesBlockedContainer>

      <IssuesContainer>
        <LookOutDashboardTitle>{lookoutTitle[0].name}</LookOutDashboardTitle>
        <Divider appearance="secondary" inverse />

        <SimpleGrid
          spacing={4}
          layout={{
            sm: [12],
            md: [6]
          }}
        >
          <AnalyticsDashboardTable
            tileFields={riskTiles}
            name={firstTableTitle}
            value={data?.openIssues}
          />

          <AnalyticsDashboardTable
            tileFields={riskTiles}
            name={secondTableTitle}
            value={data?.resolvedIssues}
          />
        </SimpleGrid>
      </IssuesContainer>

      <LoginsContainer>
        <LookOutDashboardTitle>{lookoutTitle[2].name}</LookOutDashboardTitle>
        <Divider appearance="secondary" inverse />
        <SimpleGrid
          spacing={4}
          layout={{
            sm: [12],
            md: [12],
            lg: [6]
          }}
        >
          <AnalyticsDashboardTile
            titleOptions={{
              level: 5
            }}
            valueOptions={{
              level: 5,
              weight: 3
            }}
            isDark={false}
            label={firstTileTitle}
            value={data?.adminLogins || "0"}
            icon={() => <Icon name="users" group="system" />}
          />
          <AnalyticsDashboardTile
            formattedLocale={formattedLocale}
            titleOptions={{
              level: 5
            }}
            valueOptions={{
              level: 5,
              weight: 3
            }}
            isDark={false}
            label={secondTileTitle}
            valueType="text"
            value={data?.lastAdminLogin || "None"}
            icon={() => <Icon name="clock" group="system" />}
          />
        </SimpleGrid>
      </LoginsContainer>
    </>
  );
}
