import React, { ReactElement } from "react";

import { Container } from "@source-web/container";
import { Divider } from "@source-web/divider";
import { Heading, HeadingProps } from "@source-web/heading";

import {
  AnalyticsCard,
  AnalyticsCardContentWrapper,
  AnalyticsCardTitleWrapper,
  AnalyticsCardWrapper
} from "../../styles/AnalyticsDashboard.styles";

type MaliciousSitesBlockedType = {
  trend: "Up" | "Down";
  percentage: number;
  fromPreviousDays: number;
};

export interface IAnalyticsDashboardCard {
  increaseIconSize?: boolean;
  titleOptions?: HeadingProps;
  name: string;
  children: ReactElement | string | number;
  icon?: string | undefined | ReactElement;
  statistics?: MaliciousSitesBlockedType;
  alignTitle?: string;
}

export function AnalyticsDashboardCard({
  name,
  children,
  increaseIconSize = true,
  alignTitle = "flex-start",
  titleOptions = {
    level: 5,
    weight: 3,
    noMargin: true
  }
}: IAnalyticsDashboardCard) {
  return (
    <AnalyticsCard>
      <Container>
        <AnalyticsCardWrapper increaseIconSize={increaseIconSize}>
          <AnalyticsCardTitleWrapper align={alignTitle}>
            <Heading {...titleOptions} text={name} />
          </AnalyticsCardTitleWrapper>
          <Divider appearance="secondary" inverse />
          <AnalyticsCardContentWrapper>
            {typeof children === "object" ? (
              children
            ) : (
              <Heading level={3} weight={1} text={`${children}`} />
            )}
          </AnalyticsCardContentWrapper>
        </AnalyticsCardWrapper>
      </Container>
    </AnalyticsCard>
  );
}
