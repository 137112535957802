import styled, { css } from "styled-components";

import { Theme } from "@source-web/themes";

export const TableWrapper = styled.div(
  (props) => css`
    background-color: ${props.theme.color.monochrome1.default};
    padding: 20px;
    border-radius: 6px;

    div[data-name="container-fluid"] {
      padding: 0;
    }
  `
);

export const FilterWrapper = styled.div(
  (props) => css`
    margin-bottom: ${props.theme.spacing.fixed[6]}px;

    & select {
      background-color: ${props.theme.color.monochrome2.default};
      color: ${props.theme.color.monochrome6.default};
    }
  `
);

export const RowWrapper = styled.div(
  (props: { underline: boolean; theme: Theme }) => css`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: ${props.underline
      ? `1px solid ${props.theme.color.monochrome5.default}`
      : ""};
  `
);
