import React from "react";

import { HorizontalBarChart } from "@source-web/horizontal-bar-chart";

import { Heading } from "@vfuk/core-heading";

import { SingleStackedBarTileFields } from "../../../../types";
import {
  HorizontalBarChartWrapper,
  SingleStackedBarTileContainer
} from "../../styles/AnalyticsDashboard.styles";

export function SingleStackedBarTile({
  chartData,
  fields
}: {
  chartData: { [key: string]: string };
  fields: SingleStackedBarTileFields;
}) {
  const totalNumber = Object.values(chartData).reduce(
    (acc, value) => acc + Number(value),
    0
  );
  const chartSteps = Object.keys(chartData).map((item: string) => {
    return {
      key: fields.chartItems.find((chartItem) => chartItem.itemId === item)
        ?.text,
      percentage: (Number(chartData[item]) * 100) / totalNumber,
      value: chartData[item]
    };
  });

  return (
    <SingleStackedBarTileContainer>
      <Heading weight={3} size={1}>
        {fields.tileTitle}
      </Heading>
      <HorizontalBarChartWrapper>
        <HorizontalBarChart
          id="horizontal-bar-chart"
          topText={{
            left: `${fields.chartTitle}`,
            right: `${fields.totalText} ${totalNumber}`
          }}
          inverse
          steps={chartSteps}
        />
      </HorizontalBarChartWrapper>
    </SingleStackedBarTileContainer>
  );
}
