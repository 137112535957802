import React from "react";

import { ProgressBar } from "@source-web/progress-bar";

import { MappedCultureData } from "../../CybSafe";
import {
  CultureScore,
  DesktopScore,
  Legend,
  Metrics,
  MobileScore,
  Score,
  ScoreCard
} from "../../styles/CybSafe.styles";

interface CultureMetricsProps {
  data: Array<MappedCultureData>;
}

export function CultureMetrics({ data }: CultureMetricsProps) {
  return (
    <CultureScore data-testid="culture-metrics">
      {data.map((cultures, index) => (
        <ScoreCard key={cultures.company.trim() + index.toString()}>
          <DesktopScore>
            <Score> ({cultures.company}) </Score>
            <Metrics> {cultures.culture} </Metrics>
          </DesktopScore>

          <MobileScore>
            <Legend>
              <Metrics> {cultures.culture} </Metrics>
              <Score> ({cultures.company}) </Score>
            </Legend>
            <ProgressBar
              inverse
              appearance="secondary"
              value={parseInt(cultures.company)}
              srLabel="Progress bar"
            />
          </MobileScore>
        </ScoreCard>
      ))}
    </CultureScore>
  );
}
