import { graphql, navigate } from "gatsby";
import { paramCase } from "param-case";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useTheme } from "styled-components";

import { Checkbox } from "@source-web/checkbox";
import { Heading } from "@source-web/heading";
import { Icon } from "@source-web/icon";
import { IconPopOut as IconPopOutDark } from "@source-web/source-mid-render-dark-icons";
import { IconPopOut as IconPopOutLight } from "@source-web/source-mid-render-light-icons";
import { Span } from "@source-web/span";

import { PageHeading, ThemedSection } from "../../components";
import { ContentfulRichText } from "../../components/ContentfulRichText";
import PartnerModal from "../../components/PartnerModal";
import { PrimaryButton } from "../../components/PrimaryButton";
import { lookoutFactory } from "../../context/auth/helpers";
import { useAuth } from "../../context/auth/useAuth";
import { useCampaignFormContext } from "../../context/forms/useForms";
import {
  formFlowEnums,
  getReports,
  getTrendMicroInsights,
  isJourneyPopUp,
  isServer,
  journeyHandleClick,
  primaryButtonServiceClick,
  secondaryButtonServiceClick,
  trackCampaignButtonClick,
  useEventSource
} from "../../lib";
import { getContentfulAsset } from "../../store/contentful";
import {
  AnalyticsDashboard,
  ConnectedSecurityScoreCard,
  CybSafe,
  LookoutDashboard,
  LookoutDialog,
  ManagedFirewall,
  ServiceTabs,
  TrendMicroDashboard,
  VSDMDashboard
} from "./components/";
import { useCheckBoxForModal } from "./hooks/useCheckBoxForModal";
import {
  AwarenessOverview,
  AwarenessRates,
  BehaviorScoreData,
  Culture,
  PhishingStats,
  useCybSafeData
} from "./hooks/useCybSafeData";
import { useVSDMData } from "./hooks/useVSDMData";
import {
  ActionButtonWrapper,
  ActionButtonWrapperHref,
  DesktopContainer,
  IconAndPhoneNumberWrapper,
  MainContainer,
  MobileContainer,
  PartnerBody,
  PartnerContent,
  PartnerFooter,
  PartnerHeader,
  PartnerModalContent,
  StyledButtonWrapper,
  SupportBannerWrapper
} from "./styles/ServicesPage.styles";
import { Insights, ServicePageType, SupportBannerFields } from "./types";

export const query = graphql`
  query GetServicePageData($service: String, $locale: String) {
    contentfulPageHeading(
      pageName: { eq: "Manage" }
      node_locale: { eq: $locale }
    ) {
      headingBackgroundImageDark {
        gatsbyImageData(height: 450, quality: 95)
      }
      headingBackgroundImageLight {
        gatsbyImageData(height: 450, quality: 95)
      }
      headingBackgroundMobileDark {
        gatsbyImageData(height: 400, quality: 95)
      }
      headingBackgroundMobileLight {
        gatsbyImageData(height: 400, quality: 95)
      }
      messageBodyModalOne
      messageIconModalOne
      messageTypeModalOne
      successfullyButton {
        buttonText
        appearance
        href
        openInNewTab
      }
      messageBodyModalTwo
      messageIconModalTwo
      messageTypeModalTwo
      errorButton {
        buttonText
        appearance
        href
        openInNewTab
      }
    }
    contentfulTrendMicroDashboard(node_locale: { eq: $locale }) {
      secondCardTitle
      firstCardTitle
      chartTitle
      vulnerabilityTypes
      firstListTitle
      secondListTitle
      allVulnerabilityTypes {
        internal {
          content
        }
      }
      labelsText
      desktopLabel
      mobileLabel
      filterName
      filterOptions {
        text
        value
      }
    }
    contentfulLookoutDialog(node_locale: { eq: $locale }) {
      heading
      bodyText
      stepOneHeading
      stepTwoHeading
      doneButton {
        buttonText
        appearance
      }
      stepHeading {
        text {
          text
        }
      }
      onMobileParagraph {
        text {
          text
        }
      }
      stepThreeHeading
      downloadLink {
        text
        target
      }
      shareToMultipleUsersLink {
        text
        target
      }
      step3Icon {
        file {
          url
        }
      }
      invitationModalFields {
        csvDescription
        dialogHeading
        uploadCsvButton {
          buttonText
          appearance
        }
        multipleEmailsField {
          label
          placeholder
          fieldId
        }
        emailBodyField {
          placeholder
          label
          fieldId
        }
        emailsAddedMessage
        defaultEmailBodyMessage
        submitButton {
          buttonText
          appearance
        }
        description {
          text {
            text
          }
        }
      }
    }
    contentfulPackageTile(
      packageTile: { eq: $service }
      node_locale: { eq: $locale }
    ) {
      packageTile
      widgetInfo
      buttonName
      header
      widgetSubText
    }
    contentfulLookoutDashboard(node_locale: { eq: $locale }) {
      secondTileTitle
      secondTableTitle
      fourthTileTitle
      firstTileTitle
      firstTableTitle
      thirdTileTitle
      riskTiles {
        text
        order
        color
        tileId
      }
      lookoutTitle {
        name
      }
      supportBannerText
      supportBannerIcon
      supportBannerPhoneNumber
      supportBannerAdditionalNote
    }
    contentfulIndustryBenchmark(node_locale: { eq: $locale }) {
      yourCompanyText
      companyLabel
      industryLabel
      industryDescription {
        text {
          text
        }
      }
      factorsDescription {
        text {
          text
        }
      }
      errorTitle
      errorText
    }
    allContentfulServiceTile(
      filter: { heading: { eq: $service }, node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          heading
          bannerHeading
          subHeading {
            subHeading
          }
          icon {
            file {
              url
            }
          }
          darkIcon {
            file {
              url
            }
          }
          isCampaignService
          productId
          isWalkthroughService
          havePackageWidget
          walkthroughButton
          buttonText
          secondaryButtonText
          secondaryButtonUrl
          serviceHlId
          campaignType
          isSwap
          isPopUpForProduct
          childContentfulServiceTileFormJsonNode {
            internal {
              content
            }
          }
          url
        }
      }
    }
    allContentfulScoreConfiguration(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          colour
          rating
          score
          text
          hexCode
        }
      }
    }
    contentfulSecurityScorePage(
      node_locale: { eq: $locale }
      tabs: { elemMatch: { page: { eq: "ssc" } } }
    ) {
      heading
      tooltipHelpText
      buttonText
      factorsTitle
      platformUrl
      vendorName
      scoreConfigurationHeading
      scoreConfigurationTableHeadings
      tabs {
        contentfulid
        label
        order
      }
    }
    contentfulSecurityScore(node_locale: { eq: $locale }) {
      emptyScoreMessage
      calculatingText
    }
    contentfulSecurityScoreHistory(node_locale: { eq: $locale }) {
      heading
      selectorText
      buttonText
      buttonLink
      selectorOptions {
        text
        value
      }
    }
    allContentfulSecurityScorecardRatings(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          heading
          description {
            internal {
              content
            }
          }
          isCapital
          ratingId
        }
      }
    }
    contentfulPartnerSignInModal(node_locale: { eq: $locale }) {
      signInHeading
      redirectMessage
      redirectInfo
      redirectEmail
      redirectContact
      journeyPopUpHeading
      journeyPopUpCTA
      journeyPopUpCheckbox
      journeyPopUpBody {
        raw
      }
      journeyPopUpContactInfo {
        raw
      }
    }
    allContentfulSecurityRating(filter: { node_locale: { eq: $locale } }) {
      nodes {
        name
        industryId
      }
    }
    contentfulCybSafeDashboardTiles(node_locale: { eq: $locale }) {
      activatedUsersTile
      activatedUsersHelpText
      refresherRateTile
      sscHeading
    }
    lookoutEmailNotification: contentfulEmailsNotifications(
      node_locale: { eq: $locale }
      name: { eq: "Share Lookout For Work link" }
    ) {
      subject
      name
      files {
        url
      }
      template {
        template
      }
    }
    contentfulVsdmDashboard(node_locale: { eq: $locale }) {
      dashboardTitle
      totalDeploymentText
      totalSecurityTitle
      lastSeenTitle
      compromisedPieText
      noPasscodePieText
      notEncryptedPieText
      lastSeenPieText
      ownershipStatusTile {
        tileTitle
        chartTitle
        totalText
        chartItems {
          text
          itemId
        }
      }
      lastSeenTile {
        tileTitle
        rightText
        chartItems {
          text
          itemId
          itemColor
        }
      }
      enrollmentStatusTile {
        tileTitle
        chartTitle
        totalText
        chartItems {
          text
          itemId
        }
      }
      platformStatusTile {
        tileTitle
        rightText
        chartItems {
          text
          itemId
          itemColor
        }
      }
      operatingSystemBreakdown {
        heading
        keyName
        valueName
        dropdown {
          text
          itemId
        }
      }
      supportBannerText
      supportBannerIcon
      supportBannerPhoneNumber
      supportBannerAdditionalNote
    }
  }
`;

const componentForServiceExists = (
  componentsObject: Object,
  serviceId: number
) => Object.prototype.hasOwnProperty.call(componentsObject, serviceId);

const ServicePage = ({
  data,
  pageContext: { formattedLocale, locale }
}: ServicePageType) => {
  const node = data.allContentfulServiceTile.edges[0].node;

  const {
    heading,
    bannerHeading,
    subHeading,
    isCampaignService,
    isWalkthroughService,
    walkthroughButton,
    buttonText,
    secondaryButtonText,
    secondaryButtonUrl,
    url,
    campaignType,
    icon,
    darkIcon,
    havePackageWidget,
    serviceHlId,
    isSwap,
    isPopUpForProduct
  } = node;

  const emailData = data.lookoutEmailNotification;

  const {
    journeyPopUpHeading,
    journeyPopUpCTA,
    journeyPopUpCheckbox,
    journeyPopUpBody,
    journeyPopUpContactInfo
  } = data.contentfulPartnerSignInModal;

  const {
    headingBackgroundImageDark,
    messageBodyModalOne,
    messageIconModalOne,
    messageTypeModalOne,
    successfullyButton,
    messageBodyModalTwo,
    messageIconModalTwo,
    messageTypeModalTwo,
    errorButton
  } = data.contentfulPageHeading;

  const messagePopup = {
    messageBodyModalOne,
    messageIconModalOne,
    messageTypeModalOne,
    successfullyButton,
    messageBodyModalTwo,
    messageIconModalTwo,
    messageTypeModalTwo,
    errorButton
  };

  const [reports, setReports] = useState<any>();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useCheckBoxForModal(
    "productCheckBoxState",
    false
  );
  const { initialize } = useCampaignFormContext();
  const auth = useAuth();
  const theme = useTheme();
  const isDark = theme.name !== "WS10";

  const { raw: rawBody } = journeyPopUpBody;
  const { raw: rawContact } = journeyPopUpContactInfo;

  function formatContent(template: string, replacement: string) {
    return template.replace(/\{productName\}/g, replacement);
  }

  const formattedPopUpHeading = formatContent(journeyPopUpHeading, heading);
  const formattedPopUpBody = formatContent(rawBody, heading);
  const formattedPopUpContact = formatContent(rawContact, heading);

  const {
    isLoading: isLoadingLookout,
    data: lookoutData,
    isError: lookoutError
  } = useEventSource<Insights>(`lookout/insights/`, {
    enabled: serviceHlId === 8,
    dataParsingFunction: lookoutFactory,
    enableLogs: true,
    isSingleEntity: true
  });

  const {
    data: VSDMData,
    isLoading: VSDMLoading,
    isError: VSDMError
  } = useVSDMData(serviceHlId === 11);

  const {
    data: cybsafeData,
    isLoading: cybsafeDataLoading,
    isError: cybsafeDataError
  } = useCybSafeData(serviceHlId === 20);

  const {
    data: trendData,
    // isLoading: isLoadingTrend,
    isError: trendError
  } = useQuery({
    queryKey: "trend",
    queryFn: getTrendMicroInsights,
    enabled: serviceHlId === 9
  });

  /**
   * this function will open the form for the selected service
   */
  function openForm(flow: string) {
    initialize({
      heading,
      flow
    });

    trackCampaignButtonClick({
      page_locale: locale,
      page_name: heading,
      journey_type: flow
    });
  }

  /**
   * @returns a function based on param-cased service name
   * this function will be executed when the secondary button is clicked
   */
  function handleSecondaryButtonClick(): void {
    switch (serviceHlId) {
      case 8:
        setIsDialogOpen(true);
        return;
      default:
        return;
    }
  }

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
  };

  function handleButtonContent(heading: string) {
    return (
      <StyledButtonWrapper isSwap={isSwap}>
        {isCampaignService ? (
          <ActionButtonWrapper havePackageWidget={havePackageWidget}>
            <PrimaryButton
              text={buttonText ?? ""}
              onClick={() => {
                campaignType !== ""
                  ? openForm(campaignType)
                  : openForm(formFlowEnums.campaign);
              }}
              appearance="primary"
            />
          </ActionButtonWrapper>
        ) : null}

        {!isCampaignService && url && (
          <ActionButtonWrapperHref havePackageWidget={havePackageWidget}>
            <>
              <PrimaryButton
                text={buttonText || ""}
                href={url}
                appearance={isSwap ? "alt1" : "primary"}
                htmlAttributes={{ target: "_blank" }}
                inverse={isDark}
                onClick={(e) => {
                  primaryButtonServiceClick({
                    page_locale: formattedLocale,
                    journey_type: `${heading} - ${buttonText}`
                  });

                  if (isPopUpForProduct) {
                    journeyHandleClick(
                      e,
                      formattedLocale,
                      isChecked,
                      handleOpenModal,
                      handleCloseModal,
                      url
                    );
                  }
                }}
              />
              <PartnerModal
                isOpen={isModalOpen}
                isCloseable={true}
                onCloseCb={handleCloseModal}
              >
                <>
                  <PartnerModalContent>
                    <PartnerHeader>
                      {isDark ? (
                        <IconPopOutDark size={2} />
                      ) : (
                        <IconPopOutLight size={2} />
                      )}
                      <Heading
                        text={formattedPopUpHeading}
                        size={1}
                        justify="center"
                        level={1}
                      />
                    </PartnerHeader>
                    {/* CYB-18487 - Removed copy from Italy market */}
                    {formattedLocale !== "it" ? (
                      <>
                        <PartnerBody>
                          <ContentfulRichText text={formattedPopUpBody} />
                        </PartnerBody>
                        <PartnerContent>
                          <ContentfulRichText text={formattedPopUpContact} />
                        </PartnerContent>
                      </>
                    ) : null}
                  </PartnerModalContent>
                  {formattedLocale !== "it" ? (
                    <>
                      <PartnerBody>
                        <Checkbox
                          value="donotseepopop"
                          id="donotseepopop"
                          name="donotseepopop"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        >
                          {journeyPopUpCheckbox}
                        </Checkbox>
                      </PartnerBody>
                      <PartnerFooter>
                        <PrimaryButton
                          text={journeyPopUpCTA || ""}
                          href={url}
                          appearance={"primary"}
                          htmlAttributes={{ target: "_blank" }}
                          inverse={isDark}
                          onClick={(e) => {
                            isJourneyPopUp(e, isServer, url);
                            handleCloseModal();
                          }}
                        />
                      </PartnerFooter>
                    </>
                  ) : null}
                </>
              </PartnerModal>
            </>
          </ActionButtonWrapperHref>
        )}

        {secondaryButtonText ? (
          <ActionButtonWrapperHref havePackageWidget={havePackageWidget}>
            {secondaryButtonUrl ? (
              <PrimaryButton
                text={secondaryButtonText}
                href={secondaryButtonUrl}
                inverse={isDark}
                appearance={isSwap ? "primary" : "alt1"}
                htmlAttributes={{ target: "_blank" }}
                onClick={(e) => {
                  secondaryButtonServiceClick({
                    page_locale: formattedLocale,
                    journey_type: `${heading} - ${secondaryButtonText}`
                  });
                  if (isPopUpForProduct) {
                    journeyHandleClick(
                      e,
                      formattedLocale,
                      isChecked,
                      handleOpenModal,
                      handleCloseModal,
                      secondaryButtonUrl
                    );
                  }
                }}
              />
            ) : (
              <PrimaryButton
                text={secondaryButtonText}
                onClick={() => {
                  secondaryButtonServiceClick({
                    page_locale: formattedLocale,
                    journey_type: `${heading} - ${secondaryButtonText}`
                  });
                  handleSecondaryButtonClick();
                }}
                appearance={isSwap ? "primary" : "alt1"}
                inverse={isDark}
                htmlAttributes={{ target: "_blank" }}
              />
            )}
          </ActionButtonWrapperHref>
        ) : null}
      </StyledButtonWrapper>
    );
  }

  const renderSupportBanner = (dashboardData: SupportBannerFields) => {
    if (!dashboardData || !dashboardData.supportBannerText) {
      return undefined;
    }

    return (
      <SupportBannerWrapper>
        {dashboardData.supportBannerText.split(" ").map((word, i) => (
          <Span key={i}>{word}</Span>
        ))}
        <IconAndPhoneNumberWrapper>
          <Icon
            name={dashboardData.supportBannerIcon}
            group="system"
            size={4}
          />
          <Span>{dashboardData.supportBannerPhoneNumber}</Span>
        </IconAndPhoneNumberWrapper>
        {dashboardData.supportBannerAdditionalNote.split(" ").map((word, i) => (
          <Span key={i}>{word}</Span>
        ))}
      </SupportBannerWrapper>
    );
  };

  /**
   * @returns a component based on the service HLID (defined in contentful)
   * this component will be shown below the pageHeading component.
   * return the default service tabs if  doesn't exist
   */
  function handleServicePageContent() {
    const props = {
      openFormCallback: openForm,
      serviceId: serviceHlId,
      heading: heading,
      formattedLocale: formattedLocale,
      isWalkthroughService: isWalkthroughService,
      walkthroughButtonText: walkthroughButton,
      reports: reports
    };

    const buttons = (
      <MobileContainer>{handleButtonContent(heading)}</MobileContainer>
    );

    const lookoutSupportBanner = renderSupportBanner(
      data.contentfulLookoutDashboard
    );
    const vsdmSupportBanner = renderSupportBanner(data.contentfulVsdmDashboard);

    const componentByHighLevelId: Record<number, React.ReactElement> = {
      2: (
        <>
          <ManagedFirewall {...props} />
          {buttons}
        </>
      ),
      8: (
        <AnalyticsDashboard
          isLoading={isLoadingLookout}
          isError={lookoutError}
          bannerHeading={bannerHeading}
          buttons={buttons}
          supportBanner={lookoutSupportBanner}
          selectedDashboard={
            <LookoutDashboard
              isError={lookoutError}
              data={lookoutData}
              fields={data.contentfulLookoutDashboard}
              formattedLocale={formattedLocale}
            />
          }
        />
      ),
      // TODO: not load data until they are not ready, show error
      9: (
        <AnalyticsDashboard
          isLoading={false}
          isError={trendError}
          bannerHeading={bannerHeading}
          selectedDashboard={
            <TrendMicroDashboard
              data={trendData}
              fields={data.contentfulTrendMicroDashboard}
            />
          }
        />
      ),

      10: (
        <>
          <ConnectedSecurityScoreCard
            platformUrl={data?.contentfulSecurityScorePage?.platformUrl}
            industryBenchmarkData={data?.contentfulIndustryBenchmark}
            securityScoreCardConfigurationData={
              data.allContentfulScoreConfiguration
            }
            securityScoreCardRatings={
              data.allContentfulSecurityScorecardRatings
            }
            partnermodalData={data?.contentfulPartnerSignInModal}
            securityScorePageData={{
              ...data?.contentfulSecurityScorePage,
              factorsButtonText: data?.contentfulSecurityScorePage?.buttonText,
              ...data.contentfulSecurityScore
            }}
            securityRatings={data.allContentfulSecurityRating}
            formattedLocale={formattedLocale}
            securityScoreHistory={data.contentfulSecurityScoreHistory}
          />
          {buttons}
        </>
      ),

      11: (
        <AnalyticsDashboard
          isLoading={VSDMLoading}
          isError={VSDMError}
          bannerHeading={bannerHeading}
          buttons={buttons}
          supportBanner={vsdmSupportBanner}
          selectedDashboard={
            <VSDMDashboard
              data={VSDMData}
              fields={data.contentfulVsdmDashboard}
            />
          }
        />
      ),
      20: (
        <AnalyticsDashboard
          isLoading={cybsafeDataLoading}
          bannerHeading={bannerHeading}
          isError={cybsafeDataError}
          buttons={buttons}
          selectedDashboard={
            <CybSafe
              score={cybsafeData?.behaviorScore as BehaviorScoreData}
              data={data.contentfulCybSafeDashboardTiles}
              awarenessOverview={
                cybsafeData?.awarenessOverview as AwarenessOverview
              }
              culture={cybsafeData?.culture as Culture}
              awarenessRates={cybsafeData?.awarenessRates as AwarenessRates}
              phishing={cybsafeData?.phishing as PhishingStats}
            />
          }
        />
      )
    };

    if (componentForServiceExists(componentByHighLevelId, serviceHlId)) {
      return componentByHighLevelId[serviceHlId];
    }

    return (
      <>
        <ServiceTabs {...props} />
        {buttons}
      </>
    );
  }

  /**
   * @returns a component based on the service HLID (defined in contentful)
   * this component will be shown at a overlay.
   * if the service doesn't have a overlay, just return null
   */
  function handleServicesOverlay() {
    const serviceDialogs: Record<number, React.ReactElement> = {
      8: (
        <LookoutDialog
          fields={data.contentfulLookoutDialog}
          data={lookoutData}
          emailData={emailData}
          messagePopup={messagePopup}
          isDialogOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          icon={isDark ? darkIcon?.file?.url : icon?.file?.url}
        />
      )
    };
    if (componentForServiceExists(serviceDialogs, serviceHlId))
      return serviceDialogs[serviceHlId];

    return null;
  }

  async function fetchReports() {
    if (!auth?.authState?.user?.userId) return;

    if (!reports) {
      const paramCasedHeading = paramCase(heading);
      try {
        await getReports(auth.authState.user.userId, paramCasedHeading)
          .then(async ({ data: requestData }: any) => {
            const fileId = requestData?.accentureReport;

            const file = fileId
              ? await getContentfulAsset(fileId["en-GB"]?.sys?.id)
              : null;

            const onboardingCallDateAndTime =
              requestData.onboardingCallDateAndTime["en-GB"];

            setReports([
              {
                uuid: 1,
                endDate: onboardingCallDateAndTime,
                startDate: onboardingCallDateAndTime,
                description: heading + " Campaign",
                report: file
              }
            ]);
          })
          .catch((err) => console.error(err));
      } catch (e) {
        console.error(e);
      }
    }
  }

  useEffect(() => {
    if (isCampaignService) fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Helper functions for lookout
  function getLicensesUsed(): number {
    return (lookoutData as Insights)?.licensesUsed ?? 0;
  }

  function getLicensesPurchased(): number {
    return (lookoutData as Insights)?.licensesPurchased ?? 0;
  }

  function getDevicesUsage(serviceHlId: number): number | undefined {
    switch (serviceHlId) {
      case 8:
        return getLicensesUsed();
      case 9:
        return 0; // Mock data before integration with trend micro
      default:
        return undefined;
    }
  }

  function getMaxDevicesUsage(serviceHlId: number): number | undefined {
    switch (serviceHlId) {
      case 8:
        return getLicensesPurchased();
      case 9:
        return 100; // Mock data before integration with trend micro
      default:
        return undefined;
    }
  }

  function getHandleClick(
    serviceHlId: number,
    formattedLocale: string
  ): (() => void) | undefined {
    const licensesUsed = getLicensesUsed();
    const licensesPurchased = getLicensesPurchased();

    switch (serviceHlId) {
      case 8:
        return licensesUsed >= licensesPurchased
          ? () =>
              navigate(`/${formattedLocale}/cybershop#lookout-mobile-security`)
          : () => handleOpenModal();
      case 7:
        return () => console.log("Make a call");
      case 10:
        if (formattedLocale !== "it") {
          return () =>
            navigate(`/${formattedLocale}/cybershop#security-scorecard`);
        }
      // Intentional fallthrough
      default:
        return undefined;
    }
  }

  return (
    <MainContainer>
      <PageHeading
        headingText={heading}
        headingSubtext={subHeading?.subHeading}
        backgroundImgDark={headingBackgroundImageDark}
        longerLength
        havePackageWidget={havePackageWidget}
        formattedLocale={formattedLocale}
        packageTileData={data.contentfulPackageTile}
        devicesUsage={getDevicesUsage(serviceHlId)}
        maxDevicesUsage={getMaxDevicesUsage(serviceHlId)}
        handleClick={getHandleClick(serviceHlId, formattedLocale)}
        isService={!!serviceHlId}
      >
        <DesktopContainer>{handleButtonContent(heading)}</DesktopContainer>
      </PageHeading>
      <ThemedSection appearance="primary" fullHeight>
        {handleServicePageContent()}
      </ThemedSection>
      {handleServicesOverlay()}
    </MainContainer>
  );
};

export default ServicePage;
