import React, { useEffect, useState } from "react";
import { useTheme } from "styled-components";

import { Tab, Tabs } from "@source-web/tabs";

import { ThemedSection } from "../../../../components";
import { ICompanyFactor, ISecurityScorecardRootObject } from "../../../../lib";
import { TabContent, TabsContainer } from "../../styles/ServicesPage.styles";
import { ISecurityScoreCardProps } from "./ConnectedSecurityScoreCard";
import { History } from "./components/History";
import { ConnectedIndustryBenchmark } from "./components/IndustryBenchmark";
import { ScoreAndFactors } from "./components/ScoreAndFactors";
import { SecurityScoreCardWrapper } from "./styles/SecurityScoreCard.styles";

export type getFactorDataType = (factorName: string) =>
  | {
      heading: string;
      description: {
        internal: {
          content: string;
        };
      };
    }
  | undefined;

export type CompanyFactorsContentful = {
  heading: string;
  description: {
    internal: {
      content: string;
    };
  };
};

export interface ISecurityScoreCardPresentationProps
  extends ISecurityScoreCardProps {
  scoreData?: ISecurityScorecardRootObject;
  isLoading: boolean;
}

export function SecurityScoreCard({
  securityScoreCardConfigurationData,
  securityScorePageData,
  industryBenchmarkData,
  platformUrl,
  securityScoreCardRatings,
  securityRatings,
  scoreData,
  isLoading,
  partnermodalData,
  formattedLocale,
  securityScoreHistory
}: ISecurityScoreCardPresentationProps) {
  const [industry, setIndustry] = useState<
    ISecurityScorecardRootObject["industry"] | null
  >(null);

  const [selectedFactor, setSelectedFactor] = useState<ICompanyFactor | null>(
    null
  );

  useEffect(() => {
    if (scoreData?.companyFactors?.length) {
      setIndustry(scoreData.industry);
      setSelectedFactor(scoreData.companyFactors[0]);
    }
  }, [scoreData]);
  const { name } = useTheme();
  const isDark = name === "WS10Dark";

  const factorInContentful: getFactorDataType = (factorName: string) =>
    securityScoreCardRatings?.edges?.find(({ node }) => {
      const lowerCasedFactorNames = [
        node?.ratingId.replace(" ", "").toLocaleLowerCase(),
        factorName?.toLocaleLowerCase()
      ];

      return lowerCasedFactorNames[0] === lowerCasedFactorNames[1];
    })?.node;

  const securityScoreTabs: Record<string, React.JSX.Element> = {
    securityScoreCard: (
      <ScoreAndFactors
        {...{
          securityScorePageData,
          isLoading,
          scoreData,
          securityScoreCardConfigurationData,
          isDark,
          securityScoreCardRatings,
          selectedFactor,
          setSelectedFactor,
          factorInContentful,
          platformUrl,
          partnermodalData,
          formattedLocale
        }}
      />
    ),
    industryBenchmark: (
      <TabContent>
        {scoreData && (
          <ThemedSection appearance="secondary">
            <SecurityScoreCardWrapper>
              <ConnectedIndustryBenchmark
                industryBenchmarkData={industryBenchmarkData}
                securityScoreCardRatings={securityScoreCardRatings}
                companyName={scoreData?.name}
                industry={industry}
                setIndustry={setIndustry}
                companyFactors={scoreData?.companyFactors}
                securityRatings={securityRatings}
              />
            </SecurityScoreCardWrapper>
          </ThemedSection>
        )}
      </TabContent>
    ),
    history: (
      <TabContent>
        <ThemedSection appearance="secondary">
          <History securityScoreHistory={securityScoreHistory} />
        </ThemedSection>
      </TabContent>
    )
  };

  return (
    <TabsContainer>
      <div>
        <Tabs
          {...(name === "WS10Dark" && {
            appearance: "secondary",
            inverse: true
          })}
        >
          {securityScorePageData.tabs.map((tab) => {
            return (
              <Tab
                text={tab.label}
                key={tab.contentfulid}
                id={tab.contentfulid}
              >
                {securityScoreTabs[tab.contentfulid]}
              </Tab>
            );
          })}
        </Tabs>
      </div>
    </TabsContainer>
  );
}
