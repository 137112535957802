import styled, { css } from "styled-components";

import { Theme } from "@source-web/themes";

export const AnalyticsGraphContainer = styled.div`
  ${(props) => css`
    @media (min-width: ${props.theme.breakpoints.lg}px) {
      height: unset;
    }
  `}
`;

export const AnalyticsGraphWrapper = styled.div`
  border-top: 1px solid;
  width: 100%;
  margin-bottom: 1.25em;
`;

export const AnalyticsGraph = styled.div`
  min-height: 450px;
  height: 450px;
  max-height: 1000px;
  width: 100%;
`;

export const IconAndFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    padding: 1.25em;
  }
`;

export const LabelAndPercentageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`;

export const IconWrapper = styled.div`
  display: flex;
  margin: 1.25em 0;
  & > * {
    margin-inline: 10px;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 1em;
  height: 12em;
  padding-top: 1.25em;

  overflow-x: auto;
  white-space: nowrap;

  padding-left: 14em;

  ::-webkit-scrollbar {
    opacity: 0;
  }

  & > div {
    margin-inline: 0.9em;
    width: 10em;
    max-height: 150px;
  }

  ${(props) => css`
    @media (min-width: ${props.theme.breakpoints.md}px) {
      justify-content: space-between;
      white-space: unset;

      padding-left: 0;

      & > div {
        margin-inline: 10px;
        width: 30%;
      }
    }
  `};
`;

export const TableWrapper = styled.div`
  height: 100%;
  margin-top: 1.25em;
  & table {
    & > thead {
      background: ${(props) => props.theme.color.monochrome1.default};
      & > tr > th:first-of-type {
        display: none;
      }
    }
    & > tbody > tr {
      height: 60px;

      & > th {
        display: none;
      }
    }
  }
`;

export const ListItem = styled.div(
  (props: { theme: Theme; isActive: boolean }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 180px;
    padding: 1.75em;
    transition: 0.6s;
    border-radius: 10px;
    white-space: nowrap;
    box-shadow: ${props.isActive
      ? `0px 0px 21px 0px rgba(0, 0, 0, 0.31)`
      : "unset"};

    &:hover {
      box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.31);
      cursor: pointer;
    }

    & > div {
      margin-inline: 10px;
    }

    @media (min-width: ${props.theme.breakpoints.lg}px) {
      white-space: nowrap;
    }
  `
);

export const ValueWrapper = styled.span(
  (props: { children: any; theme: Theme }) => {
    return `
    color:${
      props.children !== 0 && typeof props.children[1] !== "string"
        ? props.theme.color.info.default
        : props.theme.color.monochrome6.default
    }
`;
  }
);

export const ObjectValueWrapper = styled.div(
  (props: { children: any; theme: Theme }) => {
    return `
    width:100%;
    display:flex;
    align-items:center;
    color:${
      props.children !== 0 && typeof props.children !== "string"
        ? props.theme.color.info.default
        : props.theme.color.monochrome6.default
    };

    & > span:first-of-type {
      display:flex;
      width:40%;
      margin:1.25em;
      align-items:center;
      justify-content:center;
    }

    @media(min-width: ${props.theme.breakpoints.sm}) and (max-width:${
      props.theme.breakpoints.md
    }) {
      justify-content: space-between;
    }
`;
  }
);

export const ObjectValueEntryWrapper = styled.div((props) => {
  return `
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: end;
    justify-content: center;

    & > span {
      padding-right: ${props.theme.spacing.fixed[4]}px;
    }

    @media (max-width: ${props.theme.breakpoints.md}px) {
      width: 100%;
    }

    @media (min-width: ${props.theme.breakpoints.sm}px) and (max-width: ${props.theme.breakpoints.md}px) {
      align-items: flex-start;
    }
  `;
});

export const ObjectValueEntrySegregation = styled.div(({ theme }) => {
  return `
    display: flex;
    justify-content: space-between;
    min-width: 130px;
    padding: 0.5em 0; 
    
    &:not(:last-child) {
      border-bottom: 1px solid;
    }
    
    @media (min-width: ${theme.breakpoints.md}px) and (max-width: ${theme.breakpoints.lg}px) {
      min-width: 200px;
    }
  `;
});
