import React from "react";

import { Heading } from "@source-web/heading";
import { Span } from "@source-web/span";

import { StyledStepGridItemWrapper } from "../../../styles/ServicesPage.styles";
import { StepProps } from "../LookoutDialog";

export function StepTwo({
  heading,
  stepHeading,
  enrollmentCode
}: StepProps & { enrollmentCode: string }) {
  return (
    <StyledStepGridItemWrapper>
      <Heading text={stepHeading} weight={3} level={4} />
      <Span>{heading}</Span>
      <div>
        <Heading text={enrollmentCode} />
      </div>
    </StyledStepGridItemWrapper>
  );
}
