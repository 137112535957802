import React from "react";

import { Heading } from "@source-web/heading";
import { Paragraph } from "@source-web/paragraph";

import { checkAtLeastOnce } from "../../CybSafe";
import {
  CybSafeTileCardContentWrapper,
  CybTileCard,
  EmailMessage,
  LastDays
} from "../../styles/CybSafe.styles";

export function CybSafeTileCard({
  title,
  value
}: {
  title?: string;
  value: number | string;
}) {
  return (
    <CybTileCard>
      <CybSafeTileCardContentWrapper>
        <LastDays>
          <Heading
            level={5}
            weight={3}
            text={title === "reported" ? title : `${title} risk`}
          />
        </LastDays>
        <Heading level={3} weight={3} text={value.toString()} noMargin={true} />
        <EmailMessage>
          <Paragraph size={1} weight={1}>
            {title && checkAtLeastOnce[title as keyof typeof checkAtLeastOnce]}
          </Paragraph>
        </EmailMessage>
      </CybSafeTileCardContentWrapper>
    </CybTileCard>
  );
}
