import styled, { css } from "styled-components";

import { Theme } from "@source-web/themes";

export const DesktopContainer = styled.div`
  ${(props) => css`
    @media only screen and (min-width: ${props.theme.breakpoints.md + 2}px) {
      display: block;
    }
    display: none;
  `}
`;

export const MobileSkipButtonContainer = styled.div`
  ${(props) => `
   display: flex;
   gap: ${props.theme.spacing.fixed[5]}px;
   align-items: center;
   @media only screen and (max-width: ${props.theme.breakpoints.md}px){
      flex-direction: row-reverse;
      padding: 15px 20px 0px 15px;
      width: 100%;
    };
`}
`;

export const MobileContainer = styled.div`
  &&& {
    ${(props) => css`
      @media only screen and (max-width: ${props.theme.breakpoints.md + 1}px) {
        display: block;
        padding: 1.25em 0.5em;
      }
      display: none;
    `}
  }
`;

export const SkipButton = styled.span`
  font-weight: 600;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
`;

export const BookWalkthroughHeading = styled.div`
  white-space: normal;
`;

export const BookWalkthroughContainer = styled.div``;

export const ParagraphContainer = styled.div`
  margin-top: ${(props) => props.theme.spacing.fixed[3]}px;
`;

export const TabItemWrapper = styled.div(
  (props) => css`
    margin: 2.25em auto;
    border-radius: 12px;
    width: 100%;
    border: 1px solid ${props.theme.color.monochrome2.default};
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.61);
    & > div {
      padding: 0;
      ${props.theme.name === "WS10"
        ? ` background: ${props.theme.color.monochrome2};`
        : ``}
    }
    @media only screen and (max-width: ${props.theme.breakpoints.md}px) {
      & > div {
        z-index: 0;
      }
    }
  `
);

export const TitleContainer = styled.div(
  () => css`
    margin: 10px 0;
  `
);

export const TabItemBody = styled.div(
  () => css`
    padding: 0px 10px 10px 10px;
  `
);

export const TabItemHeader = styled.div(
  () => css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px 10px 0px 10px;
  `
);

export const FileSectionContainer = styled.div`
  background: ${(props) => props.theme.color.monochrome2.default};
  padding: 1.25em;
  margin-top: -40px;
`;

export const ButtonSection = styled.div`
  max-width: ${({ theme }) => theme.gridConfig.springWidth}px;
  margin: 2em auto;
`;

export const TabSectionTitle = styled.div`
  padding: 1.2em;
  margin: 1.2em;
`;

export const TabSection = styled.div(
  (props: { theme: Theme }) => css`
    padding: 1.2em;
    margin: 1.2em;
    margin-bottom: 0.5em;
    background: ${props.theme.color.monochrome2.default};
    border-radius: 10px 10px 10px 10px;
  `
);

export const TabsWrapper = styled.div(
  (props: { theme: Theme }) => css`
    background: ${props.theme.color.monochrome1.default};
    border-radius: 0.2em;
    padding: 0.5em;
    & > div {
      & > div:first-of-type {
        border-bottom: 1px solid;
        justify-content: flex-start;

        & > div > button > div {
          padding: 16px 24px;
        }
      }
    }
    & > div {
      width: 100%;
      & > div:nth-child(1) {
        width: 100%;
        justify-content: flex-start;
      }
    }
    @media (min-width: ${props.theme.breakpoints.md}px) {
      max-width: ${props.theme.gridConfig.springWidth}px;
      margin: 1.25em auto;
    }
  `
);

export const TableWrapper = styled.div(
  ({ theme }: { theme: Theme }) => css`
    max-width: ${theme.gridConfig.springWidth}px;

    & table {
      border-collapse: separate;
      border-spacing: 0 10px;
      margin: 1.25em 0;
      & td:first-child,
      & th:first-child {
        border-radius: 10px 0 0 10px;
      }

      // Set border-radius on the top-right and bottom-right of the last table data on the table row
      & td:last-child,
      & th:last-child {
        border-radius: 0 10px 10px 0;
      }
      & tr {
        border-bottom: none;
        border-radius: 10px 10px 10px 10px;
      }
      & thead > tr > th:nth-child(1) {
        display: none;
      }
      & tbody > tr {
        background-color: ${theme.color.monochrome2.default};
        align-items: center;

        & > th {
          opacity: 0;
        }
        & > td {
          font-weight: 700;
        }
      }
    }
  `
);

export const StyledTableEntryWrapper = styled.div`
  display: flex;
  height: 3em;

  justify-content: start;
  align-items: center;
`;

export const ToggleButton = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

export const MobileLabel = styled.span(
  (props: { theme: any }) => `
  padding-right: 1em;
  @media (min-width: ${props.theme.breakpoints.md}px) {
    display: none;
  }
`
);

export const FlexDiv = styled.div`
  display: flex;
`;

export const NewPill = styled.div`
  border-radius: 15px 15px 15px 15px;
  background-color: #009900;
  padding: 3px;
  padding-left: 20px;
  width: 70px;
  margin-left: 20px;
`;

export const FlexColumn = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > a,
  span {
    cursor: pointer;
  }
`;

export const EmptyTableAlert = styled.div(
  ({ theme }: any) => css`
    width: 300px;
    justify-content: center;
    display: flex;
    padding: 2.5em;
    border: 1px dotted ${theme.color.monochrome4.default};
    background-color: ${theme.color.monochrome2.default};
    border-radius: 0.7em;
    font-size: 0.9em;
  `
);
