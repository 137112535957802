import React from "react";

import { Divider } from "@source-web/divider";
import { Heading } from "@source-web/heading";
import { Paragraph } from "@source-web/paragraph";

import { ThemedSection } from "../../../../../components";
import { VSDMData } from "../../../hooks/useVSDMData";
import { ContentfulVsdmDashboard } from "../../../types";
import { AnalyticsBreakdownTable } from "../components/AnalyticsBreakdownTable";
import { MultipleProgressBarTile } from "../components/MultipleProgressBarTile";
import PieGraph from "../components/PieGraph";
import { SingleStackedBarTile } from "../components/SingleStackedBarTile";
import {
  PieGraphsContainer,
  VSDMContainer,
  VSDMDivider,
  VSDMFirstSection,
  VSDMLastSection,
  VSDMSecondSection,
  VSDMSummaryLine,
  VSDMTotalDeploymentContainer,
  VSDMTotalDeploymentDivider,
  VSDMTotalDeploymentValue
} from "../styles/AnalyticsDashboard.styles";

export function VSDMDashboard({
  fields,
  data
}: {
  fields: ContentfulVsdmDashboard["contentfulVsdmDashboard"];
  data: VSDMData;
}) {
  if (!data?.devices || !data?.physical) {
    return null;
  }

  const { total, security, ownership, enrollment, platform } = data.devices;
  const { lastSeenBreakdown, devices, operationSystemBreakdown } =
    data.physical;

  return (
    <ThemedSection appearance="secondary">
      <VSDMContainer>
        <VSDMSummaryLine>
          <Heading size={3} weight={2} noMargin>
            {fields.dashboardTitle}
          </Heading>
          <VSDMTotalDeploymentContainer>
            <Paragraph weight={2} noMargin>
              {fields.totalDeploymentText}
            </Paragraph>
            <VSDMTotalDeploymentValue>
              <Paragraph weight={3} noMargin>
                {total}
              </Paragraph>
            </VSDMTotalDeploymentValue>
          </VSDMTotalDeploymentContainer>
        </VSDMSummaryLine>
        <VSDMTotalDeploymentDivider>
          <Divider appearance="secondary" />
        </VSDMTotalDeploymentDivider>
        <VSDMFirstSection>
          <div>
            <Heading weight={3} size={1}>
              {fields.totalSecurityTitle}
            </Heading>
            <PieGraphsContainer>
              <PieGraph
                score={Number(security.compromised)}
                total={Number(total)}
                color="#EB9700"
                text={fields.compromisedPieText}
              />
              <PieGraph
                score={Number(security.noPassCode)}
                total={Number(total)}
                color="#EB9700"
                text={fields.noPasscodePieText}
              />
              <PieGraph
                score={Number(security.notEncrypted)}
                total={Number(total)}
                color="#EB9700"
                text={fields.notEncryptedPieText}
              />
            </PieGraphsContainer>
          </div>
          <SingleStackedBarTile
            chartData={ownership}
            fields={fields.ownershipStatusTile}
          />
        </VSDMFirstSection>
        <VSDMDivider>
          <Divider appearance="secondary" />
        </VSDMDivider>
        <VSDMSecondSection>
          <div>
            <Heading weight={3} size={1}>
              {fields.lastSeenTitle}
            </Heading>
            <PieGraphsContainer>
              <PieGraph
                score={Number(devices.length)}
                total={Number(total)}
                color="#B7BF10"
                text={fields.lastSeenPieText}
              />
            </PieGraphsContainer>
          </div>
          <MultipleProgressBarTile
            chartData={lastSeenBreakdown}
            fields={fields.lastSeenTile}
          />
        </VSDMSecondSection>
        <VSDMDivider>
          <Divider appearance="secondary" />
        </VSDMDivider>
        <VSDMLastSection>
          <SingleStackedBarTile
            chartData={enrollment}
            fields={fields.enrollmentStatusTile}
          />
          <MultipleProgressBarTile
            chartData={platform.devicesByPlatform}
            fields={fields.platformStatusTile}
          />
          <AnalyticsBreakdownTable
            operatingSystem={operationSystemBreakdown}
            contentful={fields.operatingSystemBreakdown}
          />
        </VSDMLastSection>
      </VSDMContainer>
    </ThemedSection>
  );
}
