import React, { useState } from "react";

import { Paragraph } from "@source-web/paragraph";
import { OnChangeEvent } from "@source-web/select-input";
import { SelectInputWithLabel } from "@source-web/select-input-with-label";

import { Heading } from "@vfuk/core-heading";

import { OSBreakdown } from "../../../../hooks/useVSDMData";
import { BreakdownTable } from "../../../../types";
import {
  FilterWrapper,
  RowWrapper,
  TableWrapper
} from "./AnalyticsBreakdownTable.styles";

export const AnalyticsBreakdownTable = ({
  operatingSystem,
  contentful
}: {
  operatingSystem: OSBreakdown;
  contentful: BreakdownTable;
}) => {
  const [filter, setFilter] = useState<string>(contentful.dropdown[0].itemId);

  const mapTextValue = contentful.dropdown.map((value) => ({
    text: value.text,
    value: value.itemId
  }));

  const handleChangeFilter = (e: OnChangeEvent) => {
    setFilter(e.target.value);
  };

  const sortedArray = operatingSystem[filter].sort((a, b) => {
    const keyA = parseFloat(Object.keys(a)[0]);
    const keyB = parseFloat(Object.keys(b)[0]);
    return keyB - keyA;
  });

  return (
    <div>
      <Heading weight={3} size={1}>
        {contentful.heading}
      </Heading>
      <TableWrapper>
        <FilterWrapper>
          <SelectInputWithLabel
            fieldWrapper={{
              label: "",
              showLabel: false,
              width: "full"
            }}
            selectInput={{
              onChange: handleChangeFilter,
              name: "platformFilter",
              id: "platformFilter",
              options: mapTextValue,
              value: filter
            }}
          />
        </FilterWrapper>
        <RowWrapper underline={false}>
          <Paragraph weight={3} size={2} noMargin={true}>
            {contentful.keyName}
          </Paragraph>
          <Paragraph weight={3} size={2} noMargin={true}>
            {contentful.valueName}
          </Paragraph>
        </RowWrapper>
        {sortedArray.map((os, index) => (
          <RowWrapper
            key={`${filter}-${index}`}
            underline={index !== operatingSystem[filter].length - 1}
          >
            <Paragraph weight={1} size={2} noMargin={true}>
              {Object.entries(os)[0][0]}
            </Paragraph>
            <Paragraph weight={3} size={2} noMargin={true}>
              {Object.entries(os)[0][1]}
            </Paragraph>
          </RowWrapper>
        ))}
      </TableWrapper>
    </div>
  );
};
