import styled, { css } from "styled-components";

import { Theme } from "@vfuk/core-styles/dist/themes/themes.types";

interface GenericTitleWrapper {
  theme: Theme;
  align?: string;
  score?:
    | {
        line: number;
        color?: string;
      }
    | undefined;
}

export const SecurityScoreCardConfigurationContainer = styled.div(
  ({ theme, score }: GenericTitleWrapper) => css`
    margin-bottom: 1.25em;
    padding: 0 30px 6px;
    max-width: 500px;
    @media (min-width: ${theme.breakpoints.sm}px) and (max-width: ${theme
        .breakpoints.md}px) {
      padding: 0 10px 0;
    }
    table {
      border: 1px solid ${theme.color.primary3.default};
      margin-top: 40px;

      th {
        background-color: ${theme.name === "WS10"
          ? theme.color.monochrome3.default
          : theme.color.primary4.default};
      }

      th,
      td {
        border-right: 1px solid ${theme.color.primary3.default};
        &:last-child {
          border: none;
        }
      }

      tbody {
        td {
          color: ${theme.name === "WS10" ? "black" : "white"};
        }

        ${score &&
        css`
          td {
            color: ${theme.inverseColor.monochrome3.default};
          }

          tr:nth-child(${score.line}) {
            td {
              color: ${theme.name === "WS10" ? "black" : "white"};
            }

            td:first-child {
              color: ${score.color};
              font-weight: 700;
            }
          }
        `}
      }

      tr {
        border-bottom: 1px solid ${theme.color.primary3.default};
        &:last-child {
          border: none;
        }
      }
    }
  `
);

export const SecurityScoreCardWrapper = styled.div(
  (props) => css`
    max-width: ${props.theme.gridConfig.springWidth}px;
    margin: auto;
    height: 100%;
    @media only screen and (min-width: ${props.theme.breakpoints.md}px) {
      padding: 3.75em 0em;
    }
    @media only screen and (max-width: ${props.theme.breakpoints.md}px) {
      padding: 1.75em 0em;
    }
    @media (max-width: ${props.theme.breakpoints.md}px) {
      div[data-component-name="Container"] {
        padding: 10px;
      }
    }
  `
);

export const PartnerContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  & > * {
    margin-inline: 4px;
  }
`;

export const PartnerHeader = styled.div`
  display: flex;
  svg {
    margin-inline: 20px;
  }
  h1 {
    margin-inline: 4px;
  }
`;

export const PartnerBody = styled.div`
  display: flex;
  flex-direction: row;
  margin: 12px 0;
  font-size: 18px;
  font-weight: 400;
  & [data-component-name="Link"] {
    margin-inline: 4px;
  }
`;

export const ScoreContainer = styled.div(
  (props) => css`
    margin-bottom: ${props.theme.spacing.fixed[3]}px;
    @media only screen and (min-width: ${props.theme.breakpoints.md}px) {
      display: flex;
      justify-content: center;
      gap: ${props.theme.spacing.fixed[12]}px;
    }
    @media only screen and (max-width: ${props.theme.breakpoints.md}px) {
      & > div > div:first-child {
        margin: auto;
      }
    }
  `
);

export const SSCDivider = styled.div(
  (props) => css`
    border-bottom: 1px solid ${props.theme.color.primary3.default};
    margin: 0.5em 0 1.5em 0;
    display: none;

    @media only screen and (min-width: ${props.theme.breakpoints.md}px) {
      display: block;
    }
  `
);

export const SSCTitleWrapper = styled.div(
  (props) => css`
    display: flex;
    align-items: center;
    gap: ${props.theme.spacing.fixed[2]}px;
    justify-content: center;

    padding: 0 1em;
    // Adjusts the title alignment to match help icon's vertical
    & h3 {
      margin-bottom: ${props.theme.spacing.fixed[1]}px;
    }

    @media (min-width: ${props.theme.breakpoints.md}px) {
      display: flex;
      justify-content: left;
    }
  `
);

export const HeadingWrapper = styled.div(
  () => css`
    grid-column: 2 span;
    margin-bottom: 1.25em;
  `
);

export const RemoveOutline = styled.div(
  () => css`
    & > span:nth-child(1) {
      outline: none;
      box-shadow: none;
    }
  `
);

export const FactorsGrid = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 450px;
  gap: 0.725em;
`;
