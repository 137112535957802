import React from "react";

import { Paragraph } from "@source-web/paragraph";

import {
  NodeRenderer,
  documentToReactComponents
} from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";

const ContentfulParagraph: NodeRenderer = (node, next) => (
  <Paragraph>{next}</Paragraph>
);

const ContentfulLink: NodeRenderer = (node, next) => (
  <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
    {next}
  </a>
);
interface ContentfulRichTextProps {
  text: string;
}

export function ContentfulRichText({ text }: ContentfulRichTextProps) {
  return (
    <>
      {text &&
        documentToReactComponents(JSON.parse(text), {
          renderNode: {
            [BLOCKS.PARAGRAPH]: ContentfulParagraph,
            [INLINES.HYPERLINK]: ContentfulLink
          }
        })}
    </>
  );
}
