import { QRCodeCanvas } from "qrcode.react";
import React from "react";
import { useTheme } from "styled-components";

import { Heading } from "@source-web/heading";
import { Link } from "@source-web/link";
import { Paragraph } from "@source-web/paragraph";

import { StyledStepGridItemWrapper } from "../../../styles/ServicesPage.styles";
import { ContentfulLookoutDialog } from "../../../types";
import { StepProps } from "../LookoutDialog";

export function StepOne({
  heading,
  downloadLink,
  shareLink,
  stepHeading,
  onMobileParagraph,
  openInvitationModal
}: StepProps & {
  onMobileParagraph: ContentfulLookoutDialog["contentfulLookoutDialog"]["onMobileParagraph"]["text"];
  downloadLink: ContentfulLookoutDialog["contentfulLookoutDialog"]["downloadLink"];
  shareLink: ContentfulLookoutDialog["contentfulLookoutDialog"]["shareToMultipleUsersLink"];
  openInvitationModal?: () => void;
}) {
  const lookoutUrl = "https://mtp.lookout.com/download-app.html";
  const theme = useTheme();

  return (
    <StyledStepGridItemWrapper>
      <Heading text={stepHeading} weight={3} level={4} />
      <Paragraph>
        {heading}&nbsp;
        <Link
          text={shareLink?.text}
          href="#"
          inverse={theme.name !== "WS10"}
          onClick={openInvitationModal}
          showUnderline={true}
        />
      </Paragraph>
      <div>
        <QRCodeCanvas
          id="qrCode"
          value={lookoutUrl}
          size={100}
          bgColor={"#ffff"}
          level={"H"}
        />

        <Paragraph>
          {onMobileParagraph.text}&nbsp;
          <Link
            text={downloadLink?.text}
            href={lookoutUrl}
            inverse={theme.name !== "WS10"}
            showUnderline={true}
            htmlAttributes={{ target: downloadLink?.target }}
          />
        </Paragraph>
      </div>
    </StyledStepGridItemWrapper>
  );
}
