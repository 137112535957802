import styled, { css } from "styled-components";

export const ResponsiveRadarChartCardWrapper = styled.div(
  (props) => css`
    position: relative;
    height: 110vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: ${props.theme.breakpoints.md}px) {
      flex-direction: row;
      height: 60vh;
      padding: 0 16px;
    }

    /* Viewport media only Samsung Galaxy S8+ */
    @media (max-width: 360px) and (max-height: 740px) {
      height: 115vh;
    }
    /* Viewport media only Iphone SE */
    @media (max-width: 600px) and (max-height: 667px) {
      height: 125vh;
    }
    /* Viewport media only Iphone SE */
    @media (max-width: 600px) and (max-height: 600px) {
      height: 155vh;
    }
    /* Viewport media only Iphone 5 */
    @media (max-width: 320px) and (max-height: 667px) {
      height: 148vh;
    }
    /* Viewport media only Galazy z fold */
    @media (min-width: 280px) and (max-width: 280px) and (max-height: 653px) {
      height: 125vh;
    }
    /* Viewport media only Surface Duo */
    @media (min-width: 540px) and (max-width: 540px) and (max-height: 720px) {
      height: 120vh;
    }
    /* Viewport media only Iphone 6/7/8 */
    @media (min-width: 414px) and (max-width: 414px) and (max-height: 736px) {
      height: 115vh;
    }
    /* Viewport media only Iphone X */
    @media (max-width: 600px) and (min-height: 810px) {
      height: 103vh;
    }
    /* Viewport media only Iphone 12 */
    @media (max-width: 600px) and (min-height: 840px) {
      height: 100vh;
    }
    /* Viewport media only Iphone XR */
    @media (max-width: 600px) and (min-height: 890px) {
      height: 95vh;
    }
    /* Viewport media only Iphone SE */
    @media (max-width: 600px) and (min-height: 900px) {
      height: 100vh;
    }
    /* Viewport media only Samsung 20 Ultra and A51/71 */
    @media (max-width: 600px) and (min-height: 914px) {
      height: 93vh;
    }
  `
);

export const SelectCardWrapper = styled.div(
  (props) => css`
    padding: 16px;

    @media (min-width: ${props.theme.breakpoints.md}px) {
      max-width: 400px;
    }
  `
);
export const CardGenericWrapper = styled.div(
  (props) => css`
    position: absolute;
    height: 100%;
    width: 100%;
    @media (min-width: ${props.theme.breakpoints.md}px) and (max-width: 819px) {
    }

    @media (min-width: 820px) and (max-width: ${props.theme.breakpoints.lg}px) {
    }

    @media (min-width: ${props.theme.breakpoints.md}px) {
      width: 40%;
      position: relative;
    }
  `
);

export const CardGeneric = styled.div(
  (props) => css`
    margin-bottom: 32px;
    border-radius: 6px;
    width: 100%;
    padding: 0 16px;
    @media (min-width: ${props.theme.breakpoints.md}px) {
      max-width: 400px;
      padding: 0;
    }

    position: absolute;

    div:first-of-type {
      border-radius: inherit;
    }

    p[data-component-name="Paragraph"] {
      margin-top: 24px;
      padding-right: 12px;
    }

    &:last-of-type {
      p[data-component-name="Paragraph"] {
        margin-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid
          ${props.theme.name === "WS10" ? "black" : "white"};
      }
    }

    &:first-of-type {
      top: 0;
    }
    &:last-of-type {
      bottom: 0;
    }

    @media (min-width: ${props.theme.breakpoints.md}px) {
      position: relative;
    }

    @media (min-width: ${props.theme.breakpoints.md}px) and (min-width: ${props
        .theme.breakpoints.md}px) {
      width: 328px;
    }

    @media (max-width: ${props.theme.breakpoints.md}px) {
      width: 100%;
    }

    @media (min-width: ${props.theme.breakpoints.lg}px) {
      width: 100%;
    }

    @media (min-width: ${props.theme.breakpoints.sm}px) and (max-width: ${props
        .theme.breakpoints.md}px) {
      p[data-component-name="Paragraph"] {
        padding: 0;
      }
    }
  `
);

export const ProgressBarWrapper = styled.div(
  (props) => css`
    padding: 0;
    margin: 16px 0;

    div[data-component-name="HorizontalBarChart"] span {
      color: ${props.theme.name === "WS10" ? "black" : "white"};
      font-weight: 700;
    }
  `
);

export const ChartWrapper = styled.div(
  (props) => css`
    max-width: 100%;
    height: 100%;
    width: 100%;
    @media (min-width: 319px) and (max-height: 568px) {
      position: relative;
      width: 100%;
      margin: 80px auto;
      & > div {
        height: 50%;
        width: 100%;
      }
    }

    @media (min-width: 375px) and (max-height: 812px) {
      position: relative;
      width: 100%;
      margin: 125px auto;
      & > div {
        height: 100vh;
        width: 100%;
      }
    }

    @media (min-width: 360px) and (max-height: 780px) {
      position: relative;
      width: 100%;
      margin: 125px auto;
      & > div {
        height: 100vh;
        width: 100%;
      }
    }

    @media (min-width: 390px) and (max-height: 844px) {
      position: relative;
      width: 100%;
      margin: 115px auto;
      & > div {
        height: 100vh;
        width: 100%;
      }
    }

    @media (min-width: 412px) and (max-height: 915px) {
      position: relative;
      width: 100%;
      margin: 125px auto;
      & > div {
        height: 50vh;
        width: 100%;
      }
    }

    @media (min-width: 430px) and (max-height: 932px) {
      position: relative;
      width: 100%;
      margin: 125px auto;
      & > div {
        height: 50vh;
        width: 100%;
      }
    }

    @media (min-width: ${props.theme.breakpoints.md}px) and (max-width: 820px) {
      width: 50vw;
      height: 50vh;
      padding: 0;
    }

    @media (min-width: 820px) and (max-width: ${props.theme.breakpoints.lg}px) {
      display: grid;
      place-items: center;
    }

    @media (min-width: ${props.theme.breakpoints.lg}px) {
      margin: 0 auto;
      padding: 0;
    }
  `
);
