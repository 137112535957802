import React, { Fragment, useState } from "react";

import { Checkbox } from "@source-web/checkbox";
import { Container } from "@source-web/container";
import { Heading } from "@source-web/heading";
import { SimpleGrid } from "@source-web/simple-grid";
import { IconPopOut as IconPopOutDark } from "@source-web/source-mid-render-dark-icons";
import { IconPopOut as IconPopOutLight } from "@source-web/source-mid-render-light-icons";

import { ContentfulRichText } from "../,,/../../../../../../../../components/ContentfulRichText";
import { Loader } from "../../../../../../../../components";
import PartnerModal from "../../../../../../../../components/PartnerModal";
import { PrimaryButton } from "../../../../../../../../components/PrimaryButton";
import {
  isJourneyPopUp,
  isServer,
  journeyHandleClick
} from "../../../../../../../../lib";
import { useCheckBoxForModal } from "../../../../../../hooks/useCheckBoxForModal";
import { ScoreAndFactorsProps } from "../../../../../../types";
import BreakdownSection from "../../../BreakdownSection";
import { factorMapper } from "../../factorMapper";
import {
  FactorsGrid,
  HeadingWrapper,
  PartnerBody,
  PartnerContent,
  PartnerFooter,
  PartnerHeader,
  PartnerModalContent
} from "./styles/FactorLayer.styles";

const FactorsLayer = ({
  securityScorePageData,
  scoreData,
  securityScoreCardRatings,
  selectedFactor,
  setSelectedFactor,
  factorInContentful,
  platformUrl,
  isDark,
  isLoading,
  partnermodalData,
  securityScoreCardConfigurationData,
  formattedLocale
}: ScoreAndFactorsProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useCheckBoxForModal(
    "productCheckBoxState",
    false
  );

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
  };

  const productName = securityScorePageData.vendorName;

  function formatContent(template: string, replacement: string) {
    return template.replace(/\{productName\}/g, replacement);
  }

  const formattedPopUpHeading = formatContent(
    partnermodalData.journeyPopUpHeading,
    productName
  );
  const formattedPopUpBody = formatContent(
    partnermodalData.journeyPopUpBody.raw,
    productName
  );
  const formattedPopUpContact = formatContent(
    partnermodalData.journeyPopUpContactInfo.raw,
    productName
  );

  return (
    <Container paddingLevel={4}>
      <HeadingWrapper>
        <Heading level={5} weight={3}>
          {securityScorePageData?.factorsTitle}
        </Heading>
      </HeadingWrapper>
      {isLoading ? (
        <Loader
          text={{
            text: ""
          }}
        />
      ) : (
        <SimpleGrid
          spacing={6}
          layout={{
            sm: [12],
            lg: [6, 6]
          }}
        >
          <FactorsGrid>
            {scoreData?.companyFactors?.length &&
              factorMapper(
                scoreData?.companyFactors,
                setSelectedFactor,
                factorInContentful,
                securityScoreCardConfigurationData
              )}
          </FactorsGrid>
          <Fragment>
            {selectedFactor ? (
              <>
                <BreakdownSection
                  securityScoreCardConfigurationData={
                    securityScoreCardConfigurationData
                  }
                  securityScoreCardRatings={securityScoreCardRatings}
                  key={selectedFactor?.name}
                  factor={{ ...selectedFactor }}
                  score={selectedFactor?.score}
                >
                  <PrimaryButton
                    appearance="alt1"
                    inverse={isDark}
                    htmlAttributes={{
                      target: "_blank"
                    }}
                    text={securityScorePageData.factorsButtonText}
                    href={platformUrl}
                    onClick={(e) => {
                      journeyHandleClick(
                        e,
                        formattedLocale,
                        isChecked,
                        handleOpenModal,
                        handleCloseModal,
                        platformUrl
                      );
                    }}
                  />
                </BreakdownSection>
                <PartnerModal
                  isOpen={isModalOpen}
                  isCloseable={true}
                  onCloseCb={handleCloseModal}
                >
                  <PartnerModalContent>
                    <PartnerHeader>
                      {isDark ? (
                        <IconPopOutDark size={2} />
                      ) : (
                        <IconPopOutLight size={2} />
                      )}
                      <Heading
                        text={formattedPopUpHeading}
                        size={2}
                        justify="center"
                        level={1}
                      />
                    </PartnerHeader>
                    {formattedLocale !== "it" ? (
                      <>
                        <PartnerContent>
                          <PartnerBody>
                            <ContentfulRichText text={formattedPopUpBody} />
                          </PartnerBody>
                          <PartnerBody>
                            <ContentfulRichText text={formattedPopUpContact} />
                          </PartnerBody>
                          <Checkbox
                            value="donotseepopop"
                            id="donotseepopop"
                            name="donotseepopop"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                          >
                            {partnermodalData.journeyPopUpCheckbox}
                          </Checkbox>
                        </PartnerContent>
                        <PartnerFooter>
                          <PrimaryButton
                            text={partnermodalData.journeyPopUpCTA || ""}
                            appearance={"primary"}
                            inverse={isDark}
                            onClick={(e) => {
                              isJourneyPopUp(e, isServer, platformUrl);
                              handleCloseModal();
                            }}
                          />
                        </PartnerFooter>
                      </>
                    ) : null}
                  </PartnerModalContent>
                </PartnerModal>
              </>
            ) : null}
          </Fragment>
        </SimpleGrid>
      )}
    </Container>
  );
};

export default FactorsLayer;
