//@ts-nocheck
import React, { memo, useCallback, useEffect, useState } from "react";

import { HorizontalBarChart } from "@source-web/horizontal-bar-chart";
import { useBreakpoints } from "@source-web/match-media";
import { Paragraph } from "@source-web/paragraph";
import { SelectInputWithLabel } from "@source-web/select-input-with-label";

import { ThemedSection } from "../../../../../../components";
import { AdviseNotification } from "../../../../../../components/AdviseNotification";
import { ResponsiveRadarChart } from "../../../../../../components/ResponsiveRadarChart";
import { WithLightMode } from "../../../../../../components/WithLightMode";
import {
  ISecurityScorecardRootObject,
  trimLowerCase
} from "../../../../../../lib/";
import { BoardSummaryWrapper } from "../../../../../dashboard/components/SecurityScoreWidget/styles/SecurityScoreWidget.styles";
import {
  GetContentfulScoreRatings,
  GetContentfulSecurityRating
} from "../../../../types";
import { ConnectedIndustryBenchmarkProps } from "./ConnectedIndustryBenchmark";
import {
  CardGeneric,
  CardGenericWrapper,
  ChartWrapper,
  ProgressBarWrapper,
  ResponsiveRadarChartCardWrapper,
  SelectCardWrapper
} from "./styles/IndustryBenchmark.styles";

export type IvalueRating = {
  text: string;
  value: string;
  isCapital: boolean | undefined;
};

export function IndustryBenchmark({
  data,
  isFetched,
  isError,
  securityScoreCardRatings,
  securityRatings,
  industryBenchmarkData,
  industry,
  setIndustry
}: {
  isFetched: boolean;
  isError: boolean;
  data?: ISecurityScorecardRootObject[];
  industryBenchmarkData: ConnectedIndustryBenchmarkProps["industryBenchmarkData"];
  securityScoreCardRatings: GetContentfulScoreRatings["allContentfulSecurityScorecardRatings"];
  securityRatings: GetContentfulSecurityRating["allContentfulSecurityRating"];
  industry: string | null;
  setIndustry: React.Dispatch<React.SetStateAction<string | null>>;
}) {
  const valuesRatings = securityScoreCardRatings.edges
    .map((item) => ({
      text: item.node.heading,
      value: trimLowerCase(item.node.ratingId),
      isCapital: item.node.isCapital,
      ratingId: item.node.ratingId
    }))
    .sort((a, b) => a.text.localeCompare(b.text));

  const [valueRating, setValueRating] = useState(
    trimLowerCase(valuesRatings[0]?.value)
  );

  const dataChart =
    data &&
    valuesRatings.map((item) => {
      const dataItem = data.find(
        (element) => trimLowerCase(element.section) === item.value
      );

      if (dataItem) {
        return { ...item, ...dataItem };
      }

      return null;
    });

  const valuesSecurityRatings = securityRatings.nodes
    .map((item) => ({
      text: item.name,
      value: trimLowerCase(item?.industryId)
    }))
    .sort((a, b) => a.text.localeCompare(b.text));

  const [compareValues, setCompareValues] = useState<
    ISecurityScorecardRootObject | undefined
  >(data && data[0]);

  const checkActiveCompare = useCallback(() => {
    if (data) {
      const foundObject = data?.find(
        (item) =>
          item.section &&
          trimLowerCase(item.section) === trimLowerCase(valueRating)
      );
      setCompareValues(foundObject ?? undefined);
    }
  }, [data, valueRating]);

  type ICompareValuesObject = {
    compareValues?: ISecurityScorecardRootObject;
  };

  const YourCompanyBar = memo<ICompareValuesObject>(
    ({ compareValues }: ICompareValuesObject) => {
      return (
        <HorizontalBarChart
          showLabels={false}
          inverse
          steps={[
            {
              key: industryBenchmarkData.companyLabel,
              value: "score",
              percentage:
                (compareValues &&
                  parseInt(
                    compareValues[industryBenchmarkData.companyLabel]
                  )) ||
                0
            }
          ]}
          srText={industryBenchmarkData.yourCompanyText}
          topText={{
            left: `${industryBenchmarkData.yourCompanyText}: ${
              (compareValues &&
                parseInt(compareValues[industryBenchmarkData.companyLabel])) ||
              0
            }`
          }}
        />
      );
    }
  );

  YourCompanyBar.displayName = "YourCompanyBar";

  const IndustryBar = memo<ICompareValuesObject>(
    ({ compareValues }: ICompareValuesObject) => {
      return (
        <HorizontalBarChart
          showLabels={false}
          inverse
          steps={[
            {
              key: industryBenchmarkData.industryLabel,
              value: "score",
              state: "warn",
              percentage:
                (compareValues &&
                  parseInt(
                    compareValues[industryBenchmarkData.industryLabel]
                  )) ||
                0
            }
          ]}
          srText={industryBenchmarkData.industryStandardText}
          topText={{
            left: `${industryBenchmarkData.industryStandardText}: ${
              (compareValues &&
                parseInt(compareValues[industryBenchmarkData.industryLabel])) ||
              0
            }`
          }}
        />
      );
    }
  );

  IndustryBar.displayName = "IndustryBar";

  const [isCapital, setIsCapital] = useState<IvalueRating>(valuesRatings[0]);
  const [highlight, setHighlight] = useState<IvalueRating>(valuesRatings[0]);

  const checkIsCapitalAndHighlight = (value: string) => {
    const foundObject = valuesRatings?.find(
      (item) =>
        item?.value && trimLowerCase(item?.value) === trimLowerCase(value)
    );
    foundObject && setIsCapital(foundObject);
    setHighlight(foundObject);
  };

  const getInitialRatinsAndComparisons = useCallback(() => {
    setValueRating(trimLowerCase(valueRating));
    checkActiveCompare();
  }, [checkActiveCompare, valueRating]);

  useEffect(() => {
    getInitialRatinsAndComparisons();
  }, [getInitialRatinsAndComparisons]);

  const { mdAndBelow, smAndBellow } = useBreakpoints();

  // TODO: It is better to pass the Margin,
  // Grid Values through Props and handle it
  // via Styled Components rather than useBreakpoints
  // Controls the Height of the Chart

  const chartHeight = mdAndBelow ? 490 : 600;

  // Controls the Margin for the Chart
  // TODO: Better to handle it in StyledComponents,
  // as we already have a overriding styles for
  // multiple devices of diffrent height and WidgetHeader

  const chartTopMargin = smAndBellow ? 80 : 75;
  const chartRightMargin = smAndBellow ? 40 : 60;
  const chartLeftMargin = smAndBellow ? 40 : 60;
  const chartBottomMargin = smAndBellow ? 80 : 100;

  // Controls Grid Label Distance from the Circumfrence of the Graph
  // TODO It is better to handle it using
  // StyledComponents rather useBreakpoints, as
  // Grid Lables are not needed for Tablets and Mobiles

  const gridLabelOffset = smAndBellow ? 30 : 55;

  const chartArgs = {
    isInteractive: true,
    height: chartHeight,
    dotBorderWidth: 3,
    gridLabelOffset,
    indexBy: "text",
    keys: [
      industryBenchmarkData.companyLabel,
      industryBenchmarkData.industryLabel
    ],
    valueFormat: ">-.2f",
    gridLevels: 4,
    borderColor: { from: "color" },
    colors: ["#0096AD", "#EB9700"],
    colorBy: "index",
    curve: "linearClosed",
    fillOpacity: 0,
    borderWidth: 4,
    blendMode: "normal",
    margin: {
      top: chartTopMargin,
      right: chartRightMargin,
      bottom: chartBottomMargin,
      left: chartLeftMargin
    },
    dotColor: { from: "color", modifiers: [] },
    legends: [
      {
        itemOpacity: 0,
        direction: "row",
        translateX: -70,
        translateY: -40,
        itemWidth: 70,
        itemHeight: 60,
        itemsSpacing: 30,
        symbolSize: 15,
        symbolShape: "square"
      }
    ]
  };

  return (
    <BoardSummaryWrapper>
      {isError || !data ? (
        <AdviseNotification
          state="error"
          title={industryBenchmarkData.errorTitle}
          text={industryBenchmarkData.errorText}
        />
      ) : null}

      {isFetched && data ? (
        <ResponsiveRadarChartCardWrapper>
          <CardGenericWrapper>
            <CardGeneric>
              <ThemedSection appearance="primary">
                <SelectCardWrapper>
                  <SelectInputWithLabel
                    data-testid={"securityRatingsSelect"}
                    fieldWrapper={{
                      label: ""
                    }}
                    selectInput={{
                      name: "securityRatingsSelect",
                      id: "select",
                      value: industry?.toLowerCase(),
                      onChange: (event) => {
                        setIndustry(event.target.value);
                      },
                      options: valuesSecurityRatings,
                      placeholder: {
                        text: ""
                      }
                    }}
                  />
                  <Paragraph size={1} justify="left">
                    {industryBenchmarkData.industryDescription.text.text}
                  </Paragraph>
                </SelectCardWrapper>
              </ThemedSection>
            </CardGeneric>
            <CardGeneric>
              <ThemedSection appearance="primary">
                <SelectCardWrapper>
                  <SelectInputWithLabel
                    data-testid={"ratingsSelect"}
                    fieldWrapper={{
                      label: ""
                    }}
                    selectInput={{
                      name: "ratingsSelect",
                      id: "select",
                      value: valueRating,
                      onChange: (event) => {
                        checkIsCapitalAndHighlight(event.target.value);
                        setValueRating(trimLowerCase(event.target.value));
                        checkActiveCompare();
                      },
                      options: valuesRatings,
                      placeholder: {
                        text: ""
                      }
                    }}
                  />
                  <Paragraph size={1} justify="left">
                    {industryBenchmarkData.factorsDescription.text.text}
                  </Paragraph>
                  <ProgressBarWrapper>
                    <WithLightMode>
                      <YourCompanyBar compareValues={compareValues} />
                    </WithLightMode>
                  </ProgressBarWrapper>

                  <ProgressBarWrapper>
                    <WithLightMode>
                      <IndustryBar compareValues={compareValues} />
                    </WithLightMode>
                  </ProgressBarWrapper>
                </SelectCardWrapper>
              </ThemedSection>
            </CardGeneric>
          </CardGenericWrapper>
          <ChartWrapper>
            <ResponsiveRadarChart
              data={dataChart}
              chartArgs={chartArgs}
              highlight={highlight.text}
              rating={isCapital}
            />
          </ChartWrapper>
        </ResponsiveRadarChartCardWrapper>
      ) : null}
    </BoardSummaryWrapper>
  );
}
