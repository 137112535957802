import React from "react";

import { Heading } from "@source-web/heading";
import { Paragraph } from "@source-web/paragraph";

import {
  ICompanyFactor,
  getGradeBasedOnScore,
  getProgressBasedOnGrade,
  getTileColorBasedOnScore
} from "../../../../../../lib";
import {
  ContentfulScoreConfiguration,
  GetContentfulScoreRatings
} from "../../../../types";
import * as Styles from "./BreakdownSection.styles";

type BreakdownSectionProps = {
  factor?: ICompanyFactor;
} & {
  children: React.ReactNode;
  score?: string;
  securityScoreCardRatings: GetContentfulScoreRatings["allContentfulSecurityScorecardRatings"];
  securityScoreCardConfigurationData: ContentfulScoreConfiguration;
};

const BreakdownSection: React.FC<BreakdownSectionProps> = ({
  factor,
  score,
  children,
  securityScoreCardRatings,
  securityScoreCardConfigurationData
}: BreakdownSectionProps) => {
  const factorInContentful = securityScoreCardRatings?.edges?.find(
    ({ node }) => {
      const lowerCasedFactorNames = [
        node.ratingId.replace(" ", "").toLocaleLowerCase(),
        factor?.name?.toLocaleLowerCase()
      ];

      return lowerCasedFactorNames[0] === lowerCasedFactorNames[1];
    }
  )?.node;

  if (!factorInContentful?.heading || !score) return null;

  const parsedScore = parseInt(score);
  const grade = getGradeBasedOnScore(parsedScore);

  const severity = securityScoreCardConfigurationData?.edges.find(
    ({ node }) => node.rating === grade
  )?.node.text;

  return (
    <div>
      <Styles.BreakdownTitle>
        <Heading size={1} weight={3}>
          {factorInContentful.heading}
        </Heading>
        <Styles.ComponentContainer
          progress={getProgressBasedOnGrade(grade)}
          color={getTileColorBasedOnScore(parsedScore)}
        >
          <span />
        </Styles.ComponentContainer>
        <Styles.IssueContainer>
          <Paragraph size={2} weight={3}>
            {severity}
          </Paragraph>
        </Styles.IssueContainer>
        <Paragraph size={2}>
          {factorInContentful.description.internal.content}
        </Paragraph>
        <Styles.FactorsButtonContainer>
          {children}
        </Styles.FactorsButtonContainer>
      </Styles.BreakdownTitle>
    </div>
  );
};

export default BreakdownSection;
