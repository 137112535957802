import React, { ChangeEvent, useCallback, useState } from "react";

import { Heading } from "@source-web/heading";
import { Modal } from "@source-web/modal";
import { Paragraph } from "@source-web/paragraph";

import MessagePopup from "../../../../../components/MessagePopup";
import { isServer, parseCsv, useFetchWithTracking } from "../../../../../lib";
import {
  InvitationModalDescription,
  InvitationModalWrapper
} from "../../../styles/ServicesPage.styles";
import { InvitationModalFields } from "../../../types";
import { InvitationModalForm } from "./InvitationModalForm";

export interface InvitationModalProps {
  isOpen: boolean;
  fields: InvitationModalFields;
  enrollmentCode: string;
  emailData: {
    subject: string;
    template: {
      template: string;
    };
  };
  messagePopup: {
    messageBodyModalOne: string;
    messageIconModalOne: string;
    messageTypeModalOne: boolean;
    successfullyButton: [
      {
        buttonText: string;
        appearance: string;
        href: string;
        openInNewTab: boolean;
      }
    ];
    messageBodyModalTwo: string;
    messageIconModalTwo: string;
    messageTypeModalTwo: boolean;
    errorButton: [
      {
        buttonText: string;
        appearance: string;
        href: string;
        openInNewTab: boolean;
      }
    ];
  };
  isSuccessPopupOpen: boolean;
  setIsSuccessPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isErrorPopupOpen: boolean;
  setIsErrorPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsInvitationDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
}

export type InvitationModalContentType = Omit<
  InvitationModalProps,
  "setIsInvitationDialogOpen"
> & {
  setIsInvitationDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export function InvitationModalContent({
  enrollmentCode,
  fields,
  emailData,
  messagePopup,
  isSuccessPopupOpen,
  setIsSuccessPopupOpen,
  isErrorPopupOpen,
  setIsErrorPopupOpen,
  setIsInvitationDialogOpen
}: InvitationModalContentType) {
  const [isLoading, setIsLoading] = useState(false);

  const parseCsvFile = useCallback((file: File, callBack?: any) => {
    return parseCsv(file, (results) => {
      const parsedDevices: (string | undefined)[] = results.data
        .map((result: any) => {
          const emailRegex = RegExp(/^\S+@\S+\.\S+$/);
          const entry = Object.values(result)[0] as string;
          if (!emailRegex.test(entry)) return;
          return entry;
        })
        ?.filter((emails) => emails !== "");

      if (callBack) return callBack(parsedDevices?.join(";"));
    });
  }, []);

  const onDrop = useCallback(
    (acceptedFiles: File, callBack: any) => {
      parseCsvFile(acceptedFiles, callBack);
    },
    [parseCsvFile]
  );

  const {
    description: {
      text: { text }
    },
    csvDescription,
    dialogHeading
  } = fields;

  const changeHandler = (
    { target }: ChangeEvent<HTMLInputElement>,
    callBack: any
  ) => {
    const file = target?.files || null;

    if (!file || file[0]?.type !== "text/csv") {
      alert("Please upload a CSV file");
      target.value = "";
      return;
    }

    onDrop(file[0], callBack);
  };

  const insertActivationCode = (contentEmail: string) => {
    return contentEmail.replace("{{ACTIVATION_CODE}}", enrollmentCode);
  };

  const { fetchCyberhubMiddlewareWithTracking } = useFetchWithTracking();

  const timeOut = 100;

  const contentEmail = emailData.template.template;
  async function sendTheEmail(emails: string) {
    setIsLoading(true);
    try {
      return await fetchCyberhubMiddlewareWithTracking(
        "emails/custom",
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            subject: emailData.subject,
            content: insertActivationCode(contentEmail),
            to: [emails]
          })
        },
        true
      ).then((response) => {
        const isError = response.error;
        if (isError) {
          console.log(response);
          setIsInvitationDialogOpen(false);
          setIsLoading(false);
          setTimeout(() => {
            setIsErrorPopupOpen(true);
          }, timeOut);
          return;
        }
        setIsInvitationDialogOpen(false);
        setIsLoading(false);
        setTimeout(() => {
          setIsSuccessPopupOpen(true);
        }, timeOut);
      });
    } catch (error) {
      setIsInvitationDialogOpen(false);
      setIsLoading(false);
      throw new Error("Error sending email");
    }
  }

  const onSubmit: (data: { emails: string }) => any = async (data: {
    emails: string;
  }) => {
    if (!isServer) {
      try {
        sendTheEmail(data.emails);
      } catch (error) {
        console.error(error);
        setIsInvitationDialogOpen(false);
        setIsLoading(false);
      }
    }
  };

  return (
    <InvitationModalWrapper>
      <Heading text={dialogHeading} level={3} />
      <InvitationModalDescription>
        <Paragraph size={2}>{text}</Paragraph>
        <small>{csvDescription}</small>
      </InvitationModalDescription>
      <InvitationModalForm
        onSubmit={onSubmit}
        fields={fields}
        emailData={emailData}
        messagePopup={messagePopup}
        isSuccessPopupOpen={isSuccessPopupOpen}
        setIsSuccessPopupOpen={setIsSuccessPopupOpen}
        isErrorPopupOpen={isErrorPopupOpen}
        setIsErrorPopupOpen={setIsErrorPopupOpen}
        enrollmentCode={enrollmentCode}
        isLoading={isLoading}
        changeHandler={changeHandler}
      />
    </InvitationModalWrapper>
  );
}

export function InvitationModal({
  isOpen,
  fields,
  onClose,
  emailData,
  messagePopup,
  enrollmentCode,
  setIsInvitationDialogOpen
}: InvitationModalProps) {
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);

  return (
    <>
      {isSuccessPopupOpen && !isErrorPopupOpen && (
        <MessagePopup
          message={messagePopup.messageBodyModalOne}
          button={messagePopup.successfullyButton}
          type={messagePopup.messageTypeModalOne}
          icon={messagePopup.messageIconModalOne}
          formattedLocale="en"
          openState={isSuccessPopupOpen}
          setOpenState={setIsSuccessPopupOpen}
          onClose={() => setIsSuccessPopupOpen(false)}
        />
      )}
      {!isSuccessPopupOpen && isErrorPopupOpen && (
        <MessagePopup
          message={messagePopup.messageBodyModalTwo}
          button={messagePopup.errorButton}
          type={messagePopup.messageTypeModalTwo}
          icon={messagePopup.messageIconModalTwo}
          formattedLocale="en"
          openState={isErrorPopupOpen}
          setOpenState={setIsErrorPopupOpen}
          onClose={() => setIsErrorPopupOpen(false)}
        />
      )}
      <Modal
        isClosable
        onCloseCb={onClose}
        size={5}
        srName="Invite employees to activate Lookout for Work"
        isOpen={isOpen}
      >
        {InvitationModalContent({
          isOpen,
          setIsInvitationDialogOpen,
          onClose,
          emailData,
          messagePopup,
          enrollmentCode,
          isSuccessPopupOpen,
          setIsSuccessPopupOpen,
          isErrorPopupOpen,
          setIsErrorPopupOpen,
          fields
        })}
      </Modal>
    </>
  );
}
