import styled, { css } from "styled-components";

export const ScoreContainer = styled.div(
  (props) => css`
    margin-bottom: ${props.theme.spacing.fixed[3]}px;
    @media only screen and (min-width: ${props.theme.breakpoints.md}px) {
      display: flex;
      justify-content: center;
    }

    @media only screen and (min-width: ${props.theme.breakpoints.lg}px) {
      gap: ${props.theme.spacing.fixed[12]}px;
    }
    @media (min-width: ${props.theme.breakpoints.sm}px) and (max-width: ${props
        .theme.breakpoints.md}px) {
      gap: ${props.theme.spacing.fixed[4]}px;
    }

    @media only screen and (max-width: ${props.theme.breakpoints.md}px) {
      & > div > div:first-child {
        margin: auto;
      }
    }
  `
);
