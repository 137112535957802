import styled, { css } from "styled-components";

export const BreakdownTitle = styled.div`
  margin: 0 2.25em;

  max-width: 80%;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    margin: 3.25em 2.25em;
  }

  & > p {
    font-size: 16px;
    min-height: 104px;
  }
`;

export const IssueContainer = styled.div`
  margin-bottom: 1.25em;
  display: flex;
  justify-content: space-between;
`;

export const FactorsButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 2.25em 0;
`;

export const ComponentContainer = styled.div<{
  color: string;
  progress: number;
}>(
  (props) => css`
    background-color: ${props.theme.color.monochrome3.default};
    margin-bottom: ${props.theme.spacing.fixed[3]}px;
    border-radius: 5px 4px;
    height: 4px;

    & span {
      display: flex;
      height: 4px;
      width: ${props.progress}%;
      background: ${props.color};
    }
  `
);
