import React from "react";

import { Table, TableCell, TableRow } from "@source-web/table";

import { Heading } from "@vfuk/core-heading";

import { getGradeBasedOnScore } from "../../../../../../lib";
import { ContentfulSecurityScorePage } from "../../../../types";
import { SecurityScoreCardConfigurationContainer } from "./../../styles/SecurityScoreCard.styles";

export const checkScoreLineColor = (
  score: number,
  data: ContentfulScoreConfiguration
) => {
  const color =
    data &&
    data.edges.find(
      (contentfulScoreConfigurationEntry: ScoreConfiguration) =>
        contentfulScoreConfigurationEntry.node.rating ===
        getGradeBasedOnScore(score)
    )?.node.hexCode;

  if (score >= 90) return { line: 1, color };
  if (score >= 80) return { line: 2, color };
  if (score >= 70) return { line: 3, color };
  if (score >= 60) return { line: 4, color };

  return { line: 5, color };
};

function sortByRating(arr: ScoreConfiguration[]): ScoreConfiguration[] {
  return arr.sort((a, b) => {
    const ratingA = a.node.rating;
    const ratingB = b.node.rating;
    return ratingA.localeCompare(ratingB);
  });
}

interface ScoreConfiguration {
  node: {
    rating: string;
    score: string;
    text: string;
    colour: string;
    hexCode: string;
  };
}

interface ContentfulScoreConfiguration {
  edges: ScoreConfiguration[];
}
export function SecurityScoreCardConfiguration({
  score,
  data,
  heading,
  tableHeading
}: {
  heading: ContentfulSecurityScorePage["scoreConfigurationHeading"];
  tableHeading: ContentfulSecurityScorePage["scoreConfigurationTableHeadings"];
  score?: undefined | number;
  data: ContentfulScoreConfiguration;
}) {
  const revesedHeadings = tableHeading.reverse();
  return (
    <SecurityScoreCardConfigurationContainer
      score={score ? checkScoreLineColor(score, data) : undefined}
    >
      <Heading size={1} weight={3} text={heading} />
      <Table headings={revesedHeadings} stackOnSm={false}>
        {sortByRating(data.edges).map(
          (row: ScoreConfiguration, index: number) => (
            <TableRow key={index} addRowSpacing={true}>
              <TableCell>{row.node.rating}</TableCell>
              <TableCell>{row.node.score}</TableCell>
              <TableCell>{row.node.text}</TableCell>
              <TableCell>{row.node.colour}</TableCell>
            </TableRow>
          )
        )}
      </Table>
    </SecurityScoreCardConfigurationContainer>
  );
}
