import React from "react";

import { ProgressBar } from "@source-web/progress-bar";

import { Heading } from "@vfuk/core-heading";

import { MultipleProgressBarTileFields } from "../../../../types";
import { ProgressBarWrapper } from "../../styles/AnalyticsDashboard.styles";

export function MultipleProgressBarTile({
  chartData,
  fields
}: {
  chartData: { [key: string]: string | number };
  fields: MultipleProgressBarTileFields;
}) {
  const totalNumber = Object.values(chartData).reduce(
    (acc, value) => Number(acc) + Number(value),
    0
  );

  return (
    <div>
      <Heading weight={3} size={1}>
        {fields.tileTitle}
      </Heading>

      {Object.keys(chartData).map((platformName) => {
        const numberOfDevices = chartData[platformName];
        const itemFields = fields.chartItems.find(
          (chartItem) => chartItem.itemId === platformName
        );

        return (
          <ProgressBarWrapper
            key={itemFields?.itemId}
            progressbarColor={itemFields?.itemColor || ""}
          >
            <ProgressBar
              value={(Number(numberOfDevices) * 100) / Number(totalNumber)}
              appearance="primary"
              topText={{
                left: `${itemFields?.text}`,
                right: `${fields.rightText} ${numberOfDevices}`
              }}
              srLabel="Progress bar"
            />
          </ProgressBarWrapper>
        );
      })}
    </div>
  );
}
