import React from "react";

import { Heading } from "@source-web/heading";

import { AnalyticsTitleWrapper } from "../../styles/AnalyticsDashboard.styles";

export interface IBannerHeadingProps {
  text: string;
}
export function BannerHeading({ text }: IBannerHeadingProps) {
  return (
    <AnalyticsTitleWrapper>
      <div>
        <Heading level={3} text={text} />
      </div>
    </AnalyticsTitleWrapper>
  );
}
