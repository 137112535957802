import React from "react";

import { Modal } from "@source-web/modal";
import { Spacing } from "@source-web/spacing";

import { PartnerModalProps } from "./PartnerModal.types";

const PartnerModal = ({
  isOpen,
  isCloseable,
  children,
  onCloseCb
}: PartnerModalProps) => {
  return (
    <Modal
      overlayBlur
      srName="Partner Modal"
      isOpen={isOpen}
      isClosable={isCloseable}
      animate
      size={5}
      onCloseCb={onCloseCb}
    >
      <Spacing {...{ spacingLevel: { bottom: 7 } }}>{children}</Spacing>
    </Modal>
  );
};

export default PartnerModal;
