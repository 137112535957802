import React from "react";

import { ContentfulAnalyticsDashboardTile } from "../../../../types";
import { AnalyticsDashboardCard } from "../AnalyticsDashboardCard";
import { AnalyticsDashboardTile } from "../AnalyticsDashboardTile/";

export interface IAnalyticsDashboardTable {
  name: string;
  value: {
    high: string;
    medium: string;
    low: string;
  };
  tileFields?: Array<ContentfulAnalyticsDashboardTile>;
}

export function AnalyticsDashboardTable(args: IAnalyticsDashboardTable) {
  return (
    <AnalyticsDashboardCard
      titleOptions={{
        level: 3
      }}
      alignTitle="left"
      name={args.name}
    >
      <>
        {args.tileFields?.map((tileField) => {
          if (!args.value) return;

          const valueForTile =
            args?.value[
              tileField?.tileId as keyof IAnalyticsDashboardTable["value"]
            ];

          return (
            <AnalyticsDashboardTile
              key={tileField.tileId}
              label={tileField.text}
              value={valueForTile}
              color={tileField.color}
            />
          );
        })}
      </>
    </AnalyticsDashboardCard>
  );
}
