import React from "react";

import { Heading } from "@source-web/heading";
import { useBreakpoints } from "@source-web/match-media";
import { Paragraph } from "@source-web/paragraph";
import { ProgressBar } from "@source-web/progress-bar";

import { ThemedSection } from "../../../../components";
import { ResponsiveRadarChart } from "../../../../components/ResponsiveRadarChart";
import {
  AwarenessOverview,
  AwarenessRates,
  BehaviorScoreData,
  Culture,
  PhishingStats
} from "../../hooks/useCybSafeData";
import { ContentfulDashboardTiles } from "../../types";
import { CultureMetrics } from "./components/CultureMetrics";
import { CybSafeTileCard } from "./components/CybSafeTile";
import { PhishingReviewCard } from "./components/PhishingReviewCard";
import { RefreshRateCard } from "./components/RefresherRate";
import SecurityScoreBehaviour from "./components/SecurityScoreBehaviour/SecurityScoreBehaviour";
import * as Styled from "./styles/CybSafe.styles";

export const checkAtLeastOnce = {
  low: "emails opened at least once",
  medium: "links clicked at least once",
  high: "data submitted at least once",
  reported: "emails reported at least once"
};

export interface MappedCultureData {
  culture: Culture[0]["dimensionName"];
  company: Culture[0]["responses"]["total"];
}

export interface ICybSafe {
  data: ContentfulDashboardTiles;
  score: BehaviorScoreData;
  awarenessOverview: AwarenessOverview;
  culture: Culture;
  awarenessRates: AwarenessRates;
  phishing: PhishingStats;
}

function renderLearning({
  activatedUsersTile,
  awarenessOverview,
  awarenessRates,
  data
}: {
  activatedUsersTile: string;
  awarenessOverview: AwarenessOverview;
  awarenessRates: AwarenessRates;
  data: any;
}) {
  const { awarenessOverview: awarenessOverviewData } = awarenessOverview;

  const handlePercentage = () =>
    (100 / awarenessOverviewData?.totalLicences) *
    awarenessOverviewData?.usersCompleted;

  return (
    <Styled.LearningContainer>
      <Styled.GenericGroupCardContent>
        <Styled.GenericTitleWrapper align="left">
          <Heading size={3} weight={2} text="Learning" />
        </Styled.GenericTitleWrapper>
        <Styled.GenericContainer>
          <Styled.GenericCard>
            <Heading size={1} weight={2} text={activatedUsersTile} />
            <Styled.ValuesContainer>
              <Heading
                size={3}
                weight={3}
                text={awarenessOverviewData?.usersCompleted.toString() ?? ""}
              />

              <div>
                <Heading size={1} weight={1} text="/" />
                <Heading
                  size={1}
                  weight={1}
                  text={awarenessOverviewData.totalLicences.toString() ?? ""}
                />
              </div>
            </Styled.ValuesContainer>
            <Styled.ProgressBarsContainer>
              <ProgressBar
                inverse
                appearance="secondary"
                value={handlePercentage()}
                srLabel="Progress bar"
              />
            </Styled.ProgressBarsContainer>
            <Styled.ActivatedUsersContainer>
              <Paragraph size={2} weight={1}>
                user accounts have been activated.
              </Paragraph>
            </Styled.ActivatedUsersContainer>
          </Styled.GenericCard>
          <Styled.RefresherContainer>
            {awarenessRates && (
              <RefreshRateCard
                awarenessRatesData={awarenessRates}
                contentfulData={data}
              />
            )}
          </Styled.RefresherContainer>
        </Styled.GenericContainer>
      </Styled.GenericGroupCardContent>
    </Styled.LearningContainer>
  );
}

function renderBehaviorScore(sscHeading: string, score: BehaviorScoreData) {
  return (
    <Styled.ScoreBehavior>
      <Styled.GenericGroupCardContent smallCard>
        <Styled.GenericTitleWrapper align="left">
          <Heading size={3} weight={2} text={sscHeading} />
        </Styled.GenericTitleWrapper>
        <Styled.GenericContainer>
          <div
            style={{
              width: "100%"
            }}
          >
            <SecurityScoreBehaviour score={score.score as number} />
          </div>
        </Styled.GenericContainer>
      </Styled.GenericGroupCardContent>
    </Styled.ScoreBehavior>
  );
}

function renderPhishing(phishing: PhishingStats) {
  return (
    <Styled.LeftContainer>
      {phishing && (
        <PhishingReviewCard
          title={"Phishing overview"}
          value={phishing?.emailsSent}
          // @Todo, commenting until response from BE Team
          // After speaking with the backend team,
          // it was agreed to add the value of the string parameter to the call on the front end.
          subTitle={`in the last 30 days`}
          emailMessage={"email sent"}
        />
      )}
      <Styled.RisksContainer>
        <Styled.CybSafeCard>
          <Styled.TilesContainer>
            <CybSafeTileCard title="low" value={phishing.lowRisk} />
          </Styled.TilesContainer>
        </Styled.CybSafeCard>
        <Styled.CybSafeCard>
          <Styled.TilesContainer>
            <CybSafeTileCard title="medium" value={phishing.mediumRisk} />
          </Styled.TilesContainer>
        </Styled.CybSafeCard>
        <Styled.CybSafeCard>
          <Styled.TilesContainer>
            <CybSafeTileCard title="high" value={phishing.highRisk} />
          </Styled.TilesContainer>
        </Styled.CybSafeCard>
        <Styled.CybSafeCard>
          <Styled.TilesContainer>
            <CybSafeTileCard
              title="reported"
              value={`< ${phishing.reported}`}
            />
          </Styled.TilesContainer>
        </Styled.CybSafeCard>
      </Styled.RisksContainer>
    </Styled.LeftContainer>
  );
}

function renderCultureGraph(culture: Culture, chartArgs: any) {
  function mapPayloadToGraphData(payload: Culture) {
    const mappedData: MappedCultureData[] = Object.keys(payload).map(
      (key: string) => {
        const dimension = payload[key];
        return {
          culture: dimension.dimensionName,
          company: dimension.responses.total
        };
      }
    );

    return mappedData;
  }

  const cultureMapped = mapPayloadToGraphData(culture);
  return (
    <Styled.RightContainer>
      <Styled.GenericTitleWrapper align="left">
        <Heading size={3} weight={2} text={"Culture"} />
      </Styled.GenericTitleWrapper>

      <Styled.CultureLabel>
        <Heading size={1}>Your Organisation Score</Heading>
      </Styled.CultureLabel>
      <Styled.CultureCard>
        <CultureMetrics data={cultureMapped} />
        <Styled.GraphCard>
          {culture && (
            <ResponsiveRadarChart data={cultureMapped} chartArgs={chartArgs} />
          )}
        </Styled.GraphCard>
      </Styled.CultureCard>
    </Styled.RightContainer>
  );
}

export function CybSafe({
  data,
  score,
  awarenessOverview,
  culture,
  awarenessRates,
  phishing
}: ICybSafe) {
  const { activatedUsersTile, sscHeading } = data;

  const { mdAndBelow } = useBreakpoints();

  const chartHeight = mdAndBelow ? 400 : 500;
  const chartTopMargin = mdAndBelow ? 25 : 70;
  const chartRightMargin = mdAndBelow ? 115 : 120;
  const chartLeftMargin = mdAndBelow ? 100 : 110;

  const chartArgs = {
    height: chartHeight,
    gridLevels: 4,
    gridLabelOffset: 25,
    enableDots: false,
    isInteractive: true,
    indexBy: "culture",
    borderWidth: 0.3,
    keys: ["company"],
    curve: "linearClosed",
    borderColor: "white",
    colors: { scheme: "category10" },
    blendMode: "normal",
    margin: {
      top: chartTopMargin,
      right: chartRightMargin,
      bottom: 70,
      left: chartLeftMargin
    },
    fillOpacity: 0.4,
    motionConfig: "wobbly"
  };

  return (
    <ThemedSection appearance="secondary">
      <Styled.CybSafeContainer>
        <Styled.CardContent>
          {renderLearning({
            activatedUsersTile,
            awarenessOverview,
            awarenessRates,
            data
          })}
          {renderBehaviorScore(sscHeading, score)}
        </Styled.CardContent>

        <Styled.GraphContainer>
          {renderPhishing(phishing)}
          {renderCultureGraph(culture, chartArgs)}
        </Styled.GraphContainer>
      </Styled.CybSafeContainer>
    </ThemedSection>
  );
}
