import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/it";
import "dayjs/locale/pt";
import React, { useEffect } from "react";

import { Heading, HeadingProps } from "@source-web/heading";

import {
  AnalyticsCardTile,
  AnalyticsColorBar,
  TextWithoutBoxWrapper,
  TimeStampWrapper,
  TitleAndIconWrapper
} from "../../styles/AnalyticsDashboard.styles";

export interface IAnalyticsDashboardTileProps {
  label: string;
  value?: string;
  color?: string;
  valueType?: string;
  titleOptions?: HeadingProps;
  valueOptions?: HeadingProps;
  isHorizontal?: boolean;
  isDark?: boolean;
  breakLineValue?: boolean;
  icon?: () => React.JSX.Element;
  children?: React.ReactElement;
  formattedLocale?: string;
}
export function AnalyticsDashboardTile({
  label,
  value,
  color,
  valueType,
  icon = undefined,
  breakLineValue,
  isDark = true,
  titleOptions = {
    level: 4,
    weight: 1,
    noMargin: true
  },
  valueOptions = {
    level: 4,
    weight: 1,
    noMargin: true
  },
  isHorizontal = false,
  children,
  formattedLocale
}: IAnalyticsDashboardTileProps) {
  const handleIcon = () => {
    if (!icon) return;
    return icon();
  };

  const formatLocale = (formattedLocale: string = "en") => {
    dayjs.locale(formattedLocale);
  };

  useEffect(() => {
    formatLocale(formattedLocale);
  }, [formattedLocale, value]);

  const formattedDate = dayjs(value, "DD MMMM YYYY").format("DD/MM/YY");

  const valueView = () => {
    let view = <Heading {...valueOptions}>{value}</Heading>;
    if (value === "None") {
      view = (
        <span className="isText">
          <Heading {...valueOptions}>{value}</Heading>
        </span>
      );
    }
    if (valueType === "text") {
      view = (
        <TextWithoutBoxWrapper>
          <Heading {...valueOptions}>
            {value?.indexOf("-") !== -1 ? (
              <TimeStampWrapper>
                <span>{value?.split("-")[0]}</span>
                <span>{formattedDate}</span>
              </TimeStampWrapper>
            ) : (
              value
            )}
          </Heading>
        </TextWithoutBoxWrapper>
      );
    }
    return view;
  };

  // adding a special use case.
  // in this return statement we're rending the heading
  // and the value in the same row. which can only be done
  // by changing the order from each element in the flexbox
  // `breakLineValue` should be used when the value is too big to remain
  // in only one line.
  if (breakLineValue) {
    return (
      <AnalyticsCardTile
        breakLineValue
        isHorizontal={isHorizontal}
        isDark={isDark}
        color={color}
        hasIcon={Boolean(icon)}
      >
        <TitleAndIconWrapper breakLineValue={breakLineValue}>
          {handleIcon()}
          <Heading {...titleOptions} noMargin text={label} />
        </TitleAndIconWrapper>
        {value ? valueView() : children}
      </AnalyticsCardTile>
    );
  }

  return (
    <AnalyticsCardTile
      breakLineValue={breakLineValue}
      isHorizontal={isHorizontal}
      isDark={isDark}
      color={color}
      hasIcon={Boolean(icon)}
    >
      <div className="wrapperCardTile">
        <TitleAndIconWrapper>
          {color && <AnalyticsColorBar color={color} />}
          {handleIcon()}
          <Heading {...titleOptions} text={label} />
        </TitleAndIconWrapper>
        {value ? valueView() : children}
      </div>
    </AnalyticsCardTile>
  );
}
