import { useEffect, useState } from "react";

export function useCheckBoxForModal(
  key: string,
  defaultValue: boolean
): [boolean, (value: boolean) => void] {
  const [state, setState] = useState<boolean>(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}
