import React from "react";
import { DefaultTheme, useTheme } from "styled-components";

import { Pie } from "@nivo/pie";

type CenteredMetricProps = {
  centerX: number;
  centerY: number;
  score: number;
  percentage: number;
  text: string;
  theme: DefaultTheme;
};

const CenteredMetric = ({
  centerX,
  centerY,
  score,
  percentage,
  text,
  theme
}: CenteredMetricProps) => {
  return (
    <>
      <text
        x={centerX - 10}
        y={centerY - 20}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: "40px",
          fontWeight: "600",
          fill: theme.name === "WS10" ? "#000" : "#fff"
        }}
      >
        {Math.round(percentage)}
      </text>
      <text
        x={centerX + 20}
        y={centerY - 10}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: "20px",
          fontWeight: "400",
          fill: theme.name === "WS10" ? "#000" : "#fff"
        }}
      >
        %
      </text>
      <text
        x={centerX}
        y={centerY + 10}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: "20px",
          fill: theme.name === "WS10" ? "#000" : "#fff"
        }}
      >
        {text}
      </text>
      <text
        x={centerX}
        y={centerY + 30}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: "18px",
          fontWeight: "700",
          fill: theme.name === "WS10" ? "#000" : "#fff"
        }}
      >
        {score}
      </text>
    </>
  );
};

const ResponsivePieGraph: React.FC<{
  score: number;
  color: string;
  total: number;
  text: string;
}> = ({ score, color, total, text }) => {
  const theme = useTheme();

  const percentage = (Number(score) * 100) / total;

  return (
    <Pie
      width={180}
      height={180}
      data={[
        {
          id: "score",
          label: { text },
          value: percentage,
          color: color
        },
        {
          id: "rest",
          label: "Rest",
          value: 100 - percentage,
          color: theme.name === "WS10" ? "#BEBEBE" : "#7E7E7E"
        }
      ]}
      margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
      innerRadius={0.8}
      padAngle={2}
      activeOuterRadiusOffset={8}
      colors={{ datum: "data.color" }}
      borderWidth={1}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      enableArcLinkLabels={false}
      enableArcLabels={false}
      layers={[
        "arcs",
        "arcLinkLabels",
        "legends",
        (props) => (
          <CenteredMetric
            {...props}
            percentage={percentage}
            score={score}
            theme={theme}
            text={text}
          />
        )
      ]}
    />
  );
};

export default ResponsivePieGraph;
