import styled, { css } from "styled-components";

export const MessagePopupContainer = styled.div(
  (props) => css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    & > span:first-of-type > span > span > svg {
      height: 324px;
      width: 324px;
    }
    @media (max-width: ${props.theme.breakpoints.md}px) {
      & > span:first-of-type > span > span > svg {
        height: 124px;
        width: 124px;
      }
    }
  `
);

export const MessagePageContainer = styled(MessagePopupContainer)`
  height: 80vh;
`;

export const Message = styled.div(
  (props) => css`
    font-size: 56px;
    width: 472px;
    text-align: center;
    line-height: 64px;
    margin: 12px 0px 36px;
    @media (max-width: ${props.theme.breakpoints.md}px) {
      width: 340px;
      font-size: 36px;
      line-height: 36px;
    }
  `
);

export const GoToButtons = styled.div(
  (props) => css`
    display: flex;
    gap: 32px;
    @media (max-width: ${props.theme.breakpoints.md}px) {
      display: flex;
      flex-direction: column;
      width: 300px;
    }
  `
);
