import React from "react";

import { LoadingSpinner } from "@source-web/loading-spinner";

import { ThemedSection } from "../../../../components";
import { AdviseNotification } from "../../../../components/AdviseNotification";
import { BannerHeading } from "./components/BannerHeading";
import {
  AnalyticsWrapper,
  StyledLoadingSpinnerWrapper
} from "./styles/AnalyticsDashboard.styles";

export interface IAnalyticsDashboard {
  isLoading: boolean;
  isError: boolean;
  selectedDashboard?: any;
  bannerHeading?: string;
  buttons?: React.JSX.Element;
  supportBanner?: React.JSX.Element;
}

export function AnalyticsDashboard({
  isLoading,
  isError,
  selectedDashboard,
  bannerHeading,
  buttons,
  supportBanner
}: IAnalyticsDashboard) {
  return (
    <ThemedSection appearance="secondary">
      {bannerHeading && <BannerHeading text={bannerHeading} />}
      {isLoading && !isError ? (
        <StyledLoadingSpinnerWrapper>
          <LoadingSpinner size={5} />
        </StyledLoadingSpinnerWrapper>
      ) : (
        <AnalyticsWrapper>
          {!isError && selectedDashboard}
          {isError && (
            <div>
              <AdviseNotification
                state="error"
                text=" We were unable to retrieve the details from your subscription, please try again later."
              />
            </div>
          )}
          {buttons}
        </AnalyticsWrapper>
      )}
      {supportBanner}
    </ThemedSection>
  );
}
