import React, { useState } from "react";

import { Heading } from "@source-web/heading";
import { InteractiveIcon } from "@source-web/interactive-icon";
import { SelectInputWithLabel } from "@source-web/select-input-with-label";

import loadable from "@loadable/component";

import { LabelList, ListView } from "./components";
import { Labels } from "./components/LabelList";
import {
  AnalyticsGraph,
  AnalyticsGraphContainer,
  AnalyticsGraphWrapper,
  IconAndFilterWrapper,
  IconWrapper
} from "./styles/AnalyticsDashboardGraph.styles";

const ResponsiveLineChart = loadable.lib(
  () => import("../../../../../../components/ResponsiveLineChart")
);

const availableViews = {
  graph: "graph",
  list: "list"
};

const selectOptions = [
  {
    text: "Select a filter",
    value: "",
    disabled: true
  },
  {
    text: "7 Days",
    value: "7"
  },
  {
    text: "14 Days",
    value: "14"
  },
  {
    text: "30 Days",
    value: "30"
  },
  {
    text: "60 Days",
    value: "60"
  }
];

export function AnalyticsDashboardGraph({
  name,
  data,
  tableData,
  listData,
  selectedFilter,
  onChangeFilter
}: any) {
  const [selectedView, setSelectedView] = useState(availableViews.graph);
  const [selectedLabel, setSelectedLabel] = useState<Labels>(listData[0]);
  if (!data) return <React.Fragment />;

  function renderContent() {
    if (selectedView === availableViews.graph) {
      const dataToRender = data.filter((line: any) =>
        selectedLabel.types
          .map((label) => label.split("/")[0].toLowerCase())
          .includes(line.id.split("/")[0].toLowerCase())
      );

      return (
        <AnalyticsGraph>
          <ResponsiveLineChart>
            {/* @ts-ignore */}
            {({ ResponsiveLineChart }) => (
              <ResponsiveLineChart data={dataToRender} />
            )}
          </ResponsiveLineChart>
        </AnalyticsGraph>
      );
    }

    const tableDataToRender = tableData.filter((line: any) =>
      selectedLabel.types
        .map((label) => label.split("/")[0].toLowerCase())
        .includes(line.eventType.split("/")[0].toLowerCase())
    );

    return (
      <ListView
        data={tableDataToRender}
        headings={["Event type", "Affected endpoints", "Detections"]}
      />
    );
  }

  return (
    <AnalyticsGraphContainer>
      <IconAndFilterWrapper>
        <Heading text={name} level={3} />
        <SelectInputWithLabel
          fieldWrapper={{
            label: "",
            showLabel: false,
            width: "full"
          }}
          selectInput={{
            onChange: (event) => {
              onChangeFilter(event.target.value);
            },
            name: "dateFilter",
            id: "dateFilter",
            options: selectOptions,
            value: selectedFilter
          }}
        />
      </IconAndFilterWrapper>
      <AnalyticsGraphWrapper>
        <IconWrapper>
          <InteractiveIcon
            srText="select chart view button"
            onClick={() => setSelectedView(availableViews.graph)}
            name="chart-bar"
            group="system"
            size={2}
          />
          <InteractiveIcon
            srText="select list view button"
            onClick={() => setSelectedView(availableViews.list)}
            name="bullet-list"
            group="system"
            size={2}
          />
        </IconWrapper>
        <LabelList
          selectedLabel={selectedLabel.label}
          labels={listData}
          onClickLabel={(label) => {
            setSelectedLabel(label);
          }}
        />
        {renderContent()}
      </AnalyticsGraphWrapper>
    </AnalyticsGraphContainer>
  );
}
