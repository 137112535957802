import React from "react";

import { Paragraph } from "@source-web/paragraph";

import {
  ICompanyFactor,
  getGradeBasedOnScore,
  getTileColorBasedOnScore
} from "../../lib/";
import { ContentfulScoreConfiguration } from "../../pageTemplates/servicesPage/types";
import * as Styled from "./styles/CategorySection.styles";

export interface ScoreSectionTileProps {
  factor: ICompanyFactor;
  onClick?: (factor: ICompanyFactor) => void;
  securityScoreCardConfigurationData: ContentfulScoreConfiguration;
}

export const ScoreSectionTile: React.FC<ScoreSectionTileProps> = ({
  factor,
  onClick,
  securityScoreCardConfigurationData
}) => {
  const { score, name } = factor;
  if (!score) return null;

  const parsedScore = parseInt(score);

  const grade = getGradeBasedOnScore(parsedScore);

  const severity = securityScoreCardConfigurationData?.edges?.find(
    ({ node }) => node.rating === grade
  )?.node.text;

  return (
    <Styled.ComponentContainer
      onClick={() => onClick?.(factor)}
      color={getTileColorBasedOnScore(parseInt(score || ""))}
    >
      <Styled.Application>
        <Styled.FactorName>
          <Paragraph weight={3} size={2} noMargin>
            {" "}
            {name}{" "}
          </Paragraph>
        </Styled.FactorName>
        <Styled.FactorSeverity>
          <Paragraph weight={3} size={1} noMargin>
            {" "}
            {severity}{" "}
          </Paragraph>
        </Styled.FactorSeverity>
      </Styled.Application>
      <Styled.FactorScore>{parseInt(score, 10)}</Styled.FactorScore>
    </Styled.ComponentContainer>
  );
};
