import React from "react";

import { Heading } from "@source-web/heading";
import { Span } from "@source-web/span";

import { StyledStepGridItemWrapper } from "../../../styles/ServicesPage.styles";
import { StepProps } from "../LookoutDialog";

type StepThreeProps = StepProps & {
  icon: {
    file: {
      url: string;
    };
  };
};

export function StepThree({ heading, icon, stepHeading }: StepThreeProps) {
  return (
    <StyledStepGridItemWrapper>
      <Heading text={stepHeading} weight={3} level={4} />
      <Span>{heading}</Span>
      <img src={icon.file.url} height="104px" width="104px" />
    </StyledStepGridItemWrapper>
  );
}
