import React from "react";

import { Heading } from "@source-web/heading";
import { Icon } from "@source-web/icon";
import { SimpleGrid } from "@source-web/simple-grid";
import { Tooltip } from "@source-web/tooltip";

import { ThemedSection } from "../../../../../../components";
import { TabContent } from "../../../../styles/ServicesPage.styles";
import { ScoreAndFactorsProps } from "../../../../types";
import {
  RemoveOutline,
  SSCDivider,
  SSCTitleWrapper,
  SecurityScoreCardWrapper
} from "../../styles/SecurityScoreCard.styles";
import FactorsLayer from "./components/FactorLayers.tsx/FactorLayer";
import ScoreLayer from "./components/ScoreLayers/ScoreLayer";

export const ScoreAndFactors = ({
  securityScorePageData,
  isLoading,
  scoreData,
  securityScoreCardConfigurationData,
  isDark,
  securityScoreCardRatings,
  selectedFactor,
  setSelectedFactor,
  factorInContentful,
  platformUrl,
  partnermodalData,
  formattedLocale
}: ScoreAndFactorsProps) => {
  return (
    <TabContent>
      <ThemedSection appearance="secondary">
        <SecurityScoreCardWrapper>
          <SimpleGrid
            spacing={0}
            layout={{
              sm: [12, 12, 12],
              lg: [12]
            }}
          >
            <SSCTitleWrapper>
              <Heading
                level={3}
                text={securityScorePageData.heading}
                appearance="primary"
              />
              <RemoveOutline>
                <Tooltip
                  size={1}
                  id="ssc-tooltip-score"
                  text={securityScorePageData.tooltipHelpText}
                >
                  <Icon name="help-circle" />
                </Tooltip>
              </RemoveOutline>
            </SSCTitleWrapper>
            <SSCDivider />

            <ScoreLayer
              {...{
                securityScorePageData,
                isLoading,
                scoreData,
                securityScoreCardConfigurationData,
                partnermodalData,
                formattedLocale
              }}
            />

            <FactorsLayer
              {...{
                isDark,
                securityScorePageData,
                scoreData,
                securityScoreCardRatings,
                selectedFactor,
                setSelectedFactor,
                factorInContentful,
                platformUrl,
                isLoading,
                partnermodalData,
                securityScoreCardConfigurationData,
                formattedLocale
              }}
            />
          </SimpleGrid>
        </SecurityScoreCardWrapper>
      </ThemedSection>
    </TabContent>
  );
};
