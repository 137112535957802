import { paramCase } from "param-case";

import { getStoredUserData } from "../../context/auth/authStorage";
import { fetchJsonContentful } from "../../lib/services/serviceUtils";

const testSpaceId = "5dzi6b3j1ne9";

/* istanbul ignore next */
export async function getContentfulAsset(assetId: string) {
  return await fetchJsonContentful(
    `/spaces/${testSpaceId}/environments/master/assets/${assetId}`,
    {
      method: "GET"
    }
  )
    .then((res) => res.json())
    .then((res) => res?.fields);
}

/* istanbul ignore next */
export async function captureCampaignInformation(
  formValues: any,
  heading: any
): Promise<any> {
  const state = getStoredUserData();
  const { user } = state;
  const entryId = `${paramCase(heading)}-${user?.userId}-campaign`;

  const onboardingCallDateAndTime = formValues[1]?.hour;

  const request = await fetchJsonContentful(
    `/spaces/${testSpaceId}/environments/master/entries/${entryId}`,
    {
      body: JSON.stringify({
        fields: {
          userId: {
            "en-GB": `${user?.userId}`
          },
          userEmail: {
            "en-GB": user?.user
          },
          campaignData: {
            "en-GB": formValues
          },
          onboardingCallDateAndTime: {
            "en-GB": onboardingCallDateAndTime
          }
        }
      }),
      method: "PUT",
      headers: {
        Authorization: `Bearer ${process.env.CONTENTFUL_API_KEY}`,
        "X-Contentful-Content-Type": "campaignDataCaptureTesting"
      }
    }
  );

  return request;
}

/* istanbul ignore next */
export async function getCapturedCampaignInformation(
  params: any
): Promise<any> {
  const entryId = `${params.service}-${params.userId}-campaign`;

  const response = await fetchJsonContentful(
    `/spaces/${testSpaceId}/environments/master/entries/${entryId}`,
    {
      method: "GET",
      headers: {
        "X-Contentful-Content-Type": "campaignDataCaptureTesting"
      }
    }
  );
  return response;
}
