import React from "react";
import { DefaultTheme, useTheme } from "styled-components";

import { ResponsivePie } from "@nivo/pie";

import { CybSafeScoreChartContainer } from "../../styles/CybSafe.styles";

type CenteredMetricProps = {
  centerX: number;
  centerY: number;
  score: number;
  theme: DefaultTheme;
};

const CenteredMetric = ({
  centerX,
  centerY,
  score,
  theme
}: CenteredMetricProps) => {
  return (
    <>
      <text
        x={centerX}
        y={centerY - 20}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: "15px",
          fill: theme.name === "WS10" ? "#000" : "#fff"
        }}
      >
        Score
      </text>
      <text
        x={centerX}
        y={centerY + 5}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: "40px",
          fontWeight: "600",
          fill: theme.name === "WS10" ? "#000" : "#fff"
        }}
      >
        {score}
      </text>
    </>
  );
};

const SecurityScoreBehaviour: React.FC<{ score: number }> = ({ score }) => {
  const theme = useTheme();
  const getColorForScore = (score: number) => {
    if (score < 50) return "#EA191A";
    if (score < 80) return "#EB9700";
    return "#B6BF11";
  };

  return (
    <CybSafeScoreChartContainer>
      <ResponsivePie
        data={[
          {
            id: "score",
            label: "Score",
            value: score,
            color: getColorForScore(score)
          },
          {
            id: "rest",
            label: "Rest",
            value: 100 - score,
            color: "hsl(0, 0%, 90%)"
          }
        ]}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        innerRadius={0.85}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        colors={{ datum: "data.color" }}
        borderWidth={1}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        enableArcLinkLabels={false}
        enableArcLabels={false}
        layers={[
          "arcs",
          "arcLinkLabels",
          "legends",
          (props) => <CenteredMetric {...props} score={score} theme={theme} />
        ]}
      />
    </CybSafeScoreChartContainer>
  );
};

export default SecurityScoreBehaviour;
