import React from "react";

import SecurityScore from "../../../../../../../../components/SecurityScore";
import { getGradeBasedOnScore } from "../../../../../../../../lib";
import { ScoreLayerProps } from "../../../../../../types";
import { SecurityScoreCardConfiguration } from "../../../SecurityScoreCardConfiguration";
import { ScoreContainer } from "./styles/ScoreLayer.styles";

const ScoreLayer = ({
  securityScorePageData,
  isLoading,
  scoreData,
  securityScoreCardConfigurationData
}: ScoreLayerProps) => (
  <ScoreContainer>
    <div>
      <SecurityScore
        marginMobile={40}
        securityScoreCardConfigurationData={securityScoreCardConfigurationData}
        calculatingText={securityScorePageData.calculatingText}
        emptyScoreMessage={securityScorePageData.emptyScoreMessage}
        isCalculating={isLoading}
        score={scoreData?.score}
        rating={getGradeBasedOnScore(scoreData?.score)}
      />
    </div>
    <SecurityScoreCardConfiguration
      tableHeading={securityScorePageData.scoreConfigurationTableHeadings}
      heading={securityScorePageData.scoreConfigurationHeading}
      score={scoreData?.score}
      data={securityScoreCardConfigurationData}
    />
  </ScoreContainer>
);

export default ScoreLayer;
