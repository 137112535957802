import styled, { css } from "styled-components";

export const FilterWrapper = styled.div(
  (props) => css`
    @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
      margin-top: ${props.theme.spacing.fixed[5]}px;
      margin-bottom: ${props.theme.spacing.fixed[6]}px;
    }
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: ${props.theme.spacing.fixed[3]}px;
    & > p {
      margin: 0;
    }
    & select {
      padding: ${props.theme.spacing.fixed[1]}px
        ${props.theme.spacing.fixed[8]}px ${props.theme.spacing.fixed[1]}px
        ${props.theme.spacing.fixed[4]}px;
      font-size: 20px;
      border: 1px solid ${props.theme.color.monochrome6.default};
      color: ${props.theme.color.monochrome6.default};
      background: ${props.theme.color.monochrome2.default};
    }
  `
);
