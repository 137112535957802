import React from "react";
import { useQuery } from "react-query";

import {
  ISecurityScorecardRootObject,
  getSecurityScoreAssessment
} from "../../../../lib/";
import {
  ContentfulIndustryBenchmark,
  ContentfulPartnerModal,
  ContentfulScoreConfiguration,
  ContentfulSecurityScorePage,
  GetContentfulScoreRatings,
  GetContentfulSecurityRating,
  GetContentfulSecurityScoreHistory
} from "../../types";
import { SecurityScoreCard } from "./SecurityScoreCard";

export interface ISecurityScoreCardProps {
  partnermodalData: ContentfulPartnerModal;
  securityScoreCardConfigurationData: ContentfulScoreConfiguration;
  securityScorePageData: ContentfulSecurityScorePage;
  industryBenchmarkData: ContentfulIndustryBenchmark["contentfulIndustryBenchmark"];
  securityScoreCardRatings: GetContentfulScoreRatings["allContentfulSecurityScorecardRatings"];
  platformUrl: string;
  securityRatings: GetContentfulSecurityRating["allContentfulSecurityRating"];
  formattedLocale: string;
  securityScoreHistory: GetContentfulSecurityScoreHistory["contentfulSecurityScoreHistory"];
}

export function ConnectedSecurityScoreCard(args: ISecurityScoreCardProps) {
  const { data: scoreData, isLoading } = useQuery<ISecurityScorecardRootObject>(
    {
      queryKey: "securityScoreCardPage",
      queryFn: () => getSecurityScoreAssessment()
    }
  );

  return <SecurityScoreCard {...{ ...args, scoreData, isLoading }} />;
}
