import React from "react";

import { Heading, HeadingProps } from "@source-web/heading";

import {
  EmailMessage,
  GenericTitleWrapper,
  LastDays,
  PhishingContainer,
  PhishingOverviewBody,
  PhishingOverviewValue
} from "../../styles/CybSafe.styles";

export function PhishingReviewCard({
  title,
  value,
  alignTitle = "flex-start",
  titleOptions = {
    level: 3,
    weight: 2,
    noMargin: true
  },
  subTitle,
  emailMessage
}: {
  title?: string;
  titleOptions?: HeadingProps;
  subTitle?: string;
  emailMessage?: string;
  alignTitle?: string;
  value: number;
}) {
  return (
    <PhishingContainer>
      <GenericTitleWrapper align={alignTitle}>
        <Heading {...titleOptions} level={3} weight={2} text={title} />
      </GenericTitleWrapper>
      <PhishingOverviewBody>
        {/* subTitle entry should be checked from backend Team*/}
        <LastDays>{subTitle}</LastDays>
        <PhishingOverviewValue>
          <Heading
            level={3}
            weight={3}
            text={value.toString()}
            noMargin={true}
          />
          <EmailMessage>{emailMessage}</EmailMessage>
        </PhishingOverviewValue>
      </PhishingOverviewBody>
    </PhishingContainer>
  );
}
