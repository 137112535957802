import { navigate } from "gatsby";
import React, { useState } from "react";
import { useTheme } from "styled-components";

import { Icon } from "@source-web/icon";
import { Modal } from "@source-web/modal";
import { Span } from "@source-web/span";

import { messagePopupButtonClick, usePathnameByIndex } from "../../lib";
import { MessageButtonProps } from "../../pageTemplates/explore/types";
import { PrimaryButton } from "../PrimaryButton";
import {
  GoToButtons,
  Message,
  MessagePageContainer,
  MessagePopupContainer
} from "./styles/MessagePopup.style";

interface MessagePopupProps {
  // function that closes the modal
  onClose: () => void;
  // message displayed in the modal
  message?: string;
  // object with buttons
  button: MessageButtonProps[];
  // message type, whether popup or page
  type: boolean;
  // icon displayed in the popup
  icon: string;
  // formatted language
  formattedLocale: string;
  // modal state
  openState?: boolean;
  // function that changes the state of the modal
  setOpenState?: (arg: boolean) => void;
}

export function MessagePopup({
  message,
  button,
  icon,
  type,
  formattedLocale,
  openState,
  setOpenState
}: MessagePopupProps) {
  const { name } = useTheme();
  const [isOpen, setIsOpen] = useState(true);
  const isPage = type;

  const handleClickButton = (item: MessageButtonProps) => {
    setIsOpen(false);
    !item.openInNewTab
      ? navigate(`/${formattedLocale}/${item.href}`)
      : undefined;
  };

  const pathname = usePathnameByIndex(1);

  const ContentPage = () => (
    <>
      <Icon
        name={icon}
        group={name === "WS10" ? "hifiLight" : "hifiDark"}
        size={2}
      />

      <Message>{message && <Span>{message}</Span>}</Message>

      <GoToButtons>
        {button.map((item, index) => {
          const { buttonText, appearance, href, openInNewTab } = item;
          return (
            <PrimaryButton
              key={index}
              appearance={appearance === "primary" ? "primary" : "alt1"}
              href={openInNewTab ? href : undefined}
              htmlAttributes={{
                target: openInNewTab ? "_blank" : "_parent"
              }}
              inverse={name !== "WS10"}
              text={buttonText}
              onClick={() => {
                messagePopupButtonClick({
                  page_locale: formattedLocale,
                  journey_type: item.buttonText,
                  page_name: pathname
                });
                handleClickButton(item);
              }}
            />
          );
        })}
      </GoToButtons>
    </>
  );

  const ContentPopup = () => (
    <Modal
      overlayBlur
      srName="request-callback"
      appearance="primary"
      isOpen={openState || isOpen}
      size={3}
      animate
      isClosable
      onCloseCb={() => {
        setOpenState && setOpenState(false);
        setIsOpen(false);
      }}
    >
      <MessagePopupContainer>
        <ContentPage />
      </MessagePopupContainer>
    </Modal>
  );

  return !isPage ? (
    <ContentPopup />
  ) : (
    <MessagePageContainer>
      <ContentPage />
    </MessagePageContainer>
  );
}
