import styled, { css } from "styled-components";

export const RequestStatusInfo = styled.div`
  flex: 1 1 auto;
  width: 100%;
`;

export const PillContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 10px;
`;

export const Card = styled.div`
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.5));
  white-space: normal;
  #triangle-down {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid #252525;
    transform: rotate(180deg);
  }
  ${() => css`
    height: 100%;
    align-items: center;
    & > div {
      width: 100%;
      padding: 0;
      background: none;
      border-radius: 8px;
    }
    & > div > div > div {
      cursor: pointer;
    }

    margin: 1em;
  `}
`;

export const RequestStatusBody = styled.div<{ selected: boolean }>`
  ${(props) => css`
    & > div > span {
      color: ${props.selected && props.theme.name === "WS10"
        ? props.theme.color.monochrome1.default
        : ""};
    }
    @media (min-width: ${props.theme.breakpoints.md}px) {
      flex-wrap: wrap;
      display: flex;
      align-items: flex-start;
      gap: 1em;
      flex-direction: column;
      justify-content: space-between;
    }
    padding: ${props.theme.spacing.fixed[3]}px ${props.theme.spacing.fixed[4]}px;
    width: 100%;
    background: ${props.selected
      ? props.theme.color.monochrome4.default
      : props.theme.color.monochrome2.default};

    border-radius: 0 0 ${props.theme.border.radius[1]}
      ${props.theme.border.radius[1]};
  `}
`;

export const RequestStatusHeader = styled.div<{ selected: boolean }>`
  ${(props) => css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0.5em ${props.theme.spacing.fixed[4]}px;

    background: ${props.selected
      ? props.theme.color.monochrome4.default
      : props.theme.color.monochrome2.default};
    border-bottom: 1px solid ${props.theme.color.monochrome5.default};
    border-radius: ${props.theme.border.radius[1]}
      ${props.theme.border.radius[1]} 0 0;

    & > p {
      margin-bottom: 0;
    }
    & > span {
      color: ${props.selected && props.theme.name === "WS10"
        ? props.theme.color.monochrome1.default
        : ""};
    }
  `}
`;

export const TriangleDownContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20px;
`;

export const TriangleDown = styled.span`
  ${(props) => css`
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid ${props.theme.color.monochrome4.default};
    transform: rotate(180deg);
    filter: drop-shadow(0px -1px 1px rgba(0, 0, 0, 0.5));
  `}
`;
