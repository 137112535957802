import styled, { css } from "styled-components";

export const HeadingWrapper = styled.div(
  () => css`
    grid-column: 2 span;
    margin-bottom: 1.25em;
  `
);

export const FactorsGrid = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 450px;
  gap: 0.725em;
`;

export const PartnerModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PartnerHeader = styled.div(
  (props) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      &:last-child {
        margin-inline: 6px;
      }
    }
    svg {
      margin-right: 20px;
      width: 42px;
      height: 42px;
    }
    margin-bottom: 48px;
    @media only screen and (min-width: ${props.theme.breakpoints
        .sm}px) and (max-width: ${props.theme.breakpoints.md}px) {
      display: flex;
      flex-wrap: wrap;
      svg {
        margin-right: 8px;
      }
      h1 {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }
    }

    @media only screen and (min-width: ${props.theme.breakpoints
        .md}px) and (max-width: ${props.theme.breakpoints.lg}px) {
      display: flex;
      flex-wrap: wrap;
      h1 {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        &:last-child {
          margin-inline: 6px;
        }
      }
    }
  `
);

export const PartnerContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PartnerBody = styled.div`
  margin: 10px 0;
  [data-component-name="Checkbox"] {
    margin-top: 36px;
  }
`;

export const PartnerFooter = styled.div`
  margin: 40px 0 0;
  display: grid;
  place-items: center;
  [data-component-name="Button"] {
    width: 300px;
  }
`;
