//@ts-nocheck
import dayjs from "dayjs";
import React, { useState } from "react";

import { Container } from "@source-web/container";
import { Heading } from "@source-web/heading";
import { InteractiveIcon } from "@source-web/interactive-icon";
import { LinkWithIcon } from "@source-web/link-with-icon";
import { MatrixTable } from "@source-web/matrix-table";
import { Paragraph } from "@source-web/paragraph";
import { TableCell, TableRow } from "@source-web/table";

import { Grid, GridColumn, GridRow } from "@vfuk/core-grid";
import { NavigationalTabs, Tab } from "@vfuk/core-navigational-tabs";

import { IAppointment } from "../../lib";
import { PrimaryButton } from "../PrimaryButton";
import * as Styled from "./styles/ExpandableTable.styles";

interface ExpandableRowProps {
  value: IAppointment;
  onClick: () => void;
  isHidden: boolean;
  isOpened: boolean;
  horizontalHeadings: string[];
  haveButton?: boolean;
}

interface ExpandedContainerProps {
  reports: any;
  item: any[];
  setIsSkipConfirmationOpen: () => void;
  navigateToWalkthrough: () => void;
  walkthroughButtonText: string;
  isWalkthroughService: boolean;
  showWalkthroughCall: boolean;
}

export const WalkthroughGrid = ({
  navigateToWalkthrough,
  walkthroughButtonText,
  setIsSkipConfirmationOpen,
  isWalkthroughService,
  showWalkthroughCall
}: ExpandedContainerProps) => {
  const didWalkthrough = sessionStorage.getItem("did-walkthrough") === "true";
  const displayIf =
    isWalkthroughService && showWalkthroughCall && !didWalkthrough;
  return (
    <Styled.BookWalkthroughContainer>
      {displayIf ? (
        <Grid>
          <GridRow justify="between" align="center">
            <GridColumn marginBottom={6} marginTop={6} col={12} colMd={6}>
              <Styled.BookWalkthroughHeading>
                <Heading
                  noMargin
                  level={3}
                  weight={3}
                  text="Book a walkthrough call"
                />
                <Styled.ParagraphContainer>
                  <Paragraph>
                    Accenture would like to schedule a call with you to walk you
                    through the details of the full report
                  </Paragraph>
                </Styled.ParagraphContainer>
              </Styled.BookWalkthroughHeading>
            </GridColumn>

            <Styled.MobileSkipButtonContainer>
              <Styled.SkipButton
                aria-label="skip button"
                onClick={() => {
                  setIsSkipConfirmationOpen();
                }}
              >
                Skip
              </Styled.SkipButton>
              <PrimaryButton
                text={walkthroughButtonText}
                onClick={() => navigateToWalkthrough()}
                appearance="primary"
              />
            </Styled.MobileSkipButtonContainer>
          </GridRow>
        </Grid>
      ) : null}
    </Styled.BookWalkthroughContainer>
  );
};

const THIRTY_DAYS = 30 * 24 * 60 * 60 * 1000;

export const ExpandableContainerRow = ({
  value,
  isHidden,
  haveButton
}: any) => {
  const isNew =
    new Date(new Date() - new Date(value.created_at)).getTime() <= THIRTY_DAYS;

  return isHidden || !value ? null : (
    <Styled.TabSection>
      <Grid>
        <GridRow justify="between" align="center">
          <GridColumn>
            <Styled.FlexDiv>
              <Heading
                level={5}
                weight={2}
                text={value?.report?.file["en-GB"]?.fileName}
              />
              {isNew && <Styled.NewPill>New</Styled.NewPill>}
            </Styled.FlexDiv>
          </GridColumn>
          <GridColumn>{dayjs(value.date).format("DD/MM/YY HH:mm")}</GridColumn>
          {haveButton ? (
            <GridColumn align="center" col={2}>
              <Styled.FlexColumn>
                <a
                  href={`https:${value?.report?.file["en-GB"]?.url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View
                </a>
                <a href={`https:${value.report?.file["en-GB"]?.url}`} download>
                  <InteractiveIcon name="download" />
                </a>
              </Styled.FlexColumn>
            </GridColumn>
          ) : null}
        </GridRow>
      </Grid>
    </Styled.TabSection>
  );
};

export const ExpandedContainer = ({
  item,
  ...props
}: ExpandedContainerProps) => {
  return (
    <Styled.FileSectionContainer>
      <>
        <Styled.ButtonSection>
          <WalkthroughGrid {...props} />
        </Styled.ButtonSection>
        <Styled.TabsWrapper>
          <NavigationalTabs>
            {/*TODO: commenting for now
             <Tab id="request" text="Request" key="Request">
              <Styled.TabSectionTitle>
                <Heading level={2} text="Request" />
              </Styled.TabSectionTitle>
            </Tab> */}
            <Tab id="files" text="Files" key="Files">
              <Styled.TabSectionTitle>
                <Heading level={2} text="Files" />
              </Styled.TabSectionTitle>

              {item &&
                [item]?.map((value, index) => (
                  <ExpandableContainerRow
                    key={index}
                    value={value}
                    haveButton={true}
                    isHidden={false}
                    onClick={() => {}}
                  />
                ))}
            </Tab>
          </NavigationalTabs>
        </Styled.TabsWrapper>
      </>
    </Styled.FileSectionContainer>
  );
};

export const TableCellWithWrapper = ({
  children,
  label
}: {
  label: string;
  children?: React.ReactElement | string;
}) =>
  label ? (
    <TableCell>
      <Styled.StyledTableEntryWrapper>
        <Styled.MobileLabel>{label}:</Styled.MobileLabel>
        {children && children}
      </Styled.StyledTableEntryWrapper>
    </TableCell>
  ) : null;

export const MobileCard = ({ item }: { item: IAppointment }) => {
  return (
    <Styled.TabItemWrapper>
      <Container>
        <Styled.TabItemHeader>
          <Paragraph weight={3} size={2}>
            ID: {item.uuid}
          </Paragraph>
          <Paragraph weight={3} size={2}>
            Date: {dayjs(item.endDate).format("DD/MM/YY")}
          </Paragraph>
        </Styled.TabItemHeader>
        <Styled.TabItemBody>
          <Styled.TitleContainer>
            <LinkWithIcon
              appearance="primary"
              text={item.description}
              icon={{
                name: "chevron-right",
                justify: "right"
              }}
              href="#"
              size={3}
              htmlAttributes={{ target: "_blank" }}
            />
          </Styled.TitleContainer>
          Hours: {item.totalHours}
        </Styled.TabItemBody>
      </Container>
    </Styled.TabItemWrapper>
  );
};

export const ExpandableRow = ({
  value,
  onClick,
  isHidden,
  isOpened,
  haveButton,
  horizontalHeadings
}: ExpandableRowProps) =>
  isHidden ? null : (
    <TableRow>
      <TableCellWithWrapper label={horizontalHeadings[0]}>
        {value.uuid}
      </TableCellWithWrapper>
      <TableCellWithWrapper label={horizontalHeadings[1]}>
        {value.description}
      </TableCellWithWrapper>
      <TableCellWithWrapper label={horizontalHeadings[2]}>
        {dayjs(value.endDate).format("DD/MM/YY HH:mm")}
      </TableCellWithWrapper>
      <TableCellWithWrapper label={horizontalHeadings[3]}>
        {value.totalHours}
      </TableCellWithWrapper>
      {haveButton ? (
        <TableCell>
          <Styled.StyledTableEntryWrapper>
            <Styled.ToggleButton
              onClick={() => onClick(isOpened ? null : value.uuid)}
            >
              {isOpened ? "Close" : "Open"}
            </Styled.ToggleButton>
          </Styled.StyledTableEntryWrapper>
        </TableCell>
      ) : null}
    </TableRow>
  );

export const ExpandableTable = ({
  items,
  haveButton,
  horizontalHeadings,
  setIsSkipConfirmationOpen,
  navigateToWalkthrough,
  walkthroughButtonText,
  isWalkthroughService,
  showWalkthroughCall
}: {
  items?: IAppointment[];
  horizontalHeadings?: string[];
  haveButton?: boolean;
  setIsSkipConfirmationOpen?: () => void;
  navigateToWalkthrough?: () => void;
  walkthroughButtonText?: string;
  isWalkthroughService?: boolean;
  showWalkthroughCall?: boolean;
}) => {
  const [expandedRow, setExpanded] = useState<string | null>(null);
  return (
    <>
      <Styled.DesktopContainer>
        <Styled.TableWrapper>
          {items?.length ? (
            <MatrixTable
              invertCollapse
              horizontalHeadings={horizontalHeadings}
              verticalHeadings={horizontalHeadings.map((heading) => ({
                headingText: heading,
                srVisible: false
              }))}
            >
              {items?.map((item: IAppointment, index: React.Key | null) => (
                <ExpandableRow
                  key={index}
                  value={item}
                  haveButton={haveButton}
                  horizontalHeadings={horizontalHeadings}
                  isOpened={expandedRow !== null && expandedRow === item.uuid}
                  isHidden={expandedRow !== null && expandedRow !== item.uuid}
                  onClick={() =>
                    setExpanded(
                      expandedRow !== null && expandedRow === item.uuid
                        ? null
                        : item.uuid
                    )
                  }
                />
              ))}
            </MatrixTable>
          ) : (
            <Styled.EmptyTableAlert>
              {haveButton ? "No requests completed" : "No requests in progress"}
            </Styled.EmptyTableAlert>
          )}
        </Styled.TableWrapper>
        {expandedRow !== null && (
          <ExpandedContainer
            {...{
              setIsSkipConfirmationOpen,
              navigateToWalkthrough,
              walkthroughButtonText,
              isWalkthroughService,
              showWalkthroughCall
            }}
            item={items?.find((item) => item.uuid === expandedRow)}
          />
        )}
      </Styled.DesktopContainer>
      <Styled.MobileContainer>
        {items?.map((item, index) => (
          <MobileCard key={index} item={item} />
        ))}
      </Styled.MobileContainer>
    </>
  );
};

export default ExpandableTable;
