import styled, { css } from "styled-components";

export const HistoryWrapper = styled.div(
  (props) => css`
    max-width: ${props.theme.gridConfig.springWidth}px;
    margin: auto;
    height: 100%;
    padding: 2.75em 0;

    .axisYHistoryChart > {
      .axisYValue {
        font-size: 18px;
        fill: ${props.theme.name === "WS10"
          ? props.theme.color.primary3.default
          : props.theme.inverseColor.primary2.default};
      }
    }
    .axisXHistoryChart {
      font-size: 12px;
      fill: ${props.theme.name === "WS10"
        ? props.theme.color.primary3.default
        : props.theme.inverseColor.primary2.default};

      @media (min-width: ${props.theme.breakpoints.md}px) {
        font-size: 14px;
      }
    }
  `
);

export const HistoryTitleWrapper = styled.div(
  (props) => css`
    display: flex;
    align-items: left;
    gap: ${props.theme.spacing.fixed[2]}px;
    justify-content: left;
    flex-direction: column;

    padding: 0 2.5em;
    // Adjusts the title alignment to match help icon's vertical
    & h3 {
      margin-bottom: ${props.theme.spacing.fixed[1]}px;
    }

    @media (min-width: ${props.theme.breakpoints.md}px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 1em;
    }
  `
);

export const ViewLastWrapper = styled.div(
  () => css`
    max-width: 260px;
    display: flex;
    flex-direction: row;
    align-items: center;

    h4 {
      margin-right: 15px;
    }

    > div[data-component-name="SelectInputWithLabel"]
      > div[data-component-name="FieldWrapper"]
      > div:first-child {
      margin: 0;
    }
  `
);

export const HistoryDivider = styled.div(
  (props) => css`
    border-bottom: 1px solid ${props.theme.color.primary3.default};
    margin: 1em auto;
    max-width: 90%;

    @media (min-width: ${props.theme.breakpoints.md}px) {
      margin: 1em 0 1.5em 0;
      max-width: 100%;
    }
  `
);

export const ChartWrapper = styled.div(
  () => css`
    width: 100%;
    height: 500px;
  `
);

export const ViewDetailsWrapper = styled.div(
  (props) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px 80px;

    svg {
      stroke: ${props.theme.name === "WS10"
        ? props.theme.color.primary2.default
        : props.theme.color.primary3.default};
    }
  `
);
