import React from "react";
import { useTheme } from "styled-components";

import { Heading } from "@source-web/heading";
import { Tab, Tabs } from "@source-web/tabs";

import { GridRow } from "@vfuk/core-grid";

import { ThemedSection } from "../../../../components";
import {
  NavigationalSectionContainer,
  SectionHeadingContainer,
  ServiceTabsContainer
} from "../../styles/ServicesPage.styles";
import { ServiceTabs } from "../ServiceTabs";

interface ServiceProps {
  heading: string;
  openFormCallback(flow: string): void;
  serviceId: number;
  formattedLocale: string;
  isWalkthroughService: boolean;
  walkthroughButtonText: string;
  reports: any;
}

export const ManagedFirewall = ({
  openFormCallback,
  serviceId,
  heading,
  formattedLocale,
  isWalkthroughService,
  walkthroughButtonText,
  reports
}: ServiceProps) => {
  const theme = useTheme();
  return (
    <ThemedSection appearance="primary">
      <NavigationalSectionContainer>
        <Tabs
          {...(theme.name === "WS10Dark" && {
            appearance: "secondary",
            inverse: true
          })}
        >
          <Tab text="Requests" key="requests" id="requests">
            <ThemedSection appearance="secondary">
              <GridRow justify="center">
                <SectionHeadingContainer>
                  <Heading level={4} text="Your Requests" />
                </SectionHeadingContainer>
              </GridRow>

              <ServiceTabsContainer>
                <ThemedSection appearance="primary">
                  <ServiceTabs
                    openFormCallback={openFormCallback}
                    serviceId={serviceId}
                    heading={heading}
                    formattedLocale={formattedLocale}
                    isWalkthroughService={isWalkthroughService}
                    walkthroughButtonText={walkthroughButtonText}
                    reports={reports}
                  />
                </ThemedSection>
              </ServiceTabsContainer>
            </ThemedSection>
          </Tab>
          <Tab text="Reports" key="reports" id="reports">
            <ThemedSection appearance="secondary">
              <GridRow justify="center">
                <SectionHeadingContainer>
                  <Heading level={4} text="Your reports" />
                </SectionHeadingContainer>
              </GridRow>
            </ThemedSection>
          </Tab>
        </Tabs>
      </NavigationalSectionContainer>
    </ThemedSection>
  );
};
