import styled, { css } from "styled-components";

import { Theme } from "@source-web/themes";

export const StyledLabel = styled.text(
  ({
    theme,
    smallStroke,
    active
  }: {
    smallStroke: boolean;
    active: boolean;
    theme: Theme;
  }) => css`
    text-anchor: middle;
    font-size: ${active ? 20 : 14}px;
    font-weight: ${active ? 400 : "inherit"};
    stroke-width: ${smallStroke ? 0.4 : 1};
    letter-spacing: 1.2px;
    stroke: ${theme.name !== "WS10"
      ? theme.color.monochrome3.default
      : theme.color.monochrome4.default};

    fill: ${theme.name !== "WS10"
      ? theme.color.monochrome3.default
      : theme.color.monochrome4.default};

    @media (max-width: ${theme.breakpoints.lg - 1}px) {
      display: none;
    }
  `
);

export const StyledTooltipValuesContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-left: 5px solid ${({ color }) => color};
  padding-left: 0.75em;
  margin-top: 0.75em;

  & > span {
    text-align: start;
  }
`;

export const ResponsiveRadarWrapper = styled.div`
  ${({ theme }) => css`
    height: 100%;
    width: 100%;
    ${theme.name === "WS10" &&
    css`
      svg > g:first-of-type > g:first-of-type > circle {
        stroke: #bebebe;
      }
    `}
  `}
`;
