import React from "react";

import { Container } from "@source-web/container";
import { StateKeys } from "@source-web/icon";
import { Paragraph } from "@source-web/paragraph";
import { Pill } from "@source-web/pill";
import { Span } from "@source-web/span";

import * as Styled from "./styles/RequestStatusCard.styles";

export enum pillTextEnum {
  REQUEST_COMPLETE = "Request Completed",
  REPORT_IN_PROGRESS = "Report in Progress"
}
interface RequestStatusProps {
  id: string;
  date: string;
  status: string;
  pillText: string;
  isSelected: boolean;
  onChange: () => void;
}

const RequestStatusCard = ({
  id,
  date,
  status,
  pillText,
  isSelected,
  onChange
}: RequestStatusProps) => {
  const getState = (): StateKeys => {
    switch (pillText) {
      case pillTextEnum.REQUEST_COMPLETE:
        return "success";

      case pillTextEnum.REPORT_IN_PROGRESS:
        return "warn";

      default:
        return "info";
    }
  };

  return (
    <Styled.Card onClick={onChange}>
      <Container>
        <Styled.RequestStatusHeader selected={isSelected}>
          <Paragraph weight={3} size={1}>
            Id:{id}
          </Paragraph>

          <Paragraph weight={3} size={1}>
            {date}
          </Paragraph>
        </Styled.RequestStatusHeader>
        <Styled.RequestStatusBody selected={isSelected}>
          <Styled.RequestStatusInfo>
            <Span weight={3}>{status}</Span>
            <Styled.PillContainer>
              <Pill state={getState()} text={pillText} />
            </Styled.PillContainer>
          </Styled.RequestStatusInfo>
        </Styled.RequestStatusBody>
        {isSelected && (
          <Styled.TriangleDownContainer>
            <Styled.TriangleDown />
          </Styled.TriangleDownContainer>
        )}
      </Container>
    </Styled.Card>
  );
};

export default RequestStatusCard;
