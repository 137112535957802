import styled, { css } from "styled-components";

import { Theme } from "@source-web/themes";

export const AnalyticsWrapper = styled.div(
  (props) => css`
    max-width: ${props.theme.gridConfig.springWidth}px;
    margin: auto;
    padding: 1.75em 0.875em;
    height: 100%;
    @media only screen and (min-width: ${props.theme.breakpoints.md}px) {
      padding: 1.75em 1.25em;
    }

    @media only screen and (max-width: ${props.theme.breakpoints.md}px) {
      & > div:first-of-type {
        & > :nth-child(1) {
          order: 1;
        }
        & > :nth-child(2) {
          order: 2;
        }
        & > :nth-child(3) {
          order: 4;
        }
        & > :nth-child(4) {
          order: 3;
        }
        & > :nth-child(5) {
          order: 5;
        }
        & > :nth-child(6) {
          order: 6;
        }
      }
    }
  `
);

export const AnalyticsTitleWrapper = styled.div(
  (props) => css`
    padding: 8px 16px;
    background-color: ${props.theme.color.monochrome1.default};

    @media (min-width: ${props.theme.breakpoints.md}px) and (max-width: ${props
        .theme.breakpoints.lg}px) {
      h3 {
        font-size: 32px;
      }
    }

    @media (max-width: ${props.theme.breakpoints.md}px) {
      h3 {
        font-size: 24px;
      }
    }

    @media (min-width: ${props.theme.breakpoints.lg}px) {
      h3 {
        font-size: 40px;
      }
    }

    & > div:first-of-type {
      margin: auto;
      max-width: ${props.theme.gridConfig.springWidth}px;
      h3 {
        @media only screen and (min-width: ${props.theme.breakpoints.md}px) {
          padding: 0 16px;
        }
      }
    }
    @media only screen and (min-width: ${props.theme.breakpoints.md}px) {
      padding: 11px 0;
    }
  `
);

interface AnalyticsCardTitleWrapper {
  theme: Theme;
  align?: string;
}

export const AnalyticsCardTitleWrapper = styled.div(
  ({ theme, align }: AnalyticsCardTitleWrapper) => css`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    flex: 1 100%;
    @media only screen and (min-width: ${theme.breakpoints.lg}px) {
      align-items: ${align};
      & h3 {
        font-size: 28px;
        min-height: 32px;
      }
    }
  `
);
export const TextWithoutBoxWrapper = styled.div`
  background-color: transparent !important;
  border: none !important;
  text-align: right;
  padding: 0 !important;
  margin-left: 10px !important;
  width: auto !important;
  h5 {
    font-weight: normal;
  }
`;

export const TimeStampWrapper = styled.div(
  (props) => css`
    width: 110px;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: ${props.theme.breakpoints.md - 1}px) {
      span:nth-child(2) {
        font-size: 18px;
      }
      span:first-child {
        font-size: 20px;
      }
    }
    @media only screen and (min-width: ${props.theme.breakpoints.md}px) {
      span:nth-child(2) {
        font-size: 20px;
      }
      span:first-child {
        font-size: 24px;
      }
    }
    @media only screen and (min-width: ${props.theme.breakpoints.lg}px) {
      span:nth-child(2) {
        font-size: 20px;
      }
      span:first-child {
        font-size: 28px;
      }
    }
  `
);

interface AnalyticsCardTileProps {
  theme: Theme;
  color?: string;
  isHorizontal?: boolean;
  isDark?: boolean;
  breakLineValue?: boolean;
  hasIcon?: boolean;
}

export const AnalyticsCardTile = styled.div(
  ({
    theme,
    isHorizontal,
    isDark,
    hasIcon,
    breakLineValue
  }: AnalyticsCardTileProps) => css`
    margin: 0.438em auto;
    border-radius: ${theme.border.radius["2"]};
    background: ${isDark
      ? theme.color.monochrome2.default
      : theme.color.monochrome1.default};
    display: flex;

    min-height: ${isDark ? "48px" : "76px"};
    @media only screen and (min-width: ${theme.breakpoints.md + 1}px) {
      min-height: ${isDark ? "64px" : "80px"};
    }
    @media only screen and (min-width: ${theme.breakpoints.lg}px) {
      min-height: ${isDark ? "84px" : "100px"};
    }

    @media only screen and (min-width: ${theme.breakpoints.md + 1}px) {
      justify-content: center;
      min-height: 70px;
      display: flex;
      border-radius: ${theme.border.radius["2"]};
      gap: 30px;
    }

    .wrapperCardTile {
      display: flex;
      flex: 1;
      flex-direction: ${!isHorizontal ? "row" : "column"};
      justify-content: space-between;
      align-items: center;
      height: 104px;
      @media (min-width: ${theme.breakpoints.md}px) and (max-width: ${theme
          .breakpoints.lg}px) {
        height: 80px;
      }

      @media (max-width: ${theme.breakpoints.md}px) {
        height: 72px;
      }

      @media (min-width: ${theme.breakpoints.md}px) {
        justify-content: space-between;
        gap: 25px;
        max-width: ${isDark ? "inherit" : "97%"};
        margin: 0 auto;
      }

      img {
        width: 28px;
        height: 28px;
        @media only screen and (min-width: ${theme.breakpoints.md + 1}px) {
          width: auto;
          height: auto;
        }
      }

      > h4:last-child {
        padding: 0 1.25em;
      }
      & :not(svg)path,
      circle {
        stroke: ${isDark
          ? theme.color.monochrome1.default
          : theme.color.monochrome5.default};
      }

      & > :nth-child(${hasIcon ? 2 : 1}) {
        margin: 0;
      }
      & > :nth-child(${hasIcon ? 1 : -1}) {
        min-width: 150px;
        max-width: 95%;
        @media only screen and (min-width: ${theme.breakpoints.md + 1}px) {
          max-width: auto;
        }
      }

      & > :not(.isText):nth-child(${hasIcon ? 2 : -1}) {
        @media only screen and (min-width: ${theme.breakpoints.sm}px) {
          width: 40px;
          height: 40px;
          padding: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.5em;
          background-color: ${theme.color.monochrome2.default};
          border: 1px solid ${theme.color.monochrome2.default};
          border-radius: 6px;
          font-weight: 400;
          font-size: 32px;
        }

        @media only screen and (min-width: ${theme.breakpoints.lg}px) {
          font-size: 40px;
          width: 68px;
          height: 54px;
        }

        @media (min-width: ${theme.breakpoints.md}px) and (max-width: ${theme
            .breakpoints.lg}px) {
          width: 78px;
          height: 56px;
          font-size: 32px;
        }

        @media only screen and (max-width: ${theme.breakpoints.sm}px) {
          font-size: 24px;
          width: 54px;
          height: 48px;
        }
      }

      & > .isText {
        min-width: 56px;
      }
    }

    @media (max-width: ${theme.breakpoints.md + 1}px) {
      svg {
        width: 40px;
        height: 40px;
        margin-inline: 0.25em;
      }
    }

    @media (min-width: ${theme.breakpoints.md + 1}px) {
      svg {
        width: 90px;
        height: 90px;
        margin-inline: 0.25em;
      }
    }

    svg {
      stroke: ${theme.name === "WS10" ? "black" : "white"};
    }

    ${breakLineValue &&
    css`
      display: flex;
      flex-direction: row;
      justify-content: center;

      align-items: center;
      & > div:first-of-type {
        flex-direction: row;
      }

      @media only screen and (max-width: ${theme.breakpoints.md - 1}px) {
        gap: 0.75em;

        justify-content: start;
        align-items: start;

        & > div:first-of-type {
          flex-direction: column;
        }
        & > h5[data-component-name="Heading"]:last-of-type {
          background: transparent;
          width: auto;
          border: none;
          font-size: 18px;
        }
        & > h4[data-component-name="Heading"]:last-of-type {
          max-width: calc(100% - 200px);
        }
      }
    `}
  `
);

export const AnalyticsColorBar = styled.div(
  (props) => css`
    height: 100%;
    width: 9px;
    background-color: ${props.color ? props.color : ""};
    border-radius: 10px;
    margin-right: 24px;
  `
);

export const AnalyticsCard = styled.div(
  () => css`
    div[class*="Interactionstyle"] {
      border-radius: 0;
      @media (min-width: 1241px) {
        border-radius: 6px;
      }
    }
    & > div {
      z-index: 0;
    }
  `
);

export const AnalyticsCardContentWrapper = styled.div(
  () =>
    css`
      flex: 1 auto;
      width: 100%;
    `
);
export const AnalyticsCardIconWrapper = styled.div`
  flex: 0 auto;
  min-height: 90px;
`;

export const AnalyticsCardStatisticWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const AnalyticsSpanWrapper = styled.div(
  (props) =>
    css`
      color: ${props.theme.color.monochrome4.default};
    `
);

export const StyledLoadingSpinnerWrapper = styled.div(
  () => css`
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  `
);

export const AnalyticsCardWrapper = styled.div(
  (props: { theme: Theme; increaseIconSize?: boolean }) => css`
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap;
    background-color: ${props.theme.color.monochrome1.default};
    ${props.increaseIconSize &&
    `
        & div:last-of-type {
      & > span > span > span > svg {
        width: 80px;
        height: 80px;
      }
    }
    & > div:first-of-type {
      width: 90%;
    }
    `}
  `
);

export const AnalyticsDashboardListWrapper = styled.div`
  margin: 1em;
  min-height: 280px;
`;

export const AnalyticsDashboardListLabelWrapper = styled.div(
  ({
    isTitle = false,
    theme,
    isLarge = false,
    isObjectValue = false
  }: {
    theme: Theme;
    isTitle?: boolean;
    isObjectValue?: boolean;
    isLarge?: boolean;
  }) => ` 
    align-items: center;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid;
     
    ${
      isTitle
        ? `
      padding: ${theme.spacing.fixed[2]}px ${theme.spacing.fixed[1]}px;
      margin: ${theme.spacing.fixed[5]}px ${theme.spacing.fixed[3]}px;
      font-size: 22px;
      `
        : `
          padding: ${theme.spacing.fixed[2]}px ${theme.spacing.fixed[3]}px;
          `
    };

    ${
      isLarge
        ? css`
            height: 100px;
            align-items: center;
          `
        : ``
    }

     ${
       isObjectValue &&
       `
         gap:1em;
         @media (min-width: ${theme.breakpoints.sm}px) and (max-width: ${
         theme.breakpoints.md + 1
       }px) {
            flex-direction: column;
            padding: ${theme.spacing.fixed[5]}px 0;
          
            & > div:not(:first-child) { 
              flex-direction: row-reverse;
            }
          }`
     }

  `
);

export const ListLabelIconWrapper = styled.div(
  (props: { isObjectValue?: boolean }) => `
  display: flex;
  gap: 1em;
  ${props.isObjectValue ? `max-width: 100px` : ` min-width: fit-content`};
`
);

export const TitleAndIconWrapper = styled.div(
  ({
    theme,
    breakLineValue
  }: {
    breakLineValue?: boolean;
    theme: Theme;
  }) => css`
    display: flex;
    align-items: center;
    // gap: 0.75em;
    height: 100%;
    > h4:last-child {
      padding: 10px 0;
    }
    ${breakLineValue &&
    css`
      align-items: center;
      width: auto;
      flex-direction: column;
      gap: 0.75em;
    `}

    @media (max-width: ${theme.breakpoints.md + 1}px) {
      svg {
        width: 40px;
        height: 40px;
      }
    }

    @media (min-width: ${theme.breakpoints.md + 1}px) {
      svg {
        width: 90px;
        height: 90px;
        padding: 10px 0;
      }
    }

    svg {
      circle,
      path {
        stroke: ${theme.name === "WS10" ? "black" : "white"};
      }
      rect {
        fill: ${theme.name === "WS10" ? "black" : "white"};
      }
    }

    svg.shieldIcon circle,
    svg.hookIcon g g g:last-child path {
      fill: ${theme.name === "WS10" ? "black" : "white"};
    }
  `
);

export const VSDMContainer = styled.div(
  (props) => `
    @media (max-width: ${props.theme.breakpoints.md - 1}px) {
      padding: 1.25em;
    }
  `
);

export const VSDMSummaryLine = styled.div`
  display: flex;
  align-items: baseline;
`;

export const VSDMTotalDeploymentContainer = styled.div`
  display: flex;
  margin-inline-start: 16px;
`;

export const VSDMTotalDeploymentValue = styled.div`
  margin-inline-start: 4px;
`;

export const VSDMTotalDeploymentDivider = styled.div`
  margin-block-start: 16px;
`;

export const VSDMFirstSection = styled.div(
  (props) => css`
    @media (min-width: ${props.theme.breakpoints.lg + 1}px) {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 40px;
    }

    > div:last-child {
      @media (min-width: ${props.theme.breakpoints
          .md}px) and (max-width: ${props.theme.breakpoints.lg}px) {
        width: 50%;
      }
    }
  `
);

export const VSDMSecondSection = styled.div(
  (props) => css`
    @media (min-width: ${props.theme.breakpoints.lg + 1}px) {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 40px;
    }

    @media (min-width: ${props.theme.breakpoints.md}px) and (max-width: ${props
        .theme.breakpoints.lg}px) {
      display: grid;
      grid-template-columns: 1fr 1fr;

      > div:first-child div {
        justify-content: center;
      }
    }
  `
);

export const VSDMDivider = styled.div(
  (props) => css`
    margin-block-start: 48px;

    @media (min-width: ${props.theme.breakpoints.md}px) and (max-width: ${props
        .theme.breakpoints.lg}px) {
      margin-block-start: 76px;
    }

    @media (min-width: ${props.theme.breakpoints.lg + 1}px) {
      margin-block-start: 44px;
    }
  `
);

export const VSDMLastSection = styled.div(
  (props) => css`
    display: grid;

    @media (min-width: ${props.theme.breakpoints.lg + 1}px) {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 40px;
    }

    @media (min-width: ${props.theme.breakpoints.md}px) and (max-width: ${props
        .theme.breakpoints.lg}px) {
      grid-template-columns: 1fr 1fr;
      gap: 4px;
    }

    @media (max-width: ${props.theme.breakpoints.md - 1}px) {
      grid-template-columns: 1fr;
      gap: 40px;
    }
  `
);

export const OperatingSystemBreakdownPlaceholder = styled.div`
  height: 128px;
`;

export const PieGraphsContainer = styled.div(
  (props) => css`
    display: flex;
    gap: 40px;

    @media (max-width: ${props.theme.breakpoints.md}px) {
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }
  `
);

export const SingleStackedBarTileContainer = styled.div(
  (props) =>
    css`
      @media (min-width: ${props.theme.breakpoints.md}px) {
        padding-inline-end: 16px;
      }

      @media (max-width: ${props.theme.breakpoints.md - 1}px) {
        width: 100%;
      }
    `
);

export const HorizontalBarChartWrapper = styled.div(
  (props) => css`
    margin-top: 16px;

    > div:first-child {
      > div:first-child {
        > div:first-child {
          align-items: baseline;

          > span {
            color: ${props.theme.name === "WS10" ? "#0D0D0D" : "#FFFFFF"};

            :first-child {
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;

              @media (max-width: ${props.theme.breakpoints.md - 1}px) {
                font-size: 16px;
                line-height: 20px;
              }
            }
          }
        }

        > div:nth-child(2) {
          > div {
            border-right: ${props.theme.name === "WS10"
              ? "2px solid #F2F2F2"
              : "2px solid #262626"};

            :last-child {
              width: unset;
              flex-grow: 1;
              border-right: unset;
            }
          }
        }

        > div:last-child {
          flex-direction: column;
          gap: 8px;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          color: ${props.theme.name === "WS10" ? "#0D0D0D" : "#FFFFFF"};
        }
      }
    }
  `
);

export const ProgressBarWrapper = styled.div(
  (props: { progressbarColor: string; theme: Theme }) =>
    css`
      margin-top: 16px;

      > div {
        > div {
          > div:first-child {
            align-items: baseline;

            > span:first-child {
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
            }
          }

          > div:last-child {
            background-color: ${props.theme.name === "WS10"
              ? "#BEBEBE"
              : "#7E7E7E"};

            div[role="progressbar"] {
              background-color: ${props.progressbarColor};
            }
          }
        }
      }
    `
);

export const LookOutDashboardTitle = styled.div(
  (props) => css`
    font-weight: 400;
    @media (min-width: ${props.theme.breakpoints.md}px) {
      font-size: 32px;
      height: 40px;
    }
    @media (max-width: ${props.theme.breakpoints.md - 1}px) {
      font-size: 24px;
      height: 32px;
    }
  `
);

export const SitesBlockedContainer = styled.div(
  (props) => css`
    @media (min-width: ${props.theme.breakpoints.md}px) {
      svg {
        height: 60px;
        width: 60px;
      }

      h5 {
        font-size: 28px;
        line-height: 32px;
      }
    }

    @media only screen and (max-width: ${props.theme.breakpoints.md - 1}px) {
      .wrapperCardTile {
        margin-inline: 10px;
      }

      div[data-component-name="SimpleGrid"] > div {
        margin: 0px 8px;
      }

      .wrapperCardTile > :not(.isText):nth-child(2) {
        height: 48px;
        width: 54px;
        font-size: 24px;
      }
    }

    @media (max-width: ${props.theme.breakpoints.md}px) {
      div[data-component-name="SimpleGrid"] > div {
        margin: 0px 8px;
      }
    }

    @media (max-width: ${props.theme.breakpoints.md}px) {
      svg {
        height: 32px;
        width: 32px;
        margin-right: 15px;
      }

      h5 {
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.5px;
      }
    }
  `
);

export const IssuesContainer = styled.div(
  (props) => css`
    margin: 64px 0;
    .wrapperCardTile {
      height: 84px;
    }
    @media (max-width: ${props.theme.breakpoints.md + 1}px) {
      .wrapperCardTile {
        height: 64px;
        font-weight: 400;
      }
      margin: 48px 0;
      h4 {
        font-size: 22px;
        letter-spacing: 0.5px;
      }
    }
    div[data-component-name="Container"] > div > div > div > div {
      margin: 16px 0;
    }
    @media (min-width: ${props.theme.breakpoints.md + 1}px) {
      div[data-component-name="Container"] > div > div > div > div {
        margin: 20px 0;
      }
    }
  `
);

export const LoginsContainer = styled.div(
  (props) => css`
    @media (min-width: ${props.theme.breakpoints.md}px) {
      svg {
        height: 60px;
        width: 60px;
      }

      h5 {
        font-size: 28px;
        line-height: 32px;
      }
    }

    @media (max-width: ${props.theme.breakpoints.md}px) {
      div[data-component-name="SimpleGrid"] > div {
        margin: 0px 8px;
      }
    }

    @media only screen and (max-width: ${props.theme.breakpoints.md - 1}px) {
      .wrapperCardTile {
        margin-inline: 10px;
      }
      h5 {
        width: 124px;
      }

      .wrapperCardTile > :not(.isText):nth-child(2) {
        height: 48px;
        width: 54px;
        font-size: 24px;
      }

      div[data-component-name="SimpleGrid"] > div {
        margin: 0px 8px;
      }
    }

    @media (max-width: ${props.theme.breakpoints.md}px) {
      svg {
        height: 32px;
        width: 32px;
        margin-right: 15px;
      }
      h5 {
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.5px;
      }
    }
  `
);
